import { Grid, Typography } from "@material-ui/core";
import Logo from "../../../components/common/Logo";
import TopBar from "../../../components/common/TopBar";

export default function OnboardingFrameG() {
    return (
        <>
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{
                    flex: 1,
                    justifyContent: "center",
                    paddingTop: 32,
                }}
            >
                <div className="textWrapper" 
                    style= {{
                        position: "absolute",
                        bottom: "460px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        style={{
                            color: "#058B8E",
                            padding: "0px 32px 0px 48px",
                            marginTop: "-16px",
                            fontSize: 20,
                            fontWeight: 700,
                            textAlign: "center",
                        }}
                    >
                        Know where's<br/>available
                    </Typography>
                </div>
                <div className="ChargerIconWrapper"
                    style={{
                        position: "absolute",
                        bottom: "-5px"
                    }}
                >
                    <svg width="400" viewBox="0 0 414 481" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d)">
                        <mask id="mask0" style={{maskType:"alpha",}} maskUnits="userSpaceOnUse" x="109" y="99" width="201" height="248">
                        <path d="M109.748 199.51C109.748 144.296 154.508 99.5361 209.722 99.5361C264.936 99.5361 309.696 144.296 309.696 199.51L309.696 246.073C309.696 301.287 264.936 346.047 209.722 346.047C154.508 346.047 109.748 301.287 109.748 246.073L109.748 199.51Z" fill="#058B8E"/>
                        </mask>
                        <g mask="url(#mask0)">
                        <path d="M87.834 195.685L328.867 195.685L328.867 346.056L87.834 346.056L87.834 195.685Z" fill="#058B8E"/>
                        </g>
                        <path d="M131.66 138.983L189.179 138.983L189.179 222.797L131.66 222.797L131.66 138.983Z" fill="#058B8E"/>
                        <path d="M233.009 138.989L290.528 138.989L290.528 222.803L233.009 222.803L233.009 138.989Z" fill="#058B8E"/>
                        </g>
                        <g filter="url(#filter1_d)">
                        <path d="M212 341L212 481" stroke="#058B8E" strokeWidth="50"/>
                        </g>
                        <circle cx="159.5" cy="239.5" r="6.5" fill="white"/>
                        <circle cx="262.5" cy="239.5" r="6.5" fill="white"/>
                        <path d="M190.5 270.5C198.667 273.833 224.6 277.6 233 266C230.167 272.5 211.7 282.5 190.5 270.5Z" fill="white"/>
                        <g transform="translate(123 30)">
                        <g filter="url(#filter2_d)">
                        <path d="M69 32.5C69 50.4493 54.4493 65 36.5 65C18.5507 65 4 50.4493 4 32.5C4 14.5507 18.5507 0 36.5 0C54.4493 0 69 14.5507 69 32.5Z" fill="#4FAF2D"/>
                        </g>
                        <path d="M32.5531 25.663C32.6012 24.6519 33.0345 23.4 33.7086 22.6778C34.3345 22.0519 35.3456 21.6185 36.5975 21.6185C37.8012 21.6185 38.7642 22.0519 39.3419 22.5815C39.9679 23.1593 40.4012 24.0741 40.4012 25.1815C40.4012 26.4815 39.8716 27.4926 39.1975 28.0704C38.1382 28.9371 36.4049 28.9852 35.9716 28.9852H35.5382V34.0889H36.116C37.2234 34.0889 38.9086 34.1852 40.0642 35.1C40.8345 35.726 41.4605 36.8334 41.4605 38.1815C41.4605 39.4815 40.9308 40.7334 40.1605 41.4556C39.3901 42.1778 38.1864 42.563 36.9345 42.563C35.3938 42.563 34.1419 42.0334 33.3716 41.263C32.5049 40.3963 31.9271 38.9037 31.8308 37.6519H24.416C24.7531 40.8778 26.1493 43.7185 27.979 45.5963C30.8197 48.4371 34.2382 48.9185 37.3679 48.9185C39.9679 48.9185 43.0975 48.6297 45.8419 46.126C48.1049 44.0556 49.0679 41.263 49.0679 38.8074C49.0679 37.0741 48.5382 35.0519 47.5753 33.7519C46.7086 32.5482 45.2642 31.6334 43.916 31.1519C44.8308 30.7185 45.6494 30.0926 46.3234 29.1297C47.3345 27.7334 47.6234 26.3371 47.6234 24.7482C47.6234 21.6185 46.516 19.5 45.1679 18.2C43.5308 16.563 40.6901 15.3593 36.7419 15.3593C34.0938 15.3593 30.9642 15.9371 28.6049 18.3926C26.9197 20.126 25.716 22.9667 25.4271 25.663H32.5531Z" fill="white"/>
                        </g>
                        <g transform="translate(225 60)">
                        <g filter="url(#filter3_d)">
                        <path d="M69 32.5C69 50.4493 54.4493 65 36.5 65C18.5507 65 4 50.4493 4 32.5C4 14.5507 18.5507 0 36.5 0C54.4493 0 69 14.5507 69 32.5Z" fill="#CA3F3C"/>
                        </g>
                        <path d="M48.7309 30.6326C48.7309 25.3511 47.3072 20.6666 44.322 17.7733C42.5309 16.0281 39.8672 14.6044 36.1013 14.6044C32.4272 14.6044 29.8094 15.9822 27.9724 17.7733C24.9872 20.6666 23.5635 25.3511 23.5635 30.6326C23.5635 35.8681 24.9872 40.5066 27.9724 43.4459C29.8094 45.237 32.4272 46.6148 36.1013 46.6148C39.8672 46.6148 42.5309 45.1911 44.322 43.4459C47.3072 40.5066 48.7309 35.8681 48.7309 30.6326ZM36.1472 20.6666C37.3413 20.6666 38.1679 21.034 38.9946 21.8148C41.245 24.0192 41.5664 28.4281 41.5664 30.5866C41.5664 32.9748 41.1531 37.2918 38.9946 39.4044C38.1679 40.1851 37.3413 40.5526 36.1472 40.5526C34.9531 40.5526 34.1264 40.1851 33.2998 39.4044C31.1413 37.2918 30.7279 32.9748 30.7279 30.5866C30.7279 28.4281 31.0494 24.0192 33.2998 21.8148C34.1264 21.034 34.9531 20.6666 36.1472 20.6666Z" fill="white"/>
                        </g>
                        <defs>
                        <filter id="filter0_d" x="105.748" y="138.983" width="207.948" height="215.064" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        <filter id="filter1_d" x="183" y="341" width="58" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        <filter id="filter2_d" x="0" y="0" width="73" height="73" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        <filter id="filter3_d" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        </defs>
                    </svg>
                </div>
            </Grid>
        </>
    );
}
