import { Theme } from "@material-ui/core";

export const themeProps = (theme : Theme) => ({
    MuiTextField: {
        InputProps: {
            style: {
                padding: 4,
                borderRadius: 11,
                borderColor: theme.palette.neutral.dark,
            },
        },
    },
});

export const themeOverrides = (theme : Theme) => ({
    MuiButton: {
        root: {},
        contained: {
            color: theme.palette.background.default,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: 11,
            padding: "8px 36px",
            "&:hover": {
                //you want this to be the same as the backgroundColor above
                backgroundColor: theme.palette.secondary.light,
            },
            boxShadow: "none",
            fontSize: 14,
            fontWeight: 400,
        },
        outlined: {
            borderRadius: 11,
            padding: "8px 36px",
        },
    },
    MuiAppBar: {
        root: {
            backgroundColor: theme.palette.background.default,
            boxShadow: "none",
        },
    },
    MuiSideBar: {
        root: {
            backgroundColor: theme.palette.background.default,
            boxShadow: "none",
        },
    },
    MuiToolbar: {
        root: {
            backgroundColor: theme.palette.background.paper,
            padding: 0,
        },
    },
    MuiPickersCalendarHeader: {
        switchHeader: {
            color: theme.palette.secondary.main,
        },
    },
    MuiPickersDay: {
        day: {
            color: theme.palette.neutral.main,
        },
        daySelected: {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            },
        },
        current: {
            color: theme.palette.secondary.main,
        },
    },
    MuiSvgIcon: {
        root: {
            fill: theme.palette.secondary.main,
        },
    },
    MuiInput: {
        underline: {
            '&:after': {
            borderBottom: '2px solid #058B8E',
            borderColor: theme.palette.secondary.main,
            }
        }
    },
});

declare module "@material-ui/core/styles/createPalette" {
    interface Palette {
      neutral?: Palette['primary'];
    }
    interface PaletteOptions {
      neutral?: PaletteOptions['primary'];
    }
  }