import { Grid, Typography, useTheme } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { Div } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";

export default function LogoutScreen() {
    const theme = useTheme();
    const history = useHistory();
    useEffect(() => {
        setTimeout(() => {
            history.push("/");
        }, 2000);
    }, [history]);

    return (
        <>
            <TopBar title="Logout" back />
            <Grid
                container
                direction="column"
                alignItems="stretch"
                style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    padding: "48px 24px",
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <Div
                    style={{
                        height: "80%",
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    <Typography
                        style={{
                            color: theme.palette.secondary.dark,
                            fontSize: 20,
                            fontWeight: 800,
                            textAlign: "center",
                        }}
                    >
                        Log out successful!
                    </Typography>
                    <Typography
                        style={{
                            color: theme.palette.secondary.dark,
                            fontSize: 28,
                            fontWeight: 800,
                            textAlign: "center",
                        }}
                    >
                        We hope to see you again!
                    </Typography>
                </Div>
            </Grid>
        </>
    );
}
