import { Typography, useTheme, Theme } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { Div, DivHor } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";
import { query } from "../../network";
import { Transaction } from "../../typings";
import ReceiptScreen from "../charging/ReceiptScreen";
import { ArrowForwardIos } from "@material-ui/icons";
import TransactionFilterPopup from "../../components/overlay/TransactionFilterPopup";

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const SummaryButton = withStyles((theme: Theme) => ({
    root: {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        textTransform: 'none',
        paddingLeft: 36,
        paddingRight: 36,
        paddingTop: 0,
        paddingBottom: 0,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
        }
    }
}))(Button);

export default function TransactionHistoryScreen() {
    const { data: transactions } = useQuery<Transaction[]>(
        "transactions",
        () => query("me/transactions"),
        { initialData: [] }
    );
    const { transactionId } = useParams<{ transactionId: string }>();
    const history = useHistory();
    const theme = useTheme();
    
    const [showFilter, setShowFilter] = useState(false);
    
    const openFilter = () => {
        setShowFilter(true);
    }

    const closeFilter = () => {
        setShowFilter(false);
    }

    const sortByDate = (data) => {
        const dates = {};
        for (let datum of data) {
            const date = new Date(datum.startDateTime);
            const formatted = `${date.getDate()} ${
                months[date.getMonth()]
            } ${date.getFullYear()}`;
            if (!dates[formatted]) dates[formatted] = [];
            dates[formatted].push(datum);
        }
        const dateList = [];
        for (let date in dates) {
            dates[date].reverse();
            dateList.push({
                date,
                data: dates[date],
            });
        }
        dateList.reverse();
        return dateList;
    };

    const sortedData = useMemo(() => {
        return sortByDate(transactions.filter((t) => t.endDateTime));
    }, [transactions]);

    const formatDate = (dateTime) => {
        const date = new Date(Date.parse(dateTime));
        const ampm = date.getHours() >= 12 ? "PM" : "AM";
        const minutes = date.getMinutes();
        const formatted = `${
            months[date.getMonth()]
        } ${date.getDate()}, ${date.getHours()}:${
            minutes < 10 ? `0${minutes}` : minutes
        } ${ampm}`;
        return formatted;
    };

    if (transactionId) {
        return (
            <ReceiptScreen
                transaction={transactions.find((t) => t._id === transactionId)}
            />
        );
    }

    return (
        <>
            <TopBar title="Transaction History" back />
            <Div
                style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    backgroundColor: theme.palette.background.default,
                    overflow: "auto",
                    padding: "0px 0px 24px 0px"
                }}
            >
                <Div style={{ padding: "14px 0px", borderBottom: "1px solid lightgray" }}>
                    <SummaryButton variant='outlined' onClick={openFilter}>Get Summary</SummaryButton>
                </Div>
                <TransactionFilterPopup open={showFilter} closeHandler={closeFilter}/>
                {sortedData.map((data) => (
                    <div key={data.date}>
                        <Typography
                            style={{
                                color: theme.palette.neutral.main,
                                padding: "24px 18px 8px 18px",
                                fontWeight: 500,
                                fontSize: 14,
                            }}
                        >
                            {data.date}
                        </Typography>
                        {data.data.map((d, i) => (
                            <DivHor
                                style={{
                                    backgroundColor: theme.palette.background.default,
                                    alignItems: "center",
                                    padding: "4px 14px",
                                    justifyContent: "space-between",
                                    borderTop:
                                        i === 0 ? "1px solid lightgray" : "",
                                    borderBottom: "1px solid lightgray",
                                }}
                                onClick={() =>
                                    history.push(`/transactions/${d._id}`)
                                }
                            >
                                <Div style={{ alignItems: "flex-start" }}>
                                    <Typography
                                        style={{
                                            color: theme.palette.secondary.main,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {d.location.name}
                                    </Typography>
                                    <Typography style={{ color: "#7F7E99" }}>
                                        {formatDate(d.startDateTime)}
                                    </Typography>
                                </Div>
                                <span
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: theme.palette.secondary.main,
                                            fontWeight: 600,
                                        }}
                                    >
                                        $ {d.totalCost.toFixed(2)}
                                    </Typography>
                                    <ArrowForwardIos
                                        style={{
                                            color: "lightgray",
                                            transform: "scale(0.8)",
                                            marginLeft: 8,
                                        }}
                                    />
                                </span>
                            </DivHor>
                        ))}
                    </div>
                ))}
            </Div>
        </>
    );
}
