import {
    Grid,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { DivHor } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";
import { useDebounce } from "../../hooks/useDebounce";
import { query } from "../../network";
import { distanceBetween } from "../../util";
import config from '../../config';

const { GOOGLE_MAPS_API_KEY } = config;

export default function SearchScreen() {
    const history = useHistory();
    const { locationId } = useParams<{ locationId: string }>();

    const [searchTerm, setSearchTerm] = useState("");
    const [places, setPlaces] = useState([]);
    const [chargingLocations, setChargingLocations] = useState([]);

    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);

    const debouncedSearchTerm = useDebounce(searchTerm);
    const searchRef = useRef(null);
    const theme = useTheme();

    useEffect(() => {
        setTimeout(() => searchRef.current?.focus(), 1);
    }, []);

    const { data: locations } = useQuery("loc", () => query("locations"), {
        initialData: [],
    });

    const getLocation = async (place_id: string) => {
        const base = "https://maps.googleapis.com/maps/api/place/details/json";
        const url = `${base}?place_id=${place_id}&fields=geometry&key=${GOOGLE_MAPS_API_KEY}`;
        const res = await fetch(url);
        if (!res.ok) return null;
        const json = await res.json();
        const location = json.result?.geometry.location;
        if (location) {
            const { lat, lng } = location;
            return { latitude: lat, longitude: lng };
        }
    };

    const getNearbyChargers = async ({
        latitude,
        longitude,
    }: {
        latitude: number;
        longitude: number;
    }) => {
        const nearbyChargers = locations
            .map(
                (loc: {
                    coordinates: { latitude: number; longitude: number };
                }) => {
                    const dist = distanceBetween(
                        { latitude, longitude },
                        {
                            latitude: loc.coordinates.latitude,
                            longitude: loc.coordinates.longitude,
                        }
                    );
                    return { ...loc, distance: dist };
                }
            )
            .filter((loc: { distance: number }) => loc.distance < 20)
            .sort(
                (a: { distance: number }, b: { distance: number }) =>
                    a.distance - b.distance
            );

        return nearbyChargers;
    };

    const search = async () => {
        console.log("Searching...", searchTerm);

        if (!map || !maps) return;

        var request = {
            query: searchTerm,
            fields: ["name", "geometry", "place_id"],
        };

        var service = new google.maps.places.PlacesService(map);

        if (!service) {
            console.log("NO SERVICE");
            return;
        }

        const placesRaw: any[] = await new Promise((resolve, reject) => {
            service.findPlaceFromQuery(request, function (results, status) {
                resolve(results ?? []);
            });
        });

        const places = [
            ...placesRaw.map((p) => ({
                ...p,
                latitude: p.geometry.location.lat(),
                longitude: p.geometry.location.lng(),
            })),
        ];

        if (locations.length === 0) return;

        const nearbyChargers = await Promise.all(
            places.map((p) => getNearbyChargers(p))
        );

        const allNearbyChargers = [];
        for (let chargers of nearbyChargers) {
            for (let c of chargers as any[]) {
                if (!allNearbyChargers.map((nc) => nc._id).includes(c._id)) {
                    allNearbyChargers.push(c);
                }
            }
        }
        setChargingLocations(allNearbyChargers);
    };

    useEffect(() => {
        search();
    }, [locations, debouncedSearchTerm]);

    return (
        <>
            <TopBar title="Find Locations" back />
            <Grid
                container
                direction="column"
                alignItems="stretch"
                style={{
                    flex: 1,
                    padding: 14,
                    width: "100vw",
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: GOOGLE_MAPS_API_KEY,
                        libraries: ["geometry", "places"],
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => {
                        setMap(map);
                        setMaps(maps);
                    }}
                    defaultCenter={{ lat: 1.3921, lng: 103.7289 }}
                    defaultZoom={10}
                    style={{ height: 0, width: 0, display: "none" }}
                />
                <TextField
                    variant="outlined"
                    style={{
                        color: theme.palette.primary.main,
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <img
                                    src={"images/charge-pin.png"}
                                    style={{ height: 28, marginRight: 0 }}
                                    alt=""
                                />
                            </InputAdornment>
                        ),
                    }}
                    value={searchTerm}
                    placeholder="Search Stations"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="SearchInput"
                />
                <Typography
                    style={{ marginTop: 14, color: theme.palette.primary.main }}
                >
                    Nearby Stations
                </Typography>
                {chargingLocations.map((c) => (
                    <DivHor
                        style={{
                            justifyContent: "flex-start",
                            borderBottom: 1,
                            borderBottomStyle: "solid",
                            borderBottomColor: theme.palette.neutral.main,
                            padding: "14px 0px",
                        }}
                        onClick={() => history.push(`/${c.postalCode}`)}
                    >
                        <img
                            src={"images/charge-pin.png"}
                            style={{ height: 28, marginRight: 24 }}
                            alt=""
                        />
                        <Typography>{c.name}</Typography>
                    </DivHor>
                ))}
            </Grid>
        </>
    );
}
