import { Button, Grid, Typography, useTheme } from "@material-ui/core";
import { Send, Share } from "@material-ui/icons";
import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Div, DivHor } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";
import { AuthContext } from "../../context/AuthContext";
import { del, post, query } from "../../network";
import { shadow } from "../../shadows";
import { Location } from "../../typings";
import { openLocationUrl, openStoreLink, shareLocationUrl } from "../../util";
import { Charger } from "./components/Charger";

export const ListItem = ({ location }: { location: Location }) => {
    const theme = useTheme();

    const acEvses = useMemo(() => {
        if (!location) return [];
        return location.evses.filter((e) => e.connectors[0].powerType.startsWith("AC"));
    }, [location]);

    const dcEvses = useMemo(() => {
        if (!location) return [];
        return location.evses.filter((e) => e.connectors[0].powerType === "DC");
    }, [location]);

    const providerName = location.onNetwork ? 'Public' : location.provider?.name
    const providerImage = location.onNetwork ? '/images/public-charger-icon.png' : location.provider?.imageURL

    return (
        <>
            <Typography style={{ fontSize: 25, color: theme.palette.primary.main }}>
                {location.name}
            </Typography>
            <DivHor
                style={{ justifyContent: "flex-start", padding: "0px 0px 8px 0px" }}
            >
                <Typography style={{ fontSize: 15, color: theme.palette.success.main }}>
                    Open 24 Hours
                </Typography>
                {location.residentsOnly && (
                    <>
                        <span
                            style={{
                                height: 5,
                                width: 5,
                                margin: 8,
                                backgroundColor: theme.palette.neutral.light,
                                borderRadius: 180,
                            }}
                        />
                        <Typography style={{ fontSize: 15, color: "#9190A7" }}>
                            Residents Only
                        </Typography>
                    </>
                )}
            </DivHor>
            <DivHor style={{ justifyContent: "space-between", paddingTop: 0, paddingBottom: 2 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.primary.main, fontWeight: 500 }}>
                    <span style={{ color: acEvses.length > 0 ? theme.palette.success.main : theme.palette.warning.main }}>{acEvses.length}</span> AC {acEvses.length > 0 ? `$${acEvses[0].chargingRateKWH} ` : ' '}
                    <span style={{ color: dcEvses.length > 0 ? theme.palette.success.main : theme.palette.warning.main }}>{dcEvses.length}</span> DC {dcEvses.length > 0 ? `$${dcEvses[0].chargingRateKWH}` : ''}
                </Typography>
                <DivHor style={{ padding: 0 }}>
                    <img
                        src={providerImage}
                        style={{
                            height: 28,
                            marginRight: 14,
                            borderRadius: 180,
                            ...shadow,
                        }}
                        alt=""
                    />
                    <Typography style={{ color: theme.palette.primary.main, fontWeight: 500, textAlign: 'center' }}>
                        {providerName}
                    </Typography>
                </DivHor>
            </DivHor>
            <DivHor
                style={{
                    paddingTop: 8,
                    justifyContent: "space-evenly",
                    alignSelf: "stretch",
                }}
            >
                <Button
                    variant="contained"
                    onClick={(e) => {
                        openLocationUrl(location);
                        e.stopPropagation();
                    }}
                    style={{ width: "40vw", backgroundColor: theme.palette.secondary.main }}
                    startIcon={<Send style={{ transform: "rotate(315deg) translate(4px, 0px)", fill: theme.palette.getContrastText(theme.palette.secondary.main) }} />}
                >
                    Directions
                </Button>
                <Button
                    variant="outlined"
                    onClick={(e) => {
                        shareLocationUrl(location);
                        e.stopPropagation();
                    }}
                    style={{ width: "40vw", color: theme.palette.secondary.main }}
                    startIcon={<Share />}
                >
                    Share
                </Button>
            </DivHor>
            <DivHor
                style={{
                    padding: 0,
                    overflow: "scroll",
                    justifyContent: "flex-start",
                    marginTop: 6,
                }}
            >
                <img
                    src="https://static.straitstimes.com.sg/s3fs-public/styles/x_large/public/articles/2019/03/15/colin-co2-15.jpg?itok=FnEuKLJS&timestamp=1552654693"
                    style={{
                        height: 100,
                        marginRight: 8,
                        borderRadius: 4
                    }}
                    alt=""
                />
                <img
                    src="https://static.straitstimes.com.sg/s3fs-public/styles/x_large/public/articles/2019/03/15/colin-co2-15.jpg?itok=FnEuKLJS&timestamp=1552654693"
                    style={{
                        height: 100,
                        borderRadius: 4
                    }}
                    alt=""
                />
            </DivHor>
        </>
    );
};

export const Header = ({ location }: { location: Location }) => {
    const [isSaved, setIsSaved] = useState(false);
    const { loggedIn } = useContext(AuthContext)
    const theme = useTheme();

    const { data: saved, refetch } = useQuery(
        "locationsSaved",
        () => query("me/locations"),
        { initialData: [] }
    );

    useEffect(() => {
        setIsSaved(saved.map((loc) => loc._id).includes(location._id));
    }, [saved, location]);

    const toggleSave = async () => {
        const res = isSaved
            ? await del(`me/locations/${location.postalCode}`, {})
            : await post(`me/locations/${location.postalCode}`, {});
        if (res.ok) {
            setIsSaved((s) => !s);
            refetch();
        } else {
            res.text().then((t) => console.error(t));
        }
    };

    return (
        <>
            <Div style={{
                padding: 0,
                margin: 0,
                width: '100vw'
            }}>
                <DivHor
                    style={{
                        padding: 0,
                        overflow: "scroll",
                        justifyContent: "flex-start",
                        alignSelf: "stretch",
                        transform: 'translate(0px, -14px)',
                        height: '100%',
                        overflowY: 'auto'
                    }}
                >
                    <img
                        src="https://static.straitstimes.com.sg/s3fs-public/styles/x_large/public/articles/2019/03/15/colin-co2-15.jpg?itok=FnEuKLJS&timestamp=1552654693"
                        style={{
                            height: 140,
                            marginRight: 6,
                            borderRadius: 5
                        }}
                        alt=""
                    />
                    <img
                        src="https://static.straitstimes.com.sg/s3fs-public/styles/x_large/public/articles/2019/03/15/colin-co2-15.jpg?itok=FnEuKLJS&timestamp=1552654693"
                        style={{
                            height: 140,
                            borderRadius: 5
                        }}
                        alt=""
                    />
                </DivHor>
                <Div
                    style={{
                        position: "relative",
                        alignSelf: "stretch",
                        padding: "0px 32px",
                    }}
                >
                    <Typography style={{ fontSize: 25, color: theme.palette.primary.main, textAlign: "center", padding: "0px 30px", lineHeight: 1.1 }}>
                        {location.name}
                    </Typography>
                    <Typography style={{ fontSize: 15, color: theme.palette.secondary.main }}>
                        {location.address}, {location.postalCode}
                    </Typography>
                    {
                        loggedIn && <>
                            <img
                                src="/images/favourite_empty.png"
                                style={{
                                    position: "absolute",
                                    top: 6,
                                    right: 10,
                                    height: 24,
                                    width: 24,
                                    display: !isSaved ? "block" : "none",
                                }}
                                alt="Not Favourite"
                                onClick={toggleSave}
                            />
                            <img
                                src="/images/favourite_filled.png"
                                style={{
                                    position: "absolute",
                                    top: 6,
                                    right: 10,
                                    height: 24,
                                    width: 24,
                                    display: isSaved ? "block" : "none",
                                }}
                                alt="Favourite"
                                onClick={toggleSave}
                            />
                        </>
                    }
                </Div>
                <DivHor
                    style={{ justifyContent: "flex-start", padding: "8px 0px" }}
                >
                    <Typography style={{ fontSize: 15, color: theme.palette.success.main }}>
                        Open 24 Hours
                    </Typography>
                    {location.residentsOnly && (
                        <>
                            <span
                                style={{
                                    height: 5,
                                    width: 5,
                                    margin: 8,
                                    backgroundColor: "#DADAE2",
                                    borderRadius: 180,
                                }}
                            />
                            <Typography style={{ fontSize: 15, color: "#9190A7" }}>
                                Residents Only
                            </Typography>
                        </>
                    )}
                </DivHor>
                <DivHor
                    style={{
                        paddingTop: 8,
                        justifyContent: "space-evenly",
                        alignSelf: "stretch",
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            openLocationUrl(location);
                            e.stopPropagation();
                        }}
                        style={{ width: "40vw", backgroundColor: theme.palette.secondary.main }}
                        startIcon={<Send style={{ transform: "rotate(315deg) translate(4px, 0px)", fill: theme.palette.getContrastText(theme.palette.secondary.main) }} />}
                    >
                        Directions
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={(e) => {
                            shareLocationUrl(location);
                            e.stopPropagation();
                        }}
                        style={{ width: "40vw", color:theme.palette.secondary.main }}
                        startIcon={<Share />}
                    >
                        Share
                    </Button>
                </DivHor>

            </Div>
        </>
    );
};

export default function LocationScreen() {
    const theme = useTheme();
    const { postalCode } = useParams<{ postalCode: string }>();
    const { data: locations } = useQuery("loc", () => query("locations"), {
        initialData: [],
    });
    const location = locations.find(
        (l) => l.postalCode.toString() === postalCode
    );

    const acEvses = useMemo(() => {
        if (!location) return [];
        return location.evses.filter((e) => e.connectors[0].powerType.startsWith("AC"));
    }, [location]);

    const dcEvses = useMemo(() => {
        if (!location) return [];
        return location.evses.filter((e) => e.connectors[0].powerType === "DC");
    }, [location]);

    if (!location) return <></>;

    const providerName = location.onNetwork ? 'Public' : location.provider?.name
    const providerImage = location.onNetwork ? '/images/public-charger-icon.png' : location.provider?.imageURL
    const bannerText = location.onNetwork ? '' : `Launch ${location.provider?.name} app to charge`
    const bannerAction = location.onNetwork ? () => { } : () => openStoreLink(location.provider)
    const showScan = location.onNetwork ? true : false

    return (
        <>
            <TopBar
                title={providerName}
                logoURL={providerImage}
                back
                bannerText={bannerText}
                bannerAction={bannerAction}
                showScan={showScan}
            />
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{ flex: 1, paddingTop: 14, width: "100vw", backgroundColor: theme.palette.background.default }}
            >
                {location && <Header location={location} />}
                <Typography style={{ color: theme.palette.primary.main, fontWeight: "bold" }}>
                    STATION DETAILS
                </Typography>
                {
                    location.directions && (
                        <DivHor style={{ padding: 0, alignSelf: "stretch" }}>
                            <Typography
                                style={{
                                    fontWeight: "bold",
                                    marginRight: 8,
                                    color: theme.palette.primary.main,
                                }}
                            >
                                Instructions
                            </Typography>
                            <Typography style={{ color: theme.palette.primary.main }}>
                                {location.directions}
                            </Typography></DivHor>
                    )
                }
                {acEvses?.length > 0 && (
                    <>
                        <Typography style={{ marginTop: 8, fontWeight: 500, color: theme.palette.primary.main }}>AC</Typography>
                        <DivHor
                            style={{
                                alignSelf: "stretch",
                                justifyContent: "flex-start",
                                paddingTop: 0
                            }}
                        >
                            {acEvses.map((e) => (
                                <Charger evse={e} />
                            ))}
                        </DivHor>
                    </>
                )}
                {dcEvses?.length > 0 && (
                    <>
                        <Typography style={{ marginTop: 8, fontWeight: 500, color: theme.palette.primary.main }}>DC</Typography>
                        <DivHor
                            style={{
                                alignSelf: "stretch",
                                justifyContent: "flex-start",
                                paddingTop: 0
                            }}
                        >
                            {dcEvses.map((e) => (
                                <Charger evse={e} />
                            ))}
                        </DivHor>
                    </>
                )}
            </Grid>
        </>
    );
}
