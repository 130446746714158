import { Grid, Typography } from "@material-ui/core";
import Logo from "../../../components/common/Logo";
import TopBar from "../../../components/common/TopBar";

export default function OnboardingFrameC() {
    return (
        <>
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{
                    flex: 1,
                    justifyContent: "center",
                    paddingTop: 32,
                }}
            >
                <div className="textWrapper" 
                    style= {{
                        position: "absolute",
                        bottom: "460px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography style={{ 
                        color: "#058B8E",
                        fontWeight: 700,
                        fontSize: 60,
                        }}
                    >
                        10,000
                    </Typography>
                    <Typography
                        style={{
                            color: "#058B8E",
                            padding: "0px 32px 0px 48px",
                            marginTop: "-16px",
                            fontSize: 20,
                            fontWeight: 700,
                            textAlign: "center",
                        }}
                    >
                        charging locations
                    </Typography>
                </div>
                <div className="ChargerIconWrapper"
                    style={{
                        position: "absolute",
                        bottom: "-5px"
                    }}
                >
                    <svg width="400" viewBox="0 0 414 481" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d)">
                        <mask id="mask0" style={{maskType:"alpha",}} maskUnits="userSpaceOnUse" x="109" y="99" width="201" height="248">
                        <path d="M109.748 199.51C109.748 144.296 154.508 99.5361 209.722 99.5361C264.936 99.5361 309.696 144.296 309.696 199.51L309.696 246.073C309.696 301.287 264.936 346.047 209.722 346.047C154.508 346.047 109.748 301.287 109.748 246.073L109.748 199.51Z" fill="#058B8E"/>
                        </mask>
                        <g mask="url(#mask0)">
                        <path d="M87.834 195.685L328.867 195.685L328.867 346.056L87.834 346.056L87.834 195.685Z" fill="#058B8E"/>
                        </g>
                        <path d="M131.66 138.983L189.179 138.983L189.179 222.797L131.66 222.797L131.66 138.983Z" fill="#058B8E"/>
                        <path d="M233.009 138.989L290.528 138.989L290.528 222.803L233.009 222.803L233.009 138.989Z" fill="#058B8E"/>
                        </g>
                        <g filter="url(#filter1_d)">
                        <path d="M212 341L212 481" stroke="#058B8E" strokeWidth="50"/>
                        </g>
                        <circle cx="159.5" cy="239.5" r="6.5" fill="white"/>
                        <circle cx="262.5" cy="239.5" r="6.5" fill="white"/>
                        <path d="M190.5 270.5C198.667 273.833 224.6 277.6 233 266C230.167 272.5 211.7 282.5 190.5 270.5Z" fill="white"/>
                        <g transform="translate(45 280)">
                            <mask id="mask2" style={{maskType:"alpha"}}maskUnits="userSpaceOnUse" x="50" y="41" width="84" height="103">
                            <path d="M50.7705 82.5416C50.7705 59.6983 69.2886 41.1802 92.1318 41.1802C114.975 41.1802 133.493 59.6983 133.493 82.5416L133.493 101.806C133.493 124.649 114.975 143.167 92.1318 143.167C69.2886 143.167 50.7705 124.649 50.7705 101.806L50.7705 82.5416Z" fill="#058B8E"/>
                            </mask>
                            <g mask="url(#mask2)">
                            <path d="M41.7041 80.9589L141.425 80.9589L141.425 143.171L41.7041 143.171L41.7041 80.9589Z" fill="#C4C4C4"/>
                            </g>
                            <path d="M59.8359 57.5003L83.6329 57.5003L83.6329 92.1758L59.8359 92.1758L59.8359 57.5003Z" fill="#C4C4C4"/>
                            <path d="M101.766 57.5028L125.563 57.5028L125.563 92.1784L101.766 92.1784L101.766 57.5028Z" fill="#C4C4C4"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M82.7305 199V137.769H103.417V199H82.7305Z" fill="#C4C4C4"/>
                            <path d="M74.0424 99.0863C74.0424 100.571 72.8384 101.775 71.3533 101.775C69.8681 101.775 68.6641 100.571 68.6641 99.0863C68.6641 97.6011 69.8681 96.3971 71.3533 96.3971C72.8384 96.3971 74.0424 97.6011 74.0424 99.0863Z" fill="white"/>
                            <path d="M116.656 99.0863C116.656 100.571 115.452 101.775 113.967 101.775C112.481 101.775 111.277 100.571 111.277 99.0863C111.277 97.6011 112.481 96.3971 113.967 96.3971C115.452 96.3971 116.656 97.6011 116.656 99.0863Z" fill="white"/>
                            <path d="M84.1787 111.912C87.5574 113.291 98.2866 114.849 101.762 110.05C100.59 112.739 92.9496 116.876 84.1787 111.912Z" fill="white"/>
                        </g>
                        <g transform="translate(195 280)">
                            <mask id="mask2" style={{maskType:"alpha"}}maskUnits="userSpaceOnUse" x="50" y="41" width="84" height="103">
                            <path d="M50.7705 82.5416C50.7705 59.6983 69.2886 41.1802 92.1318 41.1802C114.975 41.1802 133.493 59.6983 133.493 82.5416L133.493 101.806C133.493 124.649 114.975 143.167 92.1318 143.167C69.2886 143.167 50.7705 124.649 50.7705 101.806L50.7705 82.5416Z" fill="#058B8E"/>
                            </mask>
                            <g mask="url(#mask2)">
                            <path d="M41.7041 80.9589L141.425 80.9589L141.425 143.171L41.7041 143.171L41.7041 80.9589Z" fill="#C4C4C4"/>
                            </g>
                            <path d="M59.8359 57.5003L83.6329 57.5003L83.6329 92.1758L59.8359 92.1758L59.8359 57.5003Z" fill="#C4C4C4"/>
                            <path d="M101.766 57.5028L125.563 57.5028L125.563 92.1784L101.766 92.1784L101.766 57.5028Z" fill="#C4C4C4"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M82.7305 199V137.769H103.417V199H82.7305Z" fill="#C4C4C4"/>
                            <path d="M74.0424 99.0863C74.0424 100.571 72.8384 101.775 71.3533 101.775C69.8681 101.775 68.6641 100.571 68.6641 99.0863C68.6641 97.6011 69.8681 96.3971 71.3533 96.3971C72.8384 96.3971 74.0424 97.6011 74.0424 99.0863Z" fill="white"/>
                            <path d="M116.656 99.0863C116.656 100.571 115.452 101.775 113.967 101.775C112.481 101.775 111.277 100.571 111.277 99.0863C111.277 97.6011 112.481 96.3971 113.967 96.3971C115.452 96.3971 116.656 97.6011 116.656 99.0863Z" fill="white"/>
                            <path d="M84.1787 111.912C87.5574 113.291 98.2866 114.849 101.762 110.05C100.59 112.739 92.9496 116.876 84.1787 111.912Z" fill="white"/>
                        </g>
                        <g transform="translate(5 370)">
                        <mask id="mask1" style={{maskType:"alpha"}}maskUnits="userSpaceOnUse" x="27" y="22" width="47" height="57">
                        <path d="M27.8291 45.2434C27.8291 32.7224 37.9794 22.5721 50.5005 22.5721C63.0215 22.5721 73.1718 32.7224 73.1718 45.2434L73.1718 55.8027C73.1718 68.3238 63.0215 78.4741 50.5005 78.4741C37.9794 78.4741 27.8291 68.3238 27.8291 55.8027L27.8291 45.2434Z" fill="#058B8E"/>
                        </mask>
                        <g mask="url(#mask1)">
                        <path d="M22.8594 44.3759L77.5191 44.3759L77.5191 78.4762L22.8594 78.4762L22.8594 44.3759Z" fill="#C4C4C4"/>
                        </g>
                        <path d="M32.7979 31.5175L45.8417 31.5175L45.8417 50.5242L32.7979 50.5242L32.7979 31.5175Z" fill="#C4C4C4"/>
                        <path d="M55.7803 31.519L68.8241 31.519L68.8241 50.5256L55.7803 50.5256L55.7803 31.519Z" fill="#C4C4C4"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M45.3467 109.078V75.5154H56.6853V109.078H45.3467Z" fill="#C4C4C4"/>
                        <path d="M40.5848 54.3121C40.5848 55.1262 39.9248 55.7861 39.1107 55.7861C38.2967 55.7861 37.6367 55.1262 37.6367 54.3121C37.6367 53.498 38.2967 52.8381 39.1107 52.8381C39.9248 52.8381 40.5848 53.498 40.5848 54.3121Z" fill="white"/>
                        <path d="M63.9422 54.3121C63.9422 55.1262 63.2822 55.7861 62.4682 55.7861C61.6541 55.7861 60.9941 55.1262 60.9941 54.3121C60.9941 53.498 61.6541 52.8381 62.4682 52.8381C63.2822 52.8381 63.9422 53.498 63.9422 54.3121Z" fill="white"/>
                        <path d="M46.1406 61.3421C47.9926 62.098 53.8736 62.9522 55.7785 60.3216C55.136 61.7956 50.9482 64.0634 46.1406 61.3421Z" fill="white"/>
                        </g>
                        <g transform="translate(310 370)">
                        <mask id="mask1" style={{maskType:"alpha"}}maskUnits="userSpaceOnUse" x="27" y="22" width="47" height="57">
                        <path d="M27.8291 45.2434C27.8291 32.7224 37.9794 22.5721 50.5005 22.5721C63.0215 22.5721 73.1718 32.7224 73.1718 45.2434L73.1718 55.8027C73.1718 68.3238 63.0215 78.4741 50.5005 78.4741C37.9794 78.4741 27.8291 68.3238 27.8291 55.8027L27.8291 45.2434Z" fill="#058B8E"/>
                        </mask>
                        <g mask="url(#mask1)">
                        <path d="M22.8594 44.3759L77.5191 44.3759L77.5191 78.4762L22.8594 78.4762L22.8594 44.3759Z" fill="#C4C4C4"/>
                        </g>
                        <path d="M32.7979 31.5175L45.8417 31.5175L45.8417 50.5242L32.7979 50.5242L32.7979 31.5175Z" fill="#C4C4C4"/>
                        <path d="M55.7803 31.519L68.8241 31.519L68.8241 50.5256L55.7803 50.5256L55.7803 31.519Z" fill="#C4C4C4"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M45.3467 109.078V75.5154H56.6853V109.078H45.3467Z" fill="#C4C4C4"/>
                        <path d="M40.5848 54.3121C40.5848 55.1262 39.9248 55.7861 39.1107 55.7861C38.2967 55.7861 37.6367 55.1262 37.6367 54.3121C37.6367 53.498 38.2967 52.8381 39.1107 52.8381C39.9248 52.8381 40.5848 53.498 40.5848 54.3121Z" fill="white"/>
                        <path d="M63.9422 54.3121C63.9422 55.1262 63.2822 55.7861 62.4682 55.7861C61.6541 55.7861 60.9941 55.1262 60.9941 54.3121C60.9941 53.498 61.6541 52.8381 62.4682 52.8381C63.2822 52.8381 63.9422 53.498 63.9422 54.3121Z" fill="white"/>
                        <path d="M46.1406 61.3421C47.9926 62.098 53.8736 62.9522 55.7785 60.3216C55.136 61.7956 50.9482 64.0634 46.1406 61.3421Z" fill="white"/>
                        </g>
                        <defs>
                        <filter id="filter0_d" x="105.748" y="138.983" width="207.948" height="215.064" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        <filter id="filter1_d" x="183" y="341" width="58" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        </defs>
                    </svg>
                </div>
            </Grid>
        </>
    );
}
