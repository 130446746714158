import {
    Button,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Div } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";
import { Header } from "../../components/forms/Header";
import { Label } from "../../components/forms/Label";
import { AuthContext } from "../../context/AuthContext";
import { basePath } from "../../network";
import { GoogleLogin } from 'react-google-login';
import { ErrorModal } from "../../components/overlay/ErrorModal";
import config from '../../config';


const { GOOGLE_CLIENT_ID } = config;

function useSearchParams() {
    return new URLSearchParams(useLocation().search);
}

export default function RegisterScreen() {
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");

    const [usernameStatus, setUsernameStatus] = useState("");
    const [mobileNumberStatus, setMobileNumberStatus] = useState("");
    const [emailStatus, setEmailStatus] = useState("");
    const [otpError, setOtpError] = useState("");
    const [error, setError] = useState("")

    const [pin, setPin] = useState("");
    const next = useSearchParams().get("next");

    const [step, setStep] = useState(0);

    const { setLoggedIn } = useContext(AuthContext);

    const theme = useTheme();

    useEffect(() => {
        fetch(`${basePath}auth/checkUsername`, {
            method: "POST",
            body: JSON.stringify({ username: username.trim() }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setUsernameStatus(
                    res?.hasUser ? "Username already exists" : ""
                );
            });
    }, [username]);

    useEffect(() => {
        fetch(`${basePath}auth/checkPhone`, {
            method: "POST",
            body: JSON.stringify({ mobileNumber: `+65${mobileNumber.trim()}` }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setMobileNumberStatus(
                    res?.hasUser ? "Mobile number already exists" : ""
                );
            });
    }, [mobileNumber]);

    useEffect(() => {
        fetch(`${basePath}auth/checkEmail`, {
            method: "POST",
            body: JSON.stringify({ email: email.trim() }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setEmailStatus(res?.hasUser ? "Email already exists" : "");
            });
    }, [email]);

    const submit = async () => {
        const res = await fetch(`${basePath}auth/register`, {
            method: "POST",
            body: JSON.stringify({
                mobileNumber: `+65${mobileNumber.trim()}`,
                pin: pin.trim(),
                email: email.trim(),
                username: username.trim(),
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (res.ok) {
            res.json().then((j) => {
                localStorage.setItem("token", j.token);
                localStorage.setItem("refresh", j.refreshToken);
            });
            setLoggedIn(true);
            setStep(2);
            setTimeout(() => {
                history.push(next ?? "/");
            }, 2000);
        } else {
            res.json().then((t) => setOtpError(t?.message || t));
        }
    };

    const submitGoogle = async (googleID, googleAT, email) => {
        const res = await fetch(`${basePath}auth/register`, {
            method: "POST",
            body: JSON.stringify({
                googleID, googleAT, email
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (res.ok) {
            res.json().then((j) => {
                localStorage.setItem("token", j.token);
                localStorage.setItem("refresh", j.refreshToken);
            });
            setLoggedIn(true);
            setStep(2);
            setTimeout(() => {
                history.push(next ?? "/");
            }, 2000);
        } else {
            res.json().then((t) => setError(t?.message || t));
        }
    };

    const requestOTP = async () => {
        const res = await fetch(`${basePath}auth/requestOTP`, {
            method: "POST",
            body: JSON.stringify({ mobileNumber: `+65${mobileNumber.trim()}` }),
            headers: { "Content-Type": "application/json" },
        });
        if (res.ok) {
            setStep(1);
        } else {
            res.text().then((t) => console.log("ERR", t));
        }
    };

    const progressTo = (i: number) => {
        if (i === 1) {
            requestOTP();
        } else if (i === 2) {
            submit();
        }
    };

    const googleSuccess = (user) => {
        if (user.profileObj.email) {
            fetch(`${basePath}auth/checkEmail`, {
                method: "POST",
                body: JSON.stringify({ email: user.profileObj.email.trim() }),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res?.hasUser) {
                        setError('An account with the email associated with this Google account already exists')
                    } else {
                        submitGoogle(user.googleId, user.tokenId, user.profileObj.email)
                    }
                });
        }
    }

    return (
        <>
            <TopBar title="Create an Account" back noRight />
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    padding: "48px 0px",
                    backgroundColor: theme.palette.background.default,
                    overflowY: 'auto'
                }}
            >
                {step === 0 && (
                    <Div style={{ alignItems: "stretch", maxWidth: 640 }}>
                        <Header
                            title="Glad you're here!"
                            subtitle="Sign up to enjoy all features"
                        />
                        <Label title="Username" />
                        <TextField
                            variant="outlined"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            error={!!usernameStatus}
                        />
                        <Typography style={{ color: "#F66C62" }}>
                            {usernameStatus}
                        </Typography>
                        <Label
                            title="Contact Number"
                            subtitle="Please enter a valid mobile number to receive OTP"
                        />
                        <TextField
                            variant="outlined"
                            type="number"
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            error={!!mobileNumberStatus}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                            }}
                        />
                        <Typography style={{ color: "#F66C62" }}>
                            {mobileNumberStatus}
                        </Typography>
                        <Label
                            title="Email"
                            subtitle="Don't worry, we won't spam your email"
                        />
                        <TextField
                            variant="outlined"
                            type="email"
                            inputProps={{ inputMode: "email", pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" }}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={!!emailStatus}
                        />
                        <Typography style={{ color: "#F66C62" }}>
                            {emailStatus}
                        </Typography>
                        <Button
                            style={{ margin: "14px 0px 28px 0px" }}
                            onClick={() => progressTo(1)}
                            variant="contained"
                            disabled={
                                username === "" ||
                                email === "" ||
                                mobileNumber === "" ||
                                !!usernameStatus ||
                                !!emailStatus ||
                                !!mobileNumberStatus ||
                                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email)
                            }
                        >
                            Confirm
                        </Button>
                        <GoogleLogin
                            clientId={GOOGLE_CLIENT_ID}
                            buttonText="Sign up with Google"
                            onSuccess={googleSuccess}
                            onFailure={(f) => console.log('failure', f)}
                            cookiePolicy={'single_host_origin'}
                            style={{ marginBottom: 8 }}
                        />
                    </Div>
                )}
                {step === 1 && (
                    <Div style={{ alignItems: "stretch" }}>
                        <Header
                            title="Enter OTP"
                            subtitle={`The 6 digit number sent to +65 ${mobileNumber.trim()}`}
                        />
                        <TextField
                            variant="outlined"
                            type="number"
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            style={{ marginTop: 14 }}
                            value={pin}
                            onChange={(e) => setPin(e.target.value)}
                        />
                        <Typography style={{ color: "#F66C62", paddingTop: 14, textAlign: 'center' }}>
                            {otpError}
                        </Typography>
                        <Button
                            style={{ margin: "14px 0px 0px 0px" }}
                            onClick={requestOTP}
                            variant="contained"
                        >
                            Resend OTP
                        </Button>
                        <Button
                            style={{ margin: "14px 0px" }}
                            onClick={() => progressTo(2)}
                            variant="contained"
                            disabled={pin.length < 6}
                        >
                            Confirm
                        </Button>
                    </Div>
                )}
                {step === 2 && (
                    <Div style={{ height: "80%", justifyContent: "center" }}>
                        <Typography
                            style={{
                                color: "#058B8E",
                                fontSize: 20,
                                fontWeight: 800,
                            }}
                        >
                            Sign Up successful
                        </Typography>
                        <Typography
                            style={{
                                color: "#058B8E",
                                fontSize: 28,
                                fontWeight: 800,
                            }}
                        >
                            Enjoy charging!
                        </Typography>
                    </Div>
                )}
            </Grid>
            <ErrorModal open={!!error} handleClose={() => setError('')} message={error} />
        </>
    );
}
