import { Typography, useTheme } from "@material-ui/core";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Div } from "../../components/base/Divs";
import { query } from "../../network";
import ChargingScreen from "./ChargingScreen";
import HoggingScreen from "./HoggingScreen";
import PreChargingScreen from "./PreChargingScreen";
import UnavailableScreen from "./UnavailableScreen";
import { EVSE, Location, Transaction } from "../../typings";
import ClipLoader from "react-spinners/ClipLoader";
import PreConnectScreen from "./PreConnectScreen";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ErrorModal } from "../../components/overlay/ErrorModal";

type OCPPState = "AVAILABLE" | "PREPARING" | "CHARGING" | "FINISHING";
type State =
    | "UNPLUG"
    | "CONNECTED"
    | "CHARGING"
    | "HOGGING"
    | "UNAVAILABLE";

export default function Main() {
    const { postalCode, evseId } =
        useParams<{ postalCode: string; evseId: string }>();

    const history = useHistory();
    const { loggedIn } = useContext(AuthContext);
    const [error, setError] = useState("");
    const [waitingFrom, setWaitingFrom] = useState("");
    const timeoutRef = useRef(null)
    const theme = useTheme()

    const [state, setState] = useState<State>("UNPLUG");
    const [previousCurrent, setPreviousCurrent] = useState(null);

    const { data: location, isSuccess: gotLocation } = useQuery<Location>(
        "loc-1",
        () => query(`locations/${postalCode}`),
        { refetchInterval: 4000 }
    );
    const { data: evse, isSuccess: gotEvse } = useQuery<EVSE>(
        "evse-1",
        () => query(`locations/${postalCode}/${evseId}`),
        { refetchInterval: 4000 }
    );
    const { data: current, isSuccess: gotCurrent } = useQuery<Transaction>(
        "cur-1",
        () => query(`me/transactions/current`),
        { refetchInterval: 4000, enabled: loggedIn }
    );
    const { data: cards } = useQuery(
        "paymentMethods",
        () => query("me/paymentMethods"),
        { initialData: [], enabled: loggedIn }
    );

    const { data: me } = useQuery(
        "me",
        () => query("me"),
        { initialData: [], enabled: loggedIn }
    );

    const changeIfCan = useCallback((newState: string) => {
        if (waitingFrom !== newState) {
            setState(newState as State)
            setWaitingFrom("")
        } else {
            console.log(`state to ${newState} prevented via CIC`)
        }
    }, [waitingFrom])

    useEffect(() => {
        if (current) {
            setPreviousCurrent(current);
        }
    }, [current]);

    useEffect(() => {
        console.log("STATE: change in..", location, evse, current);
        const evseState = evse?.ocppStatus as OCPPState;
        if (evseState === "AVAILABLE") {
            console.log("prevstate", state);
            console.log("previousCurrent", previousCurrent);
            if (state === "HOGGING" || state === "CHARGING") {
                history.push(`/transactions/${previousCurrent._id}`);
            }
            console.log("STATE: setting unplug");
            changeIfCan("UNPLUG");
            return;
        }
        if (evseState === "PREPARING") {
            console.log("STATE: setting connected");
            changeIfCan("CONNECTED");
            return;
        }
        if (evseState === "CHARGING") {
            if (evse?.currentlyCharging === me?._id) {
                console.log("STATE: setting charging");
                changeIfCan("CHARGING");
                return;
            } else {
                console.log("STATE: setting unavailable (charging)");
                changeIfCan("UNAVAILABLE");
                return;
            }
        }
        if (evseState === "FINISHING") {
            if (evse?.currentlyCharging === me?._id) {
                console.log("STATE: setting hogging");
                changeIfCan("HOGGING");
                return;
            } else {
                console.log("STATE: setting unavailable (finishing)");
                changeIfCan("UNAVAILABLE");
                return;
            }
        }
        console.log("STATE: setting nothing");
    }, [location, evse, current, state, previousCurrent, changeIfCan, history]);

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
            setWaitingFrom("")
            console.log('timedout')
        }, 10000)
    }, [waitingFrom])

    if (waitingFrom !== "" || !location || !evse) {
        return (
            <Div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <Typography style={{ color: "#058B8E", fontSize: 24 }}>
                    Loading..
                </Typography>
            </Div>
        );
    }

    if (state === "UNPLUG") {
        return (
            <div style={{
                backgroundColor: theme.palette.background.default,
            }}>
                <PreConnectScreen
                    location={location}
                    evse={evse}
                    next={() => {}}
                />
            </div>
        );
    }

    if (state === "CONNECTED") {
        return (
            <>
                <PreChargingScreen
                    location={location}
                    evse={evse}
                    cards={cards}
                    ok={() => {
                        setWaitingFrom("CONNECTED");
                    }}
                    error={() => {
                        setWaitingFrom("");
                        setError("Could not begin charging. Please ensure that your selected payment method has sufficient funds.")
                    }}
                    load={() => {
                        setWaitingFrom("CONNECTED");
                    }}
                />
                <ErrorModal open={!!error} message={error} handleClose={() => setError('')} />
            </>
        );
    }
    if (state === "CHARGING") {
        return (
            <ChargingScreen
                current={current}
                stop={() => {
                    setWaitingFrom("CHARGING")
                }}
            />
        );
    }
    if (state === "HOGGING") {
        return <HoggingScreen />;
    }
    if (state === "UNAVAILABLE") {
        return (
            <UnavailableScreen
                location={location}
                evse={evse}
            />
        );
    }

    return (
        <div>
            <Typography>Welcome to CGW</Typography>
        </div>
    );
}
