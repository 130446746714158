import { Button, Typography } from "@material-ui/core";
import TopBar from "../../components/common/TopBar";
import { useTheme } from "@material-ui/core/styles";
import { Div } from "../../components/base/Divs";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { AuthContext } from "../../context/AuthContext";

export default function EmailVerificationScreen() {
    const theme = useTheme();
    const location = useLocation();
    const history = useHistory();
    const [success, setSuccess] = useState(true);

    const { loggedIn } = useContext(AuthContext);

    useEffect(() => {
        const queries = new URLSearchParams(location.search);
        if (queries.get("success") === "true") {
            setSuccess(true);
        } else {
            setSuccess(false);
        }
    }, []);

    return (
        <>
            <TopBar />
            <Div
                style={{
                    justifyContent: "flex-start",
                    padding: "32px 16px",
                    overflow: "auto",
                    backgroundColor: theme.palette.background.default,
                }}
            >
                {success ? (
                    <>
                        <Typography
                            style={{ color: theme.palette.primary.main, marginBottom: 24 }}
                        >
                            Your email has been verified!
                        </Typography>
                        {loggedIn ? (
                            <Button
                                variant="contained"
                                style={{
                                    color: theme.palette.secondary.contrastText,
                                }}
                                onClick={() => history.push('/')}
                            >
                                Home
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                style={{
                                    color: theme.palette.secondary.contrastText,
                                }}
                                onClick={() => history.push('/login')}
                            >
                                Login
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <Typography
                            style={{
                                color: theme.palette.primary.main,
                                textAlign: "center",
                            }}
                        >
                            Oh no! Something went wrong verifying your email
                        </Typography>
                        <Typography
                            style={{
                                color: theme.palette.primary.main,
                                textAlign: "center",
                                marginTop: 14,
                                marginBottom: 14
                            }}
                        >
                            Please try again later!
                        </Typography>
                        <Button
                            variant="contained"
                            style={{
                                color: theme.palette.secondary.contrastText,
                            }}
                            onClick={() => history.push('/')}
                        >
                            Home
                        </Button>
                    </>
                )}
            </Div>
        </>
    );
}
