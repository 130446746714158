import { useEffect, useState } from "react";
import TopBar from "../../components/common/TopBar";
import {
    Grid,
    Typography,
    Button,
    IconButton,
    Dialog,
    DialogContent,
} from "@material-ui/core";
import { Theme, withStyles, styled, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import { DialogTitle } from "../../components/common/DialogTitle";
import { useHistory } from "react-router";
import config from '../../config';

const FilterButton = withStyles((theme: Theme) => ({
    root: {
        border: "1px solid silver",
        borderRadius: 16,
        textTransform: "none",
        color: theme.palette.neutral.main,
        minWidth: 80,
    },
}))(Button);

const FilterBox = styled("div")({
    width: "100%",
    border: "1px solid silver",
    borderRadius: 16,
    minHeight: 100,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignContent: "flex-start",
    flexWrap: "wrap",
    padding: 8,
});

const FilterTag = withStyles((theme: Theme) => ({
    root: {
        background: theme.palette.neutral.dark,
        borderRadius: 8,
        textTransform: "none",
        color: theme.palette.primary.main,
        minWidth: 80,
        margin: 4,
        "&:hover": {
            background: theme.palette.neutral.light,
            boxShadow: "none",
        },
    },
}))(Button);

export default function FilterScreen() {
    const [options, setOptions] = useState(() => []);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const theme = useTheme();

    useEffect(() => {
        const tags = localStorage.getItem("filterOptions");
        if (tags) {
            setOptions(JSON.parse(tags));
        }
    }, []);

    const handleClickDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleOptions = (value) => {
        if (options.includes(value)) {
            let newOptions = options.filter((o) => o !== value);
            setOptions(newOptions);
        } else {
            let newOptions = options.slice();
            newOptions.push(value);
            setOptions(newOptions);
        }
    };

    const apply = () => {
        const ls = [];
        if (options.includes("AC") && options.includes("DC")) {
            ls.push("chargerType=BOTH");
        } else if (options.includes("AC")) {
            ls.push("chargerType=AC");
        } else if (options.includes("DC")) {
            ls.push("chargerType=DC");
        }
        if (options.includes("Available") && options.includes("Unavailable")) {
            //pass
        } else if (options.includes("Available")) {
            ls.push("chargerAvailability=Available");
        } else if (options.includes("Unavailable")) {
            ls.push("chargerAvailability=Unavailable");
        }
        if (options.includes("CGW") && options.includes("Others")) {
            //pass
        } else if (options.includes("CGW")) {
            ls.push("onNetwork=true");
        } else if (options.includes("Others")) {
            ls.push("onNetwork=false");
        }
        if (options.includes("Public") && options.includes("Private")) {
            //pass
        } else if (options.includes("Public")) {
            ls.push("residentsOnly=false");
        } else if (options.includes("Private")) {
            ls.push("residentsOnly=true");
        }
        console.log("setting filter query", ls.join("&"));
        localStorage.setItem("filter", ls.join("&"));
        localStorage.setItem("filterOptions", JSON.stringify(options));
        history.push("/");
    };

    return (
        <>
            <TopBar title="Filter" back />
            <div style={{ height: '100%', overflow: 'auto' }}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    style={{
                        flex: 1,
                        justifyContent: "flex-start",
                        paddingTop: 16,
                        paddingLeft: 32,
                        paddingRight: 32,
                        paddingBottom: 32,
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <Grid
                        item
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <Typography
                            style={{
                                color: theme.palette.primary.main,
                                paddingTop: 8,
                                paddingBottom: 8,
                                fontWeight: 600,
                            }}
                        >
                            My Filters
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <FilterBox>
                            {options.map((option, i) => (
                                <FilterTag
                                    key={i}
                                    variant="contained"
                                    size="small"
                                    endIcon={<CloseIcon />}
                                    onClick={() => handleOptions(option)}
                                >
                                    {option}
                                </FilterTag>
                            ))}
                        </FilterBox>
                    </Grid>
                    <Grid container style={{ paddingBottom: 16 }}>
                        <Grid
                            item
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "100%",
                            }}
                        >
                            <Typography
                                style={{
                                    color: theme.palette.primary.main,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    fontWeight: 600,
                                }}
                            >
                                Accessibility Type
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid item style={{ paddingRight: 8 }}>
                                <FilterButton
                                    onClick={() => handleOptions("Public")}
                                    style={{
                                        borderColor: options.includes("Public")
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.light,
                                        color: options.includes("Public")
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.main,
                                    }}
                                >
                                    Public
                                </FilterButton>
                            </Grid>
                            <Grid item style={{ paddingRight: 8 }}>
                                <FilterButton
                                    onClick={() => handleOptions("Private")}
                                    style={{
                                        borderColor: options.includes("Private")
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.light,
                                        color: options.includes("Private")
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.main,
                                    }}
                                >
                                    Private
                                </FilterButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        config.LOCATOR_ONLY ?
                            null :
                            <Grid container style={{ paddingBottom: 16 }}>
                                <Grid
                                    item
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: theme.palette.primary.main,
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            fontWeight: 600,
                                        }}
                                    >
                                        Station Types
                                    </Typography>
                                    <IconButton
                                        aria-label="info"
                                        style={{
                                            padding: 0,
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            marginBottom: 4,
                                        }}
                                        onClick={handleClickDialog}
                                    >
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                                <Grid container>
                                    <Grid item style={{ paddingRight: 8 }}>
                                        <FilterButton
                                            onClick={() => handleOptions("CGW")}
                                            style={{
                                                borderColor: options.includes("CGW")
                                                    ? theme.palette.secondary.main
                                                    : theme.palette.neutral.light,
                                                color: options.includes("CGW")
                                                    ? theme.palette.secondary.main
                                                    : theme.palette.neutral.main,
                                            }}
                                        >
                                            CGW
                                        </FilterButton>
                                    </Grid>
                                    <Grid item style={{ paddingRight: 8 }}>
                                        <FilterButton
                                            onClick={() => handleOptions("Others")}
                                            style={{
                                                borderColor: options.includes("Others")
                                                    ? theme.palette.secondary.main
                                                    : theme.palette.neutral.light,
                                                color: options.includes("Others")
                                                    ? theme.palette.secondary.main
                                                    : theme.palette.neutral.main,
                                            }}
                                        >
                                            Others
                                        </FilterButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                    }

                    <Grid container style={{ paddingBottom: 16 }}>
                        <Grid
                            item
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "100%",
                            }}
                        >
                            <Typography
                                style={{
                                    color: theme.palette.primary.main,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    fontWeight: 600,
                                }}
                            >
                                Charger Types
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid item style={{ paddingRight: 8 }}>
                                <FilterButton
                                    onClick={() => handleOptions("AC")}
                                    style={{
                                        borderColor: options.includes("AC")
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.light,
                                        color: options.includes("AC")
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.main,
                                    }}
                                >
                                    AC
                                </FilterButton>
                            </Grid>
                            <Grid item style={{ paddingRight: 8 }}>
                                <FilterButton
                                    onClick={() => handleOptions("DC")}
                                    style={{
                                        borderColor: options.includes("DC")
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.light,
                                        color: options.includes("DC")
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.main,
                                    }}
                                >
                                    DC
                                </FilterButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ paddingBottom: 16 }}>
                        <Grid
                            item
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "100%",
                            }}
                        >
                            <Typography
                                style={{
                                    color: theme.palette.primary.main,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    fontWeight: 600,
                                }}
                            >
                                Charger Availability
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid item style={{ paddingRight: 8 }}>
                                <FilterButton
                                    onClick={() => handleOptions("Available")}
                                    style={{
                                        borderColor: options.includes(
                                            "Available"
                                        )
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.light,
                                        color: options.includes("Available")
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.main,
                                    }}
                                >
                                    Available
                                </FilterButton>
                            </Grid>
                            <Grid item style={{ paddingRight: 8 }}>
                                <FilterButton
                                    onClick={() => handleOptions("Unavailable")}
                                    style={{
                                        borderColor: options.includes(
                                            "Unavailable"
                                        )
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.light,
                                        color: options.includes("Unavailable")
                                            ? theme.palette.secondary.main
                                            : theme.palette.neutral.main,
                                    }}
                                >
                                    Unavailable
                                </FilterButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Button
                        style={{
                            backgroundColor: "#058B8E",
                            color: "white",
                            fontWeight: 400,
                            fontSize: "1rem",
                            borderRadius: 8,
                            width: "100%",
                            marginTop: 32,
                            paddingTop: 8,
                            paddingBottom: 8,
                        }}
                        onClick={apply}
                    >
                        Apply
                    </Button>
                </Grid>
            </div>
            <Dialog onClose={handleCloseDialog} open={open}>
                <DialogTitle
                    id="dialog-title"
                    onClose={handleCloseDialog}
                    iconColor={theme.palette.secondary.main}
                    color={theme.palette.background.default}
                />
                <DialogContent style={{ paddingBottom: 32 }}>
                    <Typography
                        style={{
                            color: theme.palette.primary.main,
                            textAlign: "center",
                            fontWeight: 600,
                            marginBottom: 32,
                        }}
                    >
                        What are Station Types?
                    </Typography>
                    <Typography style={{ color: theme.palette.primary.main }}>
                        We are in the midst of onboarding more partners to join
                        the CGW network so that you can enjoy a better charging
                        experience.
                        <br />
                        <br />
                        "Others" refer to charge point operators (CPOs) that are
                        not connected to the CGW network yet.
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    );
}
