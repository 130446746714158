import { Grid, Typography, withStyles } from "@material-ui/core";
import {
    ArrowForwardIos,
    RadioButtonChecked,
    RadioButtonUnchecked,
} from "@material-ui/icons";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Div, DivHor } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";
import { Tab } from "../../components/forms/Tab";
import { query } from "../../network";
import { shadow } from "../../shadows";
import { Location } from "../../typings";
import { useTheme, Theme, Button } from "@material-ui/core";
import { del } from "../../network";
import { sortingFunc } from "../../util";

const SummaryButton = withStyles((theme: Theme) => ({
    root: {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        textTransform: "none",
        paddingLeft: 36,
        paddingRight: 36,
        paddingTop: 0,
        paddingBottom: 0,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: "white",
        },
    },
}))(Button);

const DeleteButton = withStyles((theme: Theme) => ({
    root: {
        borderColor: theme.palette.warning.main,
        color: theme.palette.warning.main,
        textTransform: "none",
        paddingLeft: 36,
        paddingRight: 36,
        paddingTop: 0,
        paddingBottom: 0,
        "&:hover": {
            backgroundColor: theme.palette.warning.main,
            color: "white",
        },
    },
}))(Button);

const Favourite = ({
    location,
    isEdit,
    isSelected,
    toggleSelected,
}: {
    location: Location;
    isEdit: boolean;
    isSelected: boolean;
    toggleSelected: Function;
}) => {
    const history = useHistory();
    const theme = useTheme();
    return (
        <DivHor
            style={{
                justifyContent: "space-between",
                borderBottom: "1px lightgray solid",
            }}
            onClick={() => {
                if (isEdit) {
                    toggleSelected();
                } else {
                    history.push(`/location/${location.postalCode}`);
                }
            }}
        >
            <DivHor style={{}}>
                <img
                    alt=""
                    src="https://static.straitstimes.com.sg/s3fs-public/styles/x_large/public/articles/2019/03/15/colin-co2-15.jpg?itok=FnEuKLJS&timestamp=1552654693"
                    style={{
                        height: 64,
                        width: 64,
                        objectFit: "cover",
                        borderRadius: 8,
                    }}
                />
                <Div style={{ alignItems: "flex-start" }}>
                    <Typography
                        style={{
                            color: theme.palette.primary.main,
                            fontWeight: "bold",
                        }}
                    >
                        {location.name}
                    </Typography>
                    <Typography>SP Utilities</Typography>
                </Div>
            </DivHor>
            {isEdit ? (
                isSelected ? (
                    <RadioButtonChecked />
                ) : (
                    <RadioButtonUnchecked />
                )
            ) : (
                <ArrowForwardIos
                    style={{
                        color: theme.palette.neutral.light,
                        transform: "scale(0.8)",
                    }}
                />
            )}
        </DivHor>
    );
};

export default function FavouritesScreen() {
    const [mode, setMode] = useState<"MOST VISITED" | "CHEAPEST" | "NEAREST">(
        "MOST VISITED"
    );
    const theme = useTheme();
    const [isEdit, setIsEdit] = useState(false);
    const [selected, setSelected] = useState([]);
    const [position, setPosition] =
        useState<{ latitude: number; longitude: number }>(null);

    // Get locations if available, set listener
    useEffect(() => {
        try {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    setPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                });
            }
            if (!navigator.permissions) {
                return;
            }
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (permissionStatus) {
                    permissionStatus.onchange = function () {
                        if (this.state === "granted") {
                            navigator.geolocation.getCurrentPosition(
                                (position) => {
                                    setPosition({
                                        latitude: position.coords.latitude,
                                        longitude: position.coords.longitude,
                                    });
                                }
                            );
                        } else {
                            setPosition(null);
                        }
                    };
                });
        } catch (err) {
            alert(err);
        }
    }, []);


    const { data: locations, refetch } = useQuery("fav", () => query("me/locations"), {
        initialData: [],
    });

    const removeLocations = async (locations: string[]) => {
        for (const postalCode of locations) {
            await del(`me/locations/${postalCode}`, {})
        }
        refetch()
        setSelected([])
    }

    return (
        <>
            <TopBar
                title="Favourites"
                back
                RightComponent={
                    <Typography
                        style={{ color: theme.palette.primary.main }}
                        onClick={() => setIsEdit((e) => !e)}
                    >
                        {isEdit ? "Done" : "Edit" }
                    </Typography>
                }
            />
            <Grid
                container
                direction="column"
                alignItems="stretch"
                style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    backgroundColor: theme.palette.background.default,
                }}
            >
                {isEdit ? (
                    <Div
                        style={{
                            padding: "14px 0px",
                            borderBottom: "1px solid lightgray",
                            flexDirection: "row",
                        }}
                    >
                        {locations.length === selected.length ? (
                            <SummaryButton
                                variant="outlined"
                                onClick={() => setSelected([])}
                            >
                                Deselect All
                            </SummaryButton>
                        ) : (
                            <SummaryButton
                                    variant="outlined"
                                    style={{ padding: "0px 14px" }}
                                onClick={() =>
                                    setSelected([
                                        ...locations.map((loc) => loc.postalCode),
                                    ])
                                }
                            >
                                Select All
                            </SummaryButton>
                        )}
                        {selected.length > 0 && (
                            <DeleteButton
                                variant="outlined"
                                style={{ borderColor: theme.palette.warning.main, color:theme.palette.warning.main, marginLeft: 8, padding: "0px 14px" }}
                                onClick={() => removeLocations(selected)}
                            >
                                Delete {selected.length} Selected
                            </DeleteButton>
                        )}
                    </Div>
                ) : (
                    <DivHor
                        style={{
                            justifyContent: "space-evenly",
                            padding: 0,
                            alignItems: "stretch",
                            zIndex: 101,
                            ...shadow,
                        }}
                    >
                        <Tab
                            title="Most Visited"
                            active={mode === "MOST VISITED"}
                            onClick={() => setMode("MOST VISITED")}
                        />
                        <Tab
                            title="Cheapest"
                            active={mode === "CHEAPEST"}
                            onClick={() => setMode("CHEAPEST")}
                        />
                        <Tab
                            title="Nearest"
                            active={mode === "NEAREST"}
                            onClick={() => setMode("NEAREST")}
                        />
                    </DivHor>
                )}
                {mode === "NEAREST" && !position ? (
                    <Div style={{ padding: 24 }}>
                        <Typography style={{ textAlign: "center" }}>
                            Location details not available on your device.
                        </Typography>
                        <Typography style={{ textAlign: "center" }}>
                            Please check your device permissions for this
                            webpage.
                        </Typography>
                    </Div>
                ) : (
                    <>
                        {locations.sort((a, b) => sortingFunc(a, b, mode, position)).map((l) => (
                            <Favourite
                                location={l}
                                isEdit={isEdit}
                                isSelected={selected.includes(l.postalCode)}
                                toggleSelected={() => {
                                    setSelected((sel) => {
                                        if (sel.includes(l.postalCode)) {
                                            return sel.filter(
                                                (loc) => loc !== l.postalCode
                                            );
                                        } else {
                                            return [...sel, l.postalCode];
                                        }
                                    });
                                }}
                            />
                        ))}
                    </>
                )}
            </Grid>
        </>
    );
}
