import { Button, Dialog, DialogContent, Typography, useTheme } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import { useEffect, useRef, useState, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FixedSizeList } from "react-window";
import { Div, DivHor } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { post, query } from "../../network";
import { shadow } from "../../shadows";
import { Location, Transaction } from "../../typings";
import { ListItem } from "../location/LocationScreen";
import { ChargingIndicator } from "./components/ChargingIndicator";
import { DualButton } from "./components/DualButton";
import { LocationListItem } from "./components/LocationListItem";
import { Marker } from "./components/Markers";
import { ScanButton } from "./components/ScanButton";
import { SearchInput } from "./components/SearchInput";
import WelcomePopup from "../../components/overlay/WelcomePopup";
import { AuthContext } from "../../context/AuthContext";
import AutoSizer from "react-virtualized-auto-sizer";
import { DialogTitle } from "../../components/common/DialogTitle";
import { darkModeStyle, sortingFunc } from "../../util";
import { Tab } from "../../components/forms/Tab";
import config from '../../config';
import BetaPopup from "../../components/overlay/BetaPopup";

const { GOOGLE_MAPS_API_KEY } = config;

function useSearchParams() {
    return new URLSearchParams(useLocation().search);
}

const IsChargingDialog = (
    { open, handleClose }: { open: boolean, handleClose: Function }
) => {
    const theme = useTheme();
    const closeHandler = () => {
        handleClose();
    }
    return (
        <Dialog onClose={closeHandler} open={open}>
            <DialogTitle id="dialog-title" onClose={closeHandler} iconColor={theme.palette.secondary.main} color={theme.palette.background.default} />
            <DialogContent style={{ paddingBottom: 32, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography style={{ color: theme.palette.primary.main, textAlign: 'center', fontWeight: 600, marginBottom: 32, marginTop: 16, }}>
                    Looks like you're already charging!
                </Typography>
                <Button variant="outlined" onClick={closeHandler} style={{ borderColor: theme.palette.secondary.main, color: theme.palette.secondary.main, textTransform: 'none' }}>Go back to charging</Button>
            </DialogContent>
        </Dialog>
    )
}

export default function MapScreen() {
    const height = useWindowDimensions().height;
    const containerRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const history = useHistory();
    const { postalCode } = useParams<{ postalCode: string }>();
    const queryParams = useSearchParams();
    const preferDark = localStorage.getItem('darkMode') === 'true';

    const [searchTerm, setSearchTerm] = useState("");
    const [zoomLevel, setZoomLevel] = useState(10);

    const [mapHeight, setMapHeight] = useState(0);
    const [isList, setIsList] = useState(false);
    const [map, setMap] = useState(null);

    const { loggedIn, setLoggedIn } = useContext(AuthContext);
    const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);
    const openWelcomeDialog = () => setTimeout(() => {
        if (!localStorage.getItem('token')) setShowWelcomeDialog(true)
    }, 3000);

    const closeWelcomeDialog = () => {
        setShowWelcomeDialog(false);
    };

    const [showIsChargingDialog, setShowIsChargingDialog] = useState(false);

    const openIsChargingDialog = () => {
        setShowIsChargingDialog(true);
    }
    const closeIsChargingDialog = () => {
        setShowIsChargingDialog(false);
    };

    const theme = useTheme();

    const [mode, setMode] = useState<"CHEAPEST" | "NEAREST">(
        "CHEAPEST"
    );

    const [position, setPosition] =
        useState<{ latitude: number; longitude: number }>(null);

    // Get locations if available, set listener
    useEffect(() => {
        try {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    setPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                });
            }
            if (!navigator.permissions) {
                return;
            }
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (permissionStatus) {
                    permissionStatus.onchange = function () {
                        if (this.state === "granted") {
                            navigator.geolocation.getCurrentPosition(
                                (position) => {
                                    setPosition({
                                        latitude: position.coords.latitude,
                                        longitude: position.coords.longitude,
                                    });
                                }
                            );
                        } else {
                            setPosition(null);
                        }
                    };
                });
        } catch (err) {
            alert(err);
        }
    }, []);

    const filter = localStorage.getItem('filter') || ''
    const { data: locations } = useQuery("loc", () => query(`locations/filtered?${filter}`), {
        initialData: [],
    });

    const sortedLocations = useMemo(() => {
        const sorted = [...locations];
        sorted.sort((a, b) => sortingFunc(a, b, mode, position))
        console.log('sorted by', mode, sorted.map(loc => loc.name))
        return sorted;
    }, [locations, mode, position])

    const { data: current, isSuccess: gotCurrent } = useQuery<Transaction>(
        "cur-1",
        () => query(`me/transactions/current`),
        { refetchInterval: 4000, enabled: loggedIn }
    );
    console.log("current", current);
    console.log("locations", locations);

    useEffect(() => {
        const queries = new URLSearchParams(location.search)
        if (queries.get('verify') && queries.get('user')) {
            post('auth/verifyEmail', { verificationCode: queries.get('verify'), userID: queries.get('user') })
                .then(res => {
                    console.log('email verification res', res)
                    if (res.ok) {
                        history.push('/emailVerification?success=true')
                    } else {
                        history.push('/emailVerification?success=false')
                    }
                })
        }
    }, [])

    useEffect(() => {
        if (postalCode) {
            const found = locations.find(
                (l) => l.postalCode.toString() === postalCode
            );
            if (found) setLocatedSelection(found);
        } else {
            setLocatedSelection(null);
        }
    }, [postalCode, locations]);

    useEffect(() => {
        const st = queryParams.get("searchTerm");
        if (st) setSearchTerm(st);
        const il = queryParams.get("list");
        setIsList(!!il);
    }, [queryParams]);

    const openDialog = () =>
        setTimeout(() => {
            if (!localStorage.getItem("token")) setShowWelcomeDialog(true);
        }, 3000);

    const closeDialog = () => {
        setShowWelcomeDialog(false);
    };

    useEffect(() => {
        if (!loggedIn) {
            openWelcomeDialog();
        }
    }, [loggedIn]);

    const [selectedLocation, setLocatedSelection] = useState(null);
    useEffect(() => {
        // if (!mapRef.current) return;
        // const h = height - (overlayRef.current?.clientHeight ?? 0);
        // console.log("H", h);
        // mapRef.current.style.height = h + "px";
        // setMapHeight(h);
    }, [location, height, selectedLocation]);

    const handleScanButton = () => {
        if (current) {
            openIsChargingDialog();
        } else {
            history.push('/scan');
        }
    }

    const markerClick = (l: Location) => {
        const loc = locations.find(
            (c) => c.postalCode === l.postalCode
        );
        history.push(`${l.postalCode}`);
        setLocatedSelection((c) => {
            return loc
        });
    }

    return (
        <>
            <TopBar
                isMap={true}
                BottomComponent={
                    current ? <ChargingIndicator current={current} /> : null
                }
            />

            {
                // show WelcomePopup when not LOCATOR_ONLY
                // show BetaPopup when LOCATOR_ONLY
                config.LOCATOR_ONLY ?
                    <BetaPopup open={showWelcomeDialog} handleClose={closeWelcomeDialog} /> :
                    <WelcomePopup open={showWelcomeDialog} handleClose={closeWelcomeDialog} />
            }

            <IsChargingDialog open={showIsChargingDialog} handleClose={closeIsChargingDialog} />
            <div
                id="container"
                style={{
                    padding: 0,
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
                ref={containerRef}
            >
                <div style={{ width: "100vw", flexGrow: 1, height: "100%", zIndex: 0, backgroundColor: theme.palette.background.default }} ref={mapRef}>
                    <DivHor
                        style={{
                            justifyContent: "space-evenly",
                            padding: 0,
                            alignItems: "stretch",
                            zIndex: 101,
                            ...shadow,
                        }}
                    >
                        <Tab
                            title="Cheapest"
                            active={mode === "CHEAPEST"}
                            onClick={() => setMode("CHEAPEST")}
                        />
                        <Tab
                            title="Nearest"
                            active={mode === "NEAREST"}
                            onClick={() => setMode("NEAREST")}
                        />
                    </DivHor>
                    {
                        (mode === "NEAREST" && !position) && (
                            <Div style={{ padding: 24 }}>
                                <Typography style={{ textAlign: "center" }}>
                                    Location details not available on your device.
                                </Typography>
                                <Typography style={{ textAlign: "center" }}>
                                    Please check your device permissions for this
                                    webpage.
                                </Typography>
                            </Div>
                        )
                    }
                    {isList ? (
                        <AutoSizer>
                            {({ height }) => (
                                <FixedSizeList
                                    height={height}
                                    width="100vw"
                                    itemCount={locations?.length}
                                    itemSize={250}
                                >
                                    {({ index, style }) => (
                                        <LocationListItem
                                            location={sortedLocations[index]}
                                            style={{ ...style, paddingBottom: index === locations.length - 1 ? 120 : 0 }}
                                            key={sortedLocations[index]._id}
                                            currentLocation={position}
                                        />
                                    )}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    ) : (
                        <GoogleMapReact
                            style={{}}
                            bootstrapURLKeys={{
                                key: GOOGLE_MAPS_API_KEY,
                                libraries: [
                                    "places",
                                    "geometry",
                                    "drawing",
                                    "visualization",
                                ],
                            }}
                            defaultCenter={{ lat: 1.3521, lng: 103.8198 }}
                            defaultZoom={11}
                            onClick={() => {
                                history.push("");
                                setLocatedSelection(null);
                            }}
                            center={
                                selectedLocation
                                    ? {
                                        lat: parseFloat(
                                            selectedLocation.coordinates
                                                .latitude
                                        ),
                                        lng: parseFloat(
                                            selectedLocation.coordinates
                                                .longitude
                                        ),
                                    }
                                    : null
                            }
                            onChildClick={(c) => {
                                history.push(`${c}`);
                                const loc = locations.find(
                                    (l) => l.postalCode === c
                                );
                                setLocatedSelection(loc);
                            }}
                            hoverDistance={70}
                            options={{
                                clickableIcons: false,
                                fullscreenControl: false,
                                zoomControl: false,
                                gestureHandling: "greedy",
                                minZoom: 11,
                                styles: preferDark ? darkModeStyle : []
                            }}
                            onZoomAnimationEnd={(zoomLevel) =>
                                setZoomLevel(zoomLevel)
                            }
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => setMap(map)}
                        >
                            {locations?.map((l) => (
                                <Marker
                                    lat={l.coordinates.latitude}
                                    lng={l.coordinates.longitude}
                                    location={l}
                                    key={l.postalCode}
                                    zoomLevel={zoomLevel}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        markerClick(l)
                                    }}
                                />
                            ))}
                        </GoogleMapReact>
                    )}
                </div>
                <div
                    style={{
                        background: theme.palette.background.default,
                        display: "flex",
                        alignSelf: "stretch",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "relative",
                        zIndex: 100,
                    }}
                    ref={overlayRef}
                >
                    <DualButton
                        isList={isList}
                        style={{ right: 18, top: -56, zIndex: 995 }}
                    />
                    {selectedLocation ? (
                        <div
                            style={{ alignSelf: "stretch", padding: 14 }}
                            onClick={() =>
                                history.push(
                                    `location/${selectedLocation.postalCode}`
                                )
                            }
                        >
                            <ListItem location={selectedLocation} />
                        </div>
                    ) : (
                        <Div
                            style={{
                                padding: "0px 14px 14px 14px",
                                alignSelf: "stretch",
                                ...shadow,
                            }}
                        >
                            <SearchInput searchTerm={searchTerm} />

                            {
                                // remove scan button in LOCATOR_ONLY mode
                                config.LOCATOR_ONLY ?
                                    null :
                                    <ScanButton clickHandler={handleScanButton} />
                            }

                        </Div>
                    )}
                </div>
            </div>
        </>
    );
}
