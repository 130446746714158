import { Dialog, DialogContent, DialogContentText, useTheme } from "@material-ui/core";
import { DialogTitle } from "../../components/common/DialogTitle";

export const ErrorModal = ({
    open, handleClose, message
}:{
    open: boolean, handleClose: Function, message: string
}) => {
    const theme = useTheme();
   
    const closeHandler = () => {
        handleClose();
    }

    return (
        <Dialog open={open} onClose={closeHandler} aria-labelledby="dialog-title">
            <DialogTitle id="dialog-title" onClose={closeHandler} iconColor={theme.palette.secondary.contrastText} color={theme.palette.secondary.main}>
                Error
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
        </Dialog>
    )
}