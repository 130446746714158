import { createTheme, ThemeProvider } from "@material-ui/core";
import React, { useEffect, useState, useMemo } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import "./App.css";
import { SideBar, SidebarContext } from "./components/common/SideBar";
import { WelcomeOverlay } from "./components/overlay/WelcomeOverlay";
import { AuthProvider } from "./context/AuthContext";
import Main from "./screens/charging/Main";
import LoginScreen from "./screens/auth/LoginScreen";
import RegisterScreen from "./screens/auth/RegisterScreen";
import ReceiptScreen from "./screens/charging/ReceiptScreen";
import LocationScreen from "./screens/location/LocationScreen";
import MapScreen from "./screens/maps/MapScreen";
import AboutScreen from "./screens/misc/AboutScreen";
import AccountScreen from "./screens/misc/AccountScreen";
import FavouritesScreen from "./screens/misc/FavouritesScreen";
import LogoutScreen from "./screens/misc/LogoutScreen";
import PaymentComplete from "./screens/misc/PaymentComplete";
import AddPMScreen from "./screens/misc/AddPMScreen";
import SupportScreen from "./screens/misc/SupportScreen";
import TransactionHistoryScreen from "./screens/misc/TransactionHistoryScreen";
import OnboardingScreen from "./screens/onboarding/OnboardingScreen";
import ScanScreen from "./screens/scan/ScanScreen";
import SearchScreen from "./screens/search/SearchScreen";
import { themeProps, themeOverrides } from "./themes";
import PMScreen from "./screens/misc/PMScreen";
import LegendScreen from "./screens/misc/LegendScreen";
import FilterScreen from "./screens/misc/FilterScreen";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EmailVerificationScreen from "./screens/misc/EmailVerificationScreen";

function App() {
    const queryClient = new QueryClient();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => setSidebarOpen((s) => !s);
    const closeSidebar = () => setSidebarOpen(false);

    const [overlay, setOverlay] = useState(true);
    useEffect(() => {
        setTimeout(() => setOverlay(false), 2000);
    }, []);

    const [preferDark, setPreferDark] = useState(localStorage.getItem('darkMode') === 'true');

    const togglePreferDark = () => {
        const darkMode = localStorage.getItem('darkMode') === 'true'
        setPreferDark(!darkMode);
        localStorage.setItem('darkMode', !darkMode ? 'true' : 'false')
    }

    const chosenTheme = useMemo(() => {
        const chosenTheme = createTheme({
            palette: {
                type: preferDark ? 'dark' : 'light',
                primary: {
                    main: preferDark ? '#fff' : '#48466D',
                },
                secondary: {
                    main: preferDark ? '#03DAC5' : '#058B8E',
                    light: preferDark ? '#70EFDE' : '#1DB5B1',
                },
                neutral: {
                    light: '#888888',
                    main: '#868686',
                    dark: '#d6d6d8',
                },
                success: {
                    main: '#4FAF2D',
                },
                warning: {
                    main: '#C94C4C',
                },
                background: {
                    default: preferDark ? '#121212' : '#fff',
                    paper: preferDark ? '#1E1E1E' : '#fafafa',
                }
            }
        });
        chosenTheme.props = themeProps(chosenTheme);
        chosenTheme.overrides = themeOverrides(chosenTheme);
        return chosenTheme;
    }, [preferDark]);

    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <SidebarContext.Provider
                    value={{ toggleSidebar, closeSidebar }}
                >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={chosenTheme}>
                            <div
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    overflow: "hidden",
                                    backgroundColor: chosenTheme.palette.background.default
                                }}
                            >
                                {overlay && <WelcomeOverlay />}
                                <Router>
                                    <SideBar
                                        open={sidebarOpen}
                                        close={() => setSidebarOpen(false)}
                                        preferDark={preferDark}
                                        togglePreferDark={togglePreferDark}
                                    />
                                    <AnimatedSwitch
                                        atEnter={{ opacity: 0 }}
                                        atLeave={{ opacity: 0 }}
                                        atActive={{ opacity: 1 }}
                                        className="switch-wrapper"
                                    >
                                        <Route path="/charger/:postalCode/:evseId">
                                            <Main />
                                        </Route>
                                        <Route path="/scan">
                                            <ScanScreen />
                                        </Route>
                                        <Route path="/search">
                                            <SearchScreen />
                                        </Route>
                                        <Route path="/receipt">
                                            <ReceiptScreen />
                                        </Route>
                                        <Route path="/emailVerification">
                                            <EmailVerificationScreen />
                                        </Route>

                                        <Route path="/login">
                                            <LoginScreen />
                                        </Route>
                                        <Route path="/register">
                                            <RegisterScreen />
                                        </Route>
                                        <Route path="/logout">
                                            <LogoutScreen />
                                        </Route>
                                        <Route path="/onboarding">
                                            <OnboardingScreen />
                                        </Route>
                                        <Route path="/account">
                                            <AccountScreen />
                                        </Route>
                                        <Route path="/about">
                                            <AboutScreen />
                                        </Route>
                                        <Route path="/favourites">
                                            <FavouritesScreen />
                                        </Route>
                                        <Route path="/paymentMethods">
                                            <PMScreen />
                                        </Route>
                                        <Route path="/addPaymentMethod">
                                            <AddPMScreen />
                                        </Route>
                                        <Route path="/paymentComplete">
                                            <PaymentComplete />
                                        </Route>
                                        <Route path="/support">
                                            <SupportScreen />
                                        </Route>
                                        <Route path="/legend">
                                            <LegendScreen />
                                        </Route>
                                        <Route path="/filter">
                                            <FilterScreen />
                                        </Route>
                                        <Route path="/transactions/:transactionId?">
                                            <TransactionHistoryScreen />
                                        </Route>
                                        <Route path="/location/:postalCode">
                                            <LocationScreen />
                                        </Route>
                                        <Route path="/:postalCode?">
                                            <MapScreen />
                                        </Route>
                                    </AnimatedSwitch>
                                </Router>
                            </div>
                        </ThemeProvider>
                    </MuiPickersUtilsProvider>
                </SidebarContext.Provider>
            </AuthProvider>
        </QueryClientProvider>
    );
}

export default App;
