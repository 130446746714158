const {
    REACT_APP_LOCATOR_ONLY,
    REACT_APP_API_URL,
    REACT_APP_GOOGLE_CLIENT_ID,
    REACT_APP_GOOGLE_MAPS_API_KEY,
    REACT_APP_SCAN_URL_PREFIX,
} = process.env;

const config = {
    LOCATOR_ONLY: REACT_APP_LOCATOR_ONLY.trim().toLowerCase() === "true" ? true : false,
    API_URL: REACT_APP_API_URL || "localhost:8080",
    GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_CLIENT_ID,
    GOOGLE_MAPS_API_KEY: REACT_APP_GOOGLE_MAPS_API_KEY,
    SCAN_URL_PREFIX: REACT_APP_SCAN_URL_PREFIX || "https://chargegowhere.sg/charger",
}

if (process.env.NODE_ENV === "development"){
    console.log(config);
}

export default config;