import { Grid, Typography } from "@material-ui/core";
import Logo from "../../components/common/Logo";
import TopBar from "../../components/common/TopBar";
import OnboardingFrameA from "./frames/OnboardingFrameA";
import OnboardingFrameB from "./frames/OnboardingFrameB";
import OnboardingFrameC from "./frames/OnboardingFrameC";
import OnboardingFrameD from "./frames/OnboardingFrameD";
import OnboardingFrameE from "./frames/OnboardingFrameE";
import OnboardingFrameF from "./frames/OnboardingFrameF";
import OnboardingFrameG from "./frames/OnboardingFrameG";
import OnboardingFrameH from "./frames/OnboardingFrameH";
import OnboardingFrameI from "./frames/OnboardingFrameI";
import OnboardingFrameJ from "./frames/OnboardingFrameJ";
import OnboardingFrameK from "./frames/OnboardingFrameK";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useEffect, useState } from "react";

export default function OnboardingScreen() {
    const [index, setIndex] = useState(0);
    const Frame = frames[index];

    useEffect(() => {
        setInterval(() => {
            setIndex((i) => (i === frames.length - 1 ? 0 : i + 1));
        }, 2000);
    }, []);

    return (
        <>
            <TopBar title="About" back />
            <Typography
                style={{
                    color: "black",
                    position: "absolute",
                    bottom: "20px",
                    right: "50px",
                    fontSize: "20px",
                }}
            >
                Skip{" "}
                <span style={{ position: "relative" }}>
                    <ArrowRightAltIcon
                        fontSize="large"
                        style={{ position: "absolute", top: "2px" }}
                    />
                </span>
            </Typography>
            <Frame />
            {/* <Grid
                container
                direction="column"
                alignItems="center"
                style={{
                    flex: 1,
                    justifyContent: "center",
                    paddingTop: 32,
                }}
            >
                <img
                    src="/images/about-us-background.png"
                    style={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        objectFit: "contain",
                        width: "100vw",
                        zIndex: -100,
                        transform: "translateY(40px)",
                    }}
                    alt=""
                />
                <Typography style={{ color: "#48466D" }}>
                    Placeholder title
                </Typography>
                <Typography
                    style={{
                        color: "#48466D",
                        padding: "32px 48px",
                        fontStyle: "italic",
                        paddingBottom: "50%",
                    }}
                >
                    Placeholder body
                </Typography>
            </Grid> */}
        </>
    );
}

const frames = [
    OnboardingFrameA,
    OnboardingFrameB,
    OnboardingFrameC,
    OnboardingFrameD,
    OnboardingFrameE,
    OnboardingFrameF,
    OnboardingFrameG,
    OnboardingFrameH,
    OnboardingFrameI,
    OnboardingFrameJ,
    OnboardingFrameK,
];
