import { Typography, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { DivHor } from "../../../components/base/Divs";

type P = {
    isList: boolean;
    style: React.CSSProperties;
};

export const DualButton = ({ isList, style }: P) => {
    const history = useHistory();
    const theme = useTheme();
    const mapClicked = () => {
        history.push("/");
    };
    const listClicked = () => {
        history.push("/?list=true");
    };
    return (
        <DivHor
            style={{
                position: "absolute",
                ...style,
                backgroundColor: theme.palette.secondary.main,
                padding: 2,
                borderRadius: 10,
            }}
        >
            <Typography
                onClick={mapClicked}
                style={{
                    width: 64,
                    padding: "2px 0px",
                    textAlign: "center",
                    color: isList ? theme.palette.secondary.main : theme.palette.background.default,
                    backgroundColor: isList ? theme.palette.background.default : theme.palette.secondary.main,
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                }}
            >
                Map
            </Typography>
            <Typography
                onClick={listClicked}
                style={{
                    width: 64,
                    padding: "2px 0px",
                    textAlign: "center",
                    color: isList ? theme.palette.background.default : theme.palette.secondary.main,
                    backgroundColor: isList ? theme.palette.secondary.main : theme.palette.background.default,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                }}
            >
                List
            </Typography>
        </DivHor>
    );
};
