import { Typography, useTheme } from "@material-ui/core";
import { Div, DivHor } from "../../../components/base/Divs";

type P = {
    title: string;
    text: string;
};

export default function DetailRow({ title, text }: P) {
    const theme = useTheme()
    return (
        <Div
            style={{
                borderBottomStyle: "solid",
                borderBottomColor: "#D9D9D9",
                borderBottomWidth: 1,
                alignSelf: "stretch",
                alignItems: "flex-start",
                margin: "0px 14px",
                padding: "8px 10px",
            }}
        >
            <Typography
                style={{
                    color: theme.palette.primary.main,
                    fontSize: 12,
                    marginRight: 8,
                }}
            >
                {title}
            </Typography>
            <Typography
                style={{
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    fontSize: 15,
                    marginTop: 4,
                }}
            >
                {text}
            </Typography>
        </Div>
    );
}
