import { Button, Grid, Typography, useTheme } from "@material-ui/core";
import { Div, DivHor } from "../../components/base/Divs";
import InfoButton from "../../components/base/InfoButton";
import TopBar from "../../components/common/TopBar";
import { Transaction } from "../../typings";
import LargeDetail from "./components/LargeDetail";
import StatusText from "./components/StatusText";
import SwipeableButton from "../../components/base/SwipeableButton";
import ChargingAnimation from "../../components/common/ChargingAnimation";
import { MaskOverlay } from "../../components/overlay/MaskOverlay";
import { post } from "../../network";

type P = {
    current: Transaction;
    stop?: Function;
};
export default function ChargingScreen({ current, stop: _stop }: P) {
    const theme = useTheme();
    const stop = () => {
        post(`transactions/end/${current._id}`, {}).then((res) => {
            if (res.ok) {
                _stop?.();
            } else {
                res.text().then((t) => console.error(t));
            }
        });
    };
    const timeElapsed = ((new Date().getTime() - new Date(current?.startDateTime).getTime()) / 60 / 1000);
    return (
        <>
            {/* <MaskOverlay /> */}
            <TopBar title="Charging" showMap />
            <Div
                style={{
                    padding: 14,
                    backgroundColor: theme.palette.background.default,
                    height: '100%',
                    overflowY: 'auto'
                }}
            >
                <Div>
                    {/* <img
                        src="/images/charging.png"
                        style={{
                            width: 180,
                            height: 96,
                            objectFit: "contain",
                            margin: "14px 0px",
                        }}
                        alt="Charging"
                    /> */}
                    <ChargingAnimation />
                    <Typography style={{ fontSize: 12, textAlign: "center", color: theme.palette.neutral.main }}>
                        Currently there are 200 other people reducing carbon
                        emissions with you!
                    </Typography>

                    <InfoButton style={{ marginTop: 18, marginBottom: 18 }} />

                    <DivHor
                        style={{
                            borderTopWidth: 1,
                            borderTopColor: "lightgray",
                            borderTopStyle: "solid",
                        }}
                    >
                        <LargeDetail
                            title="Total Charge"
                            text={current?.totalKWHCharged?.toFixed(2)}
                            suffix="kWh"
                        />
                        <span
                            style={{
                                borderRightWidth: 1,
                                borderRightColor: "lightgray",
                                borderRightStyle: "solid",
                                alignSelf: "stretch",
                            }}
                        />
                        <LargeDetail
                            title="Time Elapsed"
                            text={timeElapsed.toFixed(0)}
                            suffix="mins"
                        />
                    </DivHor>
                </Div>

                <Div>
                    <StatusText text="Charger connected" />
                    <SwipeableButton
                        height={50}
                        width={240}
                        overlayColor="linear-gradient(90deg, rgba(227,169,170,.9) 0%, rgba(201,77,76,.9) 40%)"
                        sliderColor="#C94D4C"
                        mainText="Stop Charging"
                        onSuccess={stop}
                    />
                </Div>
            </Div>
        </>
    );
}
