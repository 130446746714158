import { AppBar, IconButton, Toolbar, Typography, useTheme } from "@material-ui/core";
import { ArrowForwardIos, CropFree, Map, ScannerOutlined } from "@material-ui/icons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Div, DivHor } from "../base/Divs";
import Logo from "./Logo";
import { SidebarContext } from "./SideBar";
import FilterListIcon from '@material-ui/icons/FilterList';
import { shadow } from "../../shadows";
import config from "../../config";

export default function TopBar({
    title,
    logoURL,
    back = false,
    backURL,
    bannerText = "",
    bannerAction = () => { },
    noCenter = false,
    noRight = false,
    showMap = false,
    showScan = false,
    isMap = false,
    RightComponent,
    BottomComponent = null,
}: {
    title?: string;
    logoURL?: string;
    bannerText?: string;
    bannerAction?: Function;
    back?: boolean;
    backURL?: string;
    noCenter?: boolean;
    noRight?: boolean;
    showMap?: boolean;
    isMap?: boolean;
    showScan?: boolean;
    RightComponent?: React.ReactNode;
    BottomComponent?: React.ReactNode;
}) {
    const history = useHistory();
    const theme = useTheme();
    const { toggleSidebar } = useContext(SidebarContext);
    const { loggedIn } = useContext(AuthContext);
    return (
        <>
            <AppBar
                position="sticky"
                style={{
                    width: "100vw",
                    zIndex: 101,
                }}
            >
                <Toolbar
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 0,
                    }}
                    className="has-shadow"
                >
                    <DivHor
                        style={{
                            padding: 0,
                            height: 64,
                            width: "100vw",
                            zIndex: 102,
                            // borderBottomColor: theme.palette.neutral.main,
                            // borderBottomWidth: 1,
                            // borderBottomStyle: "solid",
                        }}
                    >
                        <span style={{ position: "absolute", left: 20 }}>
                            {back ? (
                                <IconButton
                                    edge="start"
                                    style={{}}
                                    onClick={() => {
                                        if (backURL) {
                                            history.push(backURL)
                                        } else {
                                            history.goBack()
                                        }
                                    }}
                                >
                                    <ArrowBackIosIcon style={{ fill: theme.palette.secondary.main }} />
                                </IconButton>
                            ) : (
                                <IconButton
                                    edge="start"
                                    onClick={toggleSidebar}
                                >
                                    <MenuIcon style={{ fill: theme.palette.secondary.main }} />
                                </IconButton>
                            )}
                        </span>
                        <span
                            style={{
                                position: "absolute",
                                left: 70,
                                right: 70,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {
                                logoURL && (
                                    <img
                                        src={logoURL}
                                        style={{
                                            height: 28,
                                            marginRight: 14,
                                            borderRadius: 180,
                                            ...shadow,
                                        }}
                                        alt=""
                                        onClick={() => history.push("/")}
                                    />
                                )
                            }
                            {title != null ? (
                                <Typography
                                    style={{
                                        color: theme.palette.secondary.main,
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        textAlign: "center",
                                    }}
                                >
                                    {title}
                                </Typography>
                            ) : (
                                <Logo onClick={() => history.push("/")} />
                            )}
                        </span>
                        <span style={{ position: "absolute", right: 20 }}>
                            {RightComponent ? RightComponent : <></>}
                            {isMap && (
                                <>
                                    <IconButton
                                        edge="end"
                                        onClick={() => history.push("/legend")}
                                    >
                                        <Div style={{ padding: 0 }}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="9.67578" y="10.666" width="12.3243" height="9.33333" rx="2" fill={theme.palette.secondary.main} />
                                                <path d="M8.98845 6.69978L11.8951 11.1841C12.5419 12.1821 11.8256 13.5 10.6363 13.5H4.82311C3.63382 13.5 2.91753 12.1821 3.56439 11.1841L6.47101 6.69978C7.06226 5.7876 8.3972 5.7876 8.98845 6.69978Z" fill="none" stroke={theme.palette.secondary.main} strokeWidth="3" />
                                            </svg>

                                            <Typography style={{ fontSize: 8 }}>
                                                Legend
                                            </Typography>
                                        </Div>
                                    </IconButton>
                                    <IconButton
                                        edge="end"
                                        onClick={() => history.push("/filter")}
                                    >
                                        <Div style={{ padding: 0 }}>
                                            <FilterListIcon style={{ fill: theme.palette.secondary.main }} />
                                            <Typography style={{ fontSize: 8 }}>
                                                Filter
                                            </Typography>
                                        </Div>
                                    </IconButton>
                                </>
                            )}
                            {!loggedIn && !config.LOCATOR_ONLY && (
                                <IconButton
                                    edge="end"
                                    style={{
                                        opacity: noRight ? 0 : 1,
                                    }}
                                    onClick={() => history.push("/login")}
                                >
                                    <Div style={{ padding: 0 }}>
                                        <ExitToAppIcon
                                            style={{
                                                fill: theme.palette.secondary.main,
                                                transform: "rotate(180deg)",
                                            }}
                                        />
                                        <Typography style={{ fontSize: 8 }}>
                                            Login
                                        </Typography>
                                    </Div>
                                </IconButton>
                            )}
                            {!!showMap && (
                                <IconButton
                                    edge="end"
                                    onClick={() => history.push("/")}
                                >
                                    <Div style={{ padding: 0 }}>
                                        <Map />
                                        <Typography style={{ fontSize: 8 }}>
                                            Map
                                        </Typography>
                                    </Div>
                                </IconButton>
                            )}
                            {!!showScan && !config.LOCATOR_ONLY && (
                                <IconButton
                                    edge="end"
                                    onClick={() => history.push("/scan")}
                                >
                                    <Div style={{ padding: 0 }}>
                                        <CropFree />
                                        <Typography style={{ fontSize: 8 }}>
                                            Scan QR
                                        </Typography>
                                    </Div>
                                </IconButton>
                            )}
                        </span>
                    </DivHor>
                    {BottomComponent}
                </Toolbar>
            </AppBar>
            {bannerText && (
                <DivHor
                    style={{
                        backgroundColor: "#FFDC52",
                        justifyContent: "space-between",
                        zIndex: 102,
                        padding: "10px 14px",
                        ...shadow
                    }}
                    onClick={bannerAction}
                >
                    <Typography
                        style={{
                            color: "black",
                            fontSize: 14,
                            fontWeight: 500
                        }}
                    >
                        {bannerText}
                    </Typography>
                    <ArrowForwardIos
                        style={{ color: "black", height: 14, width: 14 }}
                    />
                </DivHor>
            )}
        </>
    );
}
