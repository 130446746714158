import { Button, Grid, InputAdornment, TextField, Typography, useTheme } from "@material-ui/core";
import produce from "immer";
import { useEffect, useState, MouseEventHandler } from "react";
import { useQuery, useQueryClient } from "react-query";
import { DivHor } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";
import { Label } from "../../components/forms/Label";
import { patch, post, put, query } from "../../network";
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import { DialogTitle } from "../../components/common/DialogTitle";
import { basePath } from "../../network";
import { ErrorModal } from "../../components/overlay/ErrorModal";

const UpdateMobileModal = ({
    open, handleClose, updateField
}:{
    open: boolean, handleClose: Function, updateField: Function
}) => {
    type State = "ASKNUM" | "ASKOTP" | "DONE";
    const [state, setState] = useState<State>("ASKNUM");
    const [mobile, setMobile] = useState(null);
    const [mobileError, setMobileError] = useState('');
    const [otpError, setOtpError] = useState('');
    const [pin, setPin] = useState(null);
    const theme = useTheme();

    useEffect(() => {
        fetch(`${basePath}auth/checkPhone`, {
            method: "POST",
            body: JSON.stringify({ mobileNumber: `+65${mobile}` }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setMobileError(
                    res?.hasUser ? "Mobile number already exists" : ""
                );
            });
    }, [mobile]);
    
    const requestOTP = async () => {
        const res = await fetch(`${basePath}auth/requestOTP`, {
            method: "POST",
            body: JSON.stringify({ mobileNumber: `+65${mobile}` }),
            headers: { "Content-Type": "application/json" },
        });
        if (res.ok) {
            setState("ASKOTP");
        } else {
            res.json().then((t) => setOtpError(t?.message || t));
        }
    };

    const updateMobileHandler = async () => {
        const res = await put(`auth/changeMobileNumber`, { newMobileNumber: `+65${mobile}`, pin });
        if (res.ok) {
            updateField("mobileNumber", mobile);
            setState("ASKNUM");
            setMobile(null);
            setPin(null);
            handleClose();
        } else {
            res.json().then((t) => setOtpError(t?.message || t));
        }
    }

    const closeHandler = () => {
        handleClose();
    }

    return (
        <Dialog open={open} onClose={closeHandler} aria-labelledby="dialog-title">
            <DialogTitle id="dialog-title" onClose={closeHandler} iconColor={theme.palette.secondary.main} color={theme.palette.background.default}>
            </DialogTitle>
            <DialogContent>
                { state === "ASKOTP" && (
                    <DialogContentText>Enter your OTP<br/><span style={{
                        color: theme.palette.neutral.dark,
                        fontSize: '.8rem',
                    }}>The 6 digit number was sent to <span style={{whiteSpace: 'nowrap'}}>+65 {mobile}</span></span></DialogContentText>
                )}
                { state === "ASKNUM" && (
                    <DialogContentText>Please enter your updated contact number.</DialogContentText>
                )}
            { state === "ASKNUM" && (
                <>
                <TextField
                    placeholder="Mobile number"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: 24 }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                    }}
                />
                {mobileError && <Typography style={{ color: '#F66C62', marginTop: 8, }}>{mobileError}</Typography>}
                <Button
                    style={{ margin: "48px 0px 14px 0px" }}
                    onClick={requestOTP}
                    variant="contained"
                    fullWidth
                >Confirm</Button>
                </>
            )}
            { state === "ASKOTP" && (
                <>
                    <TextField
                        placeholder="OTP"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        variant="outlined"
                        style={{ marginTop: 24 }}
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                        fullWidth
                    />
                    {otpError && <Typography style={{ color: '#F66C62', marginTop: 8, }}>{otpError}</Typography>}
                    <Button
                        style={{ margin: "48px 0px 14px 0px" }}
                        onClick={requestOTP}
                        variant="contained"
                        fullWidth
                    >Resend OTP</Button>
                    <Button
                        style={{ margin: "0px 0px 14px 0px" }}
                        onClick={updateMobileHandler}
                        variant="contained"
                        fullWidth
                        disabled={pin.length < 6}
                    >Confirm</Button>
                </>
            )}
            </DialogContent>
        </Dialog>
    )
}

export default function AccountScreen() {
    const { data: existing, refetch } = useQuery("me", () => query("me"), {
        initialData: {
            name: "",
            email: "",
            mobileNumber: "",
            username: "",
        },
    });
    const queryClient = useQueryClient()

    const theme = useTheme();

    const [isEdit, setIsEdit] = useState(false);
    const [data, setData] = useState({ ...existing, mobileNumber: existing?.mobileNumber?.replace('+65', '')});
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const [canResend, setCanResend] = useState(true)

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        setData(existing);
    }, [existing]);

    const updateField = (key, value) => {
        setData((data) =>
            produce(data, (d) => {
                d[key] = value;
            })
        );
    };

    const toggleEdit = async () => {
        if (isEdit) {
            //save
            await saveDetails()
            setIsEdit(false);
        } else {
            setIsEdit(true);
        }
    };

    const saveDetails = async () => {
        const res = await patch(`auth/update`, {
            email: data.email,
            username: data.username
        });
        if (!res.ok) {
            const json = await res.json()
            setError(json.message)
        }
        queryClient.invalidateQueries('me')
        await refetch()
        setData(existing)
    };

    const resendVerification = async () => {
        setCanResend(false)
        const res = await post('auth/resendVerificationEmail', {})
        if (!res.ok) {
            const json = await res.json()
            setError(json.message)
        }
    }

    return (
        <>
            <TopBar
                title="Account"
                back
                RightComponent={
                    <Typography style={{ color: theme.palette.primary.main }} onClick={toggleEdit}>
                        {isEdit ? "Save" : "Edit"}
                    </Typography>
                }
            />
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    padding: "14px 24px",
                    backgroundColor: theme.palette.background.default,
                }}
            >
                {isEdit ? (
                    <>
                        <Label title="Username" />
                        <TextField
                            variant="outlined"
                            value={data.username}
                            onChange={(e) =>
                                updateField("username", e.target.value)
                            }
                        />
                        <Label title="Email" />
                        <DivHor style={{ padding: 0 }}>
                            <TextField
                                variant="outlined"
                                value={data.email}
                                onChange={(e) =>
                                    updateField("email", e.target.value)
                                }
                                style={{ flex: 1, paddingRight: 16 }}
                            />
                        </DivHor>
                        <Label title="Contact Number" />
                        <DivHor style={{ padding: 0 }}>
                            <TextField
                                variant="outlined"
                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                value={data.mobileNumber}
                                disabled={true}
                                onChange={(e) =>
                                    updateField("mobileNumber", e.target.value)
                                }
                                style={{ flex: 1, paddingRight: 16 }}
                                // InputProps={{
                                //     startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                                // }}
                            />
                            <Button
                                variant="contained"
                                style={{ padding: 8, textTransform: 'none', }}
                                onClick={handleOpen}
                            >
                                Update
                            </Button>
                            <UpdateMobileModal open={open} handleClose={handleClose} updateField={updateField}/>
                        </DivHor>
                    </>
                ) : (
                    <>
                        <Label title="Username" subtitle={data.username || 'Not set'} />
                        <Label title="Email" subtitle={`${data.email}${data.emailVerified ? ' (Verified)' : ' (Not Verified)'}`} />
                        {
                                !data.emailVerified && 
                            <Button
                                variant="contained"
                                style={{ padding: 8, textTransform: 'none', alignSelf: 'flex-start', marginTop: 8 }}
                                onClick={resendVerification}
                                disabled={!canResend}
                            >
                                Resend Verification Email
                            </Button>
                        }
                        <Label
                            title="Contact Number"
                            subtitle={data.mobileNumber || 'Not set'}
                        />
                    </>
                )}
                <ErrorModal open={!!error} handleClose={() => setError('')} message={error} />
            </Grid>
        </>
    );
}
