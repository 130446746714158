import React from "react";
import { useTheme } from "@material-ui/core";

export default function Logo({ onClick = () => {} }: { onClick?: Function }) {
    const theme = useTheme();
    return (
        // <img
        //     src="/images/logo.png"
        //     alt="logo"
        //     style={{ height: 32, fill: theme.palette.secondary.main}}
        //     onClick={() => onClick()}
        // />
        <svg width="152" height="24" viewBox="0 0 162 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M62.9895 27.9432C59.6168 31.2557 56.53 31.8839 54.2434 31.8839C49.556 31.8839 46.8122 29.4852 46.0119 28.6857C44.297 27.0294 42.6964 24.2309 42.6964 20.2902C42.6964 16.6351 44.1826 13.551 46.2977 11.495C48.2984 9.5532 50.8708 8.52518 54.0719 8.52518C58.1306 8.52518 61.0459 10.5241 62.9895 12.5801V2.41422C59.2738 0.815093 56.3585 0.301086 53.8433 0.301086C47.8983 0.301086 42.9822 2.52845 39.6096 5.78382C36.1797 9.09631 33.6646 14.1221 33.6646 20.1189C33.6646 26.2298 36.1797 31.2557 39.6096 34.5682C42.8107 37.7093 47.5553 40.108 54.5864 40.108C56.873 40.108 59.6168 39.7653 62.9895 38.052V27.9432Z" fill={theme.palette.secondary.main}/>
            <path d="M86.1237 25.5445H95.3271C94.584 28.343 92.869 29.8279 92.5832 30.0563C91.7258 30.7988 89.725 32.1124 85.9523 32.1124C83.0369 32.1124 80.4645 31.427 78.2352 29.1425C76.6346 27.5434 75.034 24.7449 75.034 20.4044C75.034 16.8635 76.1773 13.6081 78.2923 11.3808C80.1216 9.43898 82.6939 8.29674 86.1809 8.29674C88.3531 8.29674 90.2967 8.86786 92.0116 10.1243C93.4978 11.2094 94.6411 12.7515 95.3842 14.2364L103.33 9.95298C101.958 7.55429 99.7287 4.87003 97.1563 3.15668C93.6122 0.815093 89.6107 0.301086 86.4667 0.301086C81.9508 0.301086 76.1773 0.986429 71.4899 5.84093C68.2887 9.09631 66.0022 14.065 66.0022 20.2902C66.0022 27.8861 69.3748 32.5121 71.6042 34.7966C76.063 39.3656 80.979 40.108 85.5521 40.108C90.068 40.108 94.9269 39.3084 99.157 34.9108C103.387 30.5132 105.045 23.4885 105.045 18.8624V18.4626H86.1237V25.5445Z" fill={theme.palette.secondary.main}/>
            <path d="M104.709 1.15776L117.685 39.2513H125.116L133.119 16.1211L141.122 39.2513H148.553L161.529 1.15776H152.383L144.266 26.4012L136.149 1.15776H130.089L121.972 26.4012L113.855 1.15776H104.709Z" fill={theme.palette.secondary.main}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.1811 21.7488C29.8026 21.9735 32.4365 20.992 34.0217 19.9929L36.6796 24.2812C34.444 25.6901 30.7377 27.1287 26.7564 26.7875C22.5758 26.4292 18.3917 24.123 15.5698 18.5993L20.0279 16.2834C22.0775 20.2955 24.7589 21.5412 27.1811 21.7488Z" fill={theme.palette.secondary.main}/>
            <mask id="mask0_825:8696" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="3" y="3" width="20" height="21">
            <path d="M5.24609 17.0975C2.34876 13.4495 2.95728 8.14343 6.60527 5.24609C10.2533 2.34876 15.5593 2.95728 18.4566 6.60527L20.9 9.6817C23.7974 13.3297 23.1888 18.6357 19.5409 21.5331C15.8929 24.4304 10.5868 23.8219 7.68948 20.1739L5.24609 17.0975Z" fill={theme.palette.secondary.main}/>
            </mask>
            <g mask="url(#mask0_825:8696)">
            <path d="M3.59717 17.9935L19.5222 5.34541L27.4129 15.2805L11.4879 27.9286L3.59717 17.9935Z" fill={theme.palette.secondary.main}/>
            </g>
            <path d="M3.51758 11.9471L7.31787 8.92884L11.716 14.4664L7.91567 17.4847L3.51758 11.9471Z" fill={theme.palette.secondary.main}/>
            <path d="M10.2129 6.63031L14.0132 3.61201L18.4113 9.14958L14.611 12.1679L10.2129 6.63031Z" fill={theme.palette.secondary.main}/>
        </svg>

    );
}
