import { Typography, useTheme } from "@material-ui/core";
import { Div } from "../base/Divs";

export const Tab = ({
    title,
    active,
    onClick,
}: {
    title: string;
    active: boolean;
    onClick: Function;
}) => {
    const theme = useTheme();
    const bc = active ? theme.palette.secondary.main : theme.palette.background.default;
    const tc = active ? theme.palette.background.default : theme.palette.secondary.main;
    return (
        <Div
            style={{ flex: 1, backgroundColor: bc, height: 32 }}
            onClick={() => onClick()}
        >
            <Typography style={{ color: tc, fontSize: 12, padding: 8 }}>
                {title}
            </Typography>
        </Div>
    );
};
