import { Typography, useTheme } from "@material-ui/core";
import { Div } from "../base/Divs";
import Logo from "../common/Logo";

export const WelcomeOverlay = () => {
    const theme = useTheme();
    return (
        <Div
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: theme.palette.background.default,
                zIndex: 999,
            }}
        >
            <Typography style={{ color: theme.palette.secondary.main, fontWeight: "bold" }}>
                Welcome to
            </Typography>
            <Logo />
        </Div>
    );
};
