import React from "react";
import './ChargingAnimation.css';

export default function ChargingAnimation () {
    return (
        <div style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            }}
        >
            <div className="circleAnimationContainer">
                <div className="circleAnimation" style={{animationDelay: "0s"}}></div>
                <div className="circleAnimation" style={{animationDelay: "-1s"}}></div>
                <div className="circleAnimation" style={{animationDelay: "-2s"}}></div>
            </div>
            <svg width="119" height="100" viewBox="0 0 119 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_i)">
            <path fillRule="evenodd" clipRule="evenodd" d="M21.4023 51.1255C23.7033 51.162 25.9417 50.1453 27.2641 49.1771L29.8441 52.757C27.9792 54.1224 24.833 55.6032 21.3384 55.5478C17.6689 55.4896 13.8778 53.732 11.0794 49.0841L14.8282 46.7922C16.8608 50.1681 19.2762 51.0918 21.4023 51.1255Z" fill="white"/>
            <mask id="mask0" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="36" width="18" height="18">
            <path d="M1.97938 48.403C-0.770655 45.3964 -0.562626 40.7296 2.44403 37.9796C5.45068 35.2296 10.1174 35.4376 12.8674 38.4442L15.1866 40.9798C17.9366 43.9865 17.7286 48.6532 14.722 51.4032C11.7153 54.1533 7.04859 53.9452 4.29855 50.9386L1.97938 48.403Z" fill="white"/>
            </mask>
            <g mask="url(#mask0)">
            <path d="M0.595703 49.2852L13.721 37.2801L21.2106 45.4685L8.08524 57.4736L0.595703 49.2852Z" fill="white"/>
            </g>
            <path d="M0.158203 44.0146L3.29038 41.1498L7.46488 45.7138L4.3327 48.5787L0.158203 44.0146Z" fill="white"/>
            <path d="M5.67773 38.9688L8.80991 36.1039L12.9844 40.6679L9.85223 43.5328L5.67773 38.9688Z" fill="white"/>
            <path d="M89.8243 60.4943C89.8243 60.4943 91.0273 64.2593 96.1253 64.3453C101.224 64.4303 102.51 60.2313 102.681 59.4603L104.052 59.5033V60.5313C104.052 60.5313 111.936 60.0173 113.307 59.6743C113.307 59.6743 114.012 59.2743 113.947 59.0783C113.947 59.0783 113.555 58.4893 112.874 58.2533C112.874 58.2533 113.838 56.0613 113.951 55.3753C114.064 54.6893 114.007 52.6333 113.493 51.8333C112.979 51.0333 113.065 51.3183 113.065 51.3183C113.065 51.3183 113.093 49.3193 112.18 48.5483C111.265 47.7773 107.352 45.9483 104.181 44.8633C101.01 43.7783 93.6123 42.4063 93.0693 42.3213C92.5263 42.2363 92.1843 42.1493 92.1843 42.1493C92.1843 42.1493 81.8153 36.8793 76.7163 35.3373C71.6163 33.7953 53.9643 34.2233 49.1643 35.3373C44.3663 36.4513 34.1683 40.5223 32.3683 41.3363L27.2693 42.1503H24.6563L24.9133 42.9223C24.9133 42.9223 24.5273 44.2503 24.3123 45.7073C24.0983 47.1643 24.0983 48.7923 23.9703 49.0493C23.8413 49.3063 22.8983 51.3633 22.8983 51.3633C22.8983 51.3633 22.5993 52.2623 22.6423 53.0773C22.6843 53.8913 22.8133 57.1043 22.8133 57.1043L23.8413 58.4753L32.8393 59.9753L33.0103 59.2043H34.1683C34.1683 59.2043 34.9823 63.9603 40.5103 64.1323C46.0383 64.3043 47.7513 60.5753 47.7943 60.1913L88.2423 60.4053" fill="white" fillOpacity="1"/>
            <mask id="mask1" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="23" y="35" width="92" height="31">
            <path d="M90.1866 61.1594C90.1866 61.1594 91.3896 64.9244 96.4876 65.0104C101.587 65.0954 102.873 60.8964 103.044 60.1254L104.415 60.1684V61.1964C104.415 61.1964 112.299 60.6824 113.67 60.3394C113.67 60.3394 114.375 59.9394 114.31 59.7434C114.31 59.7434 113.918 59.1544 113.237 58.9184C113.237 58.9184 114.201 56.7264 114.314 56.0404C114.427 55.3544 114.37 53.2984 113.856 52.4984C113.342 51.6984 113.428 51.9834 113.428 51.9834C113.428 51.9834 113.456 49.9844 112.543 49.2134C111.628 48.4424 107.715 46.6134 104.544 45.5284C101.373 44.4434 93.9746 43.0714 93.4316 42.9864C92.8886 42.9014 92.5466 42.8144 92.5466 42.8144C92.5466 42.8144 82.1776 37.5444 77.0786 36.0024C71.9786 34.4604 54.3266 34.8884 49.5266 36.0024C44.7286 37.1164 34.5306 41.1874 32.7306 42.0014L27.6316 42.8154H25.0186L25.2756 43.5874C25.2756 43.5874 24.8896 44.9154 24.6746 46.3724C24.4606 47.8294 24.4606 49.4574 24.3326 49.7144C24.2036 49.9714 23.2606 52.0284 23.2606 52.0284C23.2606 52.0284 22.9616 52.9274 23.0046 53.7424C23.0466 54.5564 23.1756 57.7694 23.1756 57.7694L24.2036 59.1404L33.2016 60.6404L33.3726 59.8694H34.5306C34.5306 59.8694 35.3446 64.6254 40.8726 64.7974C46.4006 64.9694 48.1136 61.2404 48.1566 60.8564L88.6046 61.0704" fill="white"/>
            </mask>
            <g mask="url(#mask1)">
            <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#13ece5" />
            <stop offset="50%" stop-color="#1DB5B1" />
            <stop offset="100%" stop-color="#076e6b" />
            </linearGradient>
            <path 
                fill="url(#grad1)" 
                d="
                M20 53.6654C20 53.6654 20.9722 32.7324 70 45.5C118 58 120.663 53.6654 120.663 53.6654V65.5H20L20 53.6654Z">
                <animate 
                repeatCount="indefinite" 
                fill="url(#grad1)" 
                attributeName="d" 
                dur="5s"
                attributeType="XML"
                values="
                    M20 53.6654C20 53.6654 20.9722 32.7324 70 45.5C118 58 120.663 53.6654 120.663 53.6654V65.5H20L20 53.6654Z;

                    M19.9999 53.6654C19.9999 53.6654 19.3369 45.5 69.9999 45.5C132.5 45.5 120.663 53.6654 120.663 53.6654V65.5H19.9999L19.9999 53.6654Z;

                    M20 53.6654C20 53.6654 20.2884 55.2724 70 45.5C128.5 34 120.663 53.6654 120.663 53.6654V65.5H20L20 53.6654Z;

                    M19.9999 53.6654C19.9999 53.6654 19.3369 45.5 69.9999 45.5C132.5 45.5 120.663 53.6654 120.663 53.6654V65.5H19.9999L19.9999 53.6654Z;

                    M20 53.6654C20 53.6654 20.9722 32.7324 70 45.5C118 58 120.663 53.6654 120.663 53.6654V65.5H20L20 53.6654Z
                    ">
                </animate>
            </path>
            </g>
            </g>
            <defs>
            <filter id="filter0_i" x="0.158203" y="0" width="117.842" height="100" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="5"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
            </filter>
            </defs>
            </svg>
        </div>
    )
};