import { useState , useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import { HandledEvents } from 'react-swipeable/dist/types';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { useTheme } from '@material-ui/core';

function findLeft(element) {
    let rec = element.getBoundingClientRect();
    return rec.left + window.scrollX;
}

export default function SwipeableButton ({
    mainText,
    overlayText,
    height=60,
    width=240,
    overlayColor="#444",
    sliderColor="#444",
    onSuccess = () => {},
    delta = 10,
    minSwipeWidth = 0.6,
    minSwipeVelocity = 0.6
} : {
    mainText?: String,
    overlayText?: String,
    height?: number,
    width?: number,
    overlayColor?: string,
    sliderColor?: string,
    onSuccess?: Function,
    delta?: number,
    minSwipeWidth?: number,
    minSwipeVelocity?: number
}) {
    const [overlayWidth, setOverlayWidth] = useState(height);
    const [swipeComplete, setSwipeComplete] = useState(false);
    const buttonRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();

    const swipeRightEventHandler = (event: HandledEvents, buttonWidth: number, offsetLeft: number) => {
        if (event instanceof MouseEvent) {
            return event.offsetX > minSwipeWidth * buttonWidth
        } else if (event instanceof TouchEvent) {
            return event.changedTouches[0].clientX - offsetLeft > minSwipeWidth * buttonWidth;
        }
    }

    const handlers = useSwipeable({
        onSwipedRight: (data) => {
            if (swipeComplete) {
                return;
            }
            const buttonWidth = buttonRef.current.offsetWidth;
            if (data.velocity > minSwipeVelocity) {
                setOverlayWidth(buttonWidth);
                setSwipeComplete(true);
                setTimeout(() => {
                    onSuccess();
                }, 100);
            } else {
                const offsetLeft = findLeft(buttonRef.current);
                const startPos = Math.abs(data.initial[0] - offsetLeft);
                if (startPos <= 100 && swipeRightEventHandler(data.event, buttonWidth, offsetLeft)) {
                    setOverlayWidth(buttonWidth);
                    setSwipeComplete(true);
                    setTimeout(() => {
                        onSuccess()
                    }, 100);
                } else {
                    setOverlayWidth(height);
                }
            }
        },
        onSwiping: (data) => {
            if (swipeComplete){
                return;
            }
            const offsetLeft = findLeft(buttonRef.current);
            const startPos = Math.abs(data.initial[0] - offsetLeft);
            if (startPos <= 100) {
                if (data.event instanceof MouseEvent) {
                    setOverlayWidth(Math.max(Math.min(data.event.offsetX,width),height));
                } else if (data.event.type === 'touchmove' && data.event instanceof TouchEvent) {
                    setOverlayWidth(Math.max(Math.min(data.event.touches[0].clientX - offsetLeft,width),height));
                }
            }
        },
        delta,
        trackMouse: true,
        preventDefaultTouchmoveEvent: true
    });

    return (
        <div className="swipeButton"
            {...handlers}
            style ={{
                width: width + "px",
                height: height + "px",
                border: "2px solid #ccc",
                borderRadius: height + "px",
                boxShadow: "inset 0 0 3px rgba(0,0,0,.3)",
                minWidth: "200px",
                padding: "11px",
                position: "relative",
                overflow: "hidden",
                color: theme.palette.neutral.main,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "default",
                userSelect: "none",
                boxSizing: "border-box"
            }}
            ref={(t) => {
                handlers.ref(t);
                buttonRef.current = t;
            }}
        >
            <div className="swipeButtonOverlay"
                style={{
                    position: "absolute",
                    left: 0,
                    zIndex: 1,
                    height: height + "px",
                    borderRadius: height + "px",
                    background: overlayColor,
                    transition: "width 0.2s ease-out",
                    willChange: "width",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: overlayWidth + "px",
                    userSelect: "none",
                    boxSizing: "border-box",
                    cursor: "pointer",
                }}
            >
                <div className="swipeButtonOverlayWrapper"
                    style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                        userSelect: "none",
                        boxSizing: "border-box",

                    }}
                >
                    <div className="swipeButtonArrowWrapper"
                        style={{
                            position: "absolute",
                            maxWidth: height + "px",
                            width: height + "px",
                            minWidth: 0,
                            right: 0,
                            top: "50%",
                            height: "100%",
                            transform: "translateY(-50%)",
                            borderRadius: height + "px",
                            background: sliderColor,
                            boxShadow: "0 0 4px rgba(0,0,0,0.2)",
                            zIndex: 2,
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                            justifyContent: "center",
                            userSelect: "none",
                            boxSizing: "border-box",
                        }}
                    >
                        <DoubleArrowIcon 
                            style={{
                                color: "#fff",
                                fill: "white"
                            }}
                        />
                    </div>
                </div>
                <div className="swipeButtonOverlayText"
                    style={{
                        position: "absolute",
                        width: "100%",
                        left: 0,
                        top: 0,
                        height: "100%",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        userSelect: "none",
                        boxSizing: "border-box",

                    }}
                >
                    {overlayText}
                </div>
            </div>
            <div className="swipeButtonText"
                style={{
                    userSelect: "none",
                    boxSizing: "border-box",
                }}    
            >
                {mainText}
            </div>
        </div>
    );
}