import { Dialog, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Theme, withStyles, useTheme } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { DialogTitle } from "../common/DialogTitle";
import ShareIcon from "@material-ui/icons/Share";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import StarsIcon from "@material-ui/icons/Stars";
import NotificationsIcon from "@material-ui/icons/Notifications";

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: 16,
        paddingLeft: 32,
        paddingRight: 32,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}))(MuiDialogContent);

export default function BetaPopup({
    open = false,
    handleClose,
}: {
    open?: boolean;
    handleClose: Function;
}) {
    const history = useHistory();
    const theme = useTheme();

    const closeHandler = () => {
        handleClose();
    };

    return (
        <Dialog
            onClose={closeHandler}
            aria-labelledby="customized-dialog-title"
            open={open}
            PaperProps={{
                style: {
                    borderRadius: 16,
                    minWidth: 300,
                    width: "50%",
                },
            }}
        >
            <DialogTitle id="customized-dialog-title" onClose={closeHandler}>
                Welcome to the ChargeGoWhere Open Beta!
            </DialogTitle>
            <DialogContent dividers>
                <Typography
                    gutterBottom
                    style={{
                        textAlign: "center",
                        color: theme.palette.primary.main,
                    }}
                >
                    ChargeGoWhere is Singapore’s first ever universal EV Charger
                    locator that allows you to view the live status of all
                    Public EV chargers in Singapore. You will be able to view
                    and compare prices of the different chargers as well as to
                    get directions to them.
                </Typography>
                <Typography
                    gutterBottom
                    style={{
                        textAlign: "center",
                        color: theme.palette.primary.main,
                        marginTop: 14,
                    }}
                >
                    However, this site is still in beta and some of the
                    functions may not be working as intended.
                </Typography>
                <Button
                    style={{
                        backgroundColor: theme.palette.secondary.light,
                        color: "white",
                        width: "100%",
                        paddingTop: 12,
                        paddingBottom: 12,
                        borderRadius: 16,
                        marginTop: 16,
                        marginBottom: 16,
                    }}
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Proceed
                </Button>
            </DialogContent>
        </Dialog>
    );
}
