import { Typography, createStyles, IconButton, Theme, WithStyles, withStyles } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '8px 38px',
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      textAlign: 'center',
      minHeight: 16,
    },
    closeButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      color: 'white',
      "& .MuiSvgIcon-root": {
          fill: 'white',
      }
    },
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    onClose: () => void;
    children?: React.ReactNode;
    iconColor?: string;
    color?: string;
}
  
export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { classes, children, onClose, color, iconColor,...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} style={{backgroundColor: color}} {...other}>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon style={{fill: iconColor}}/>
          </IconButton>
        ) : null}
        {children ? (
        <Typography variant="h6">{children}</Typography>
        ) : null}
      </MuiDialogTitle>
    );
});