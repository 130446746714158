import TopBar from "../../components/common/TopBar";
import { Grid, Typography, Divider, useTheme } from "@material-ui/core";

export default function LegendScreen() {
    const theme = useTheme();
    return (
        <div style={{
            height: '100%',
            backgroundColor: theme.palette.background.default,
            overflow: 'auto'
        }}>
            <TopBar title="Legend" back />
            <Grid
                container
                style={{
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingBottom: 32,
                    overflowY: 'scroll',
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <Grid item>
                    <Typography
                        style={{
                            color: theme.palette.primary.main,
                            paddingTop: 8,
                            paddingBottom: 8,
                            fontWeight: 600,
                        }}
                    >What do these <span style={{fontStyle: "italic", fontWeight: 500,}}>shapes</span> mean?
                    </Typography>
                </Grid>
                <Grid container direction="row" style={{maxWidth: 400,paddingTop: 8, paddingBottom: 8,}}>
                    <Grid item xs={4} style={{
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                    <svg width="59" height="48" viewBox="0 0 59 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.0964 4.71129C25.6747 -0.465619 33.3253 -0.465614 36.9036 4.7113L56.5483 33.1327C60.6745 39.1023 56.4016 47.25 49.1447 47.25H9.85532C2.59845 47.25 -1.67446 39.1023 2.45172 33.1327L22.0964 4.71129Z" fill="white"/>
                        <path d="M35.6697 5.56419L55.3143 33.9856C58.7528 38.9603 55.1921 45.75 49.1447 45.75H9.85532C3.80792 45.75 0.247166 38.9603 3.68565 33.9856L23.3303 5.56418C26.3122 1.25009 32.6878 1.25009 35.6697 5.56419Z" stroke={theme.palette.neutral.dark} stroke-opacity="0.75" strokeWidth="3"/>
                    </svg>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            style={{
                                fontSize: 14,
                                color: theme.palette.primary.main,
                                paddingBottom: 8,
                            }}
                        >EV stations that allow you to use CGW to charge your EV and obtain station availabilities.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" style={{maxWidth: 400,paddingTop: 8, paddingBottom: 8,}}>
                    <Grid item xs={4} style={{
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        <svg width="95" height="39" viewBox="0 0 95 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="2" width="51" height="37" rx="8" fill="white"/>
                            <rect x="1.5" y="3.5" width="48" height="34" rx="6.5" stroke={theme.palette.neutral.dark} stroke-opacity="0.75" strokeWidth="3"/>
                            <path d="M75.5 0L78.2454 2.16607L81.5258 0.954399L83.4675 3.86284L86.9618 3.72417L87.9097 7.09028L91.2758 8.03819L91.1372 11.5325L94.0456 13.4742L92.8339 16.7546L95 19.5L92.8339 22.2454L94.0456 25.5258L91.1372 27.4675L91.2758 30.9618L87.9097 31.9097L86.9618 35.2758L83.4675 35.1372L81.5258 38.0456L78.2454 36.8339L75.5 39L72.7546 36.8339L69.4742 38.0456L67.5325 35.1372L64.0382 35.2758L63.0903 31.9097L59.7242 30.9618L59.8628 27.4675L56.9544 25.5258L58.1661 22.2454L56 19.5L58.1661 16.7546L56.9544 13.4742L59.8628 11.5325L59.7242 8.03819L63.0903 7.09028L64.0382 3.72417L67.5325 3.86284L69.4742 0.954399L72.7546 2.16607L75.5 0Z" fill="#FFDC52"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M82.8629 19.9069L76.7692 26.0006L75.6201 24.8516L81.7139 18.7578L82.8629 19.9069Z" fill={theme.palette.primary.main}/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M82.8629 19.9066L76.7692 13.8129L75.6201 14.9619L81.7139 21.0557L82.8629 19.9066Z" fill={theme.palette.primary.main}/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M77.5309 19.9069L71.4371 26.0006L70.2881 24.8516L76.3818 18.7578L77.5309 19.9069Z" fill={theme.palette.primary.main}/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M77.5309 19.9066L71.4371 13.8129L70.2881 14.9619L76.3818 21.0557L77.5309 19.9066Z" fill={theme.palette.primary.main}/>
                        </svg>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            style={{
                                fontSize: 14,
                                color: theme.palette.primary.main,
                                paddingBottom: 8,
                            }}
                        >You're required to launch their respective charging applications to charge your EV and obtain station availabilities.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item style={{width: '100%', paddingTop: 8,paddingBottom: 16,}}>
                    <Divider/>
                </Grid>
                <Grid item>
                    <Typography
                        style={{
                            color: theme.palette.primary.main,
                            paddingTop: 8,
                            paddingBottom: 8,
                            fontWeight: 600,
                        }}
                    >What do these <span style={{fontStyle: "italic", fontWeight: 500,}}>colours</span> mean?
                    </Typography>
                </Grid>
                <Grid container direction="row" style={{maxWidth: 400,paddingTop: 8, paddingBottom: 8, display: "flex", alignItems: "center",}}>
                    <Grid item xs={4} style={{
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        <svg width="91" height="60" viewBox="0 0 91 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="40.5" y="32.5" width="49" height="31" rx="7.5" fill="white" stroke={theme.palette.success.main} strokeWidth="3"/>
                            <path d="M33.9602 9.29476C36.1443 6.10132 40.8556 6.10132 43.0398 9.29476L63.1136 38.6451C65.6102 42.2954 62.9962 47.25 58.5738 47.25H18.4262C14.0038 47.25 11.3898 42.2954 13.8864 38.6451L33.9602 9.29476Z" fill="white" stroke={theme.palette.success.main} strokeWidth="3"/>
                        </svg>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            style={{
                                fontSize: 14,
                                color: theme.palette.primary.main,
                            }}
                        >EV stations that are available for charging.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" style={{maxWidth: 400,paddingTop: 8, paddingBottom: 8, display: "flex", alignItems: "center",}}>
                    <Grid item xs={4} style={{
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        <svg width="91" height="60" viewBox="0 0 91 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="40.5" y="32.5" width="49" height="31" rx="7.5" fill="white" stroke={theme.palette.warning.main} strokeWidth="3"/>
                            <path d="M33.9602 9.29476C36.1443 6.10132 40.8556 6.10132 43.0398 9.29476L63.1136 38.6451C65.6102 42.2954 62.9962 47.25 58.5738 47.25H18.4262C14.0038 47.25 11.3898 42.2954 13.8864 38.6451L33.9602 9.29476Z" fill="white" stroke={theme.palette.warning.main} strokeWidth="3"/>
                        </svg>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            style={{
                                fontSize: 14,
                                color: theme.palette.primary.main,
                            }}
                        >EV stations that are currently occupied.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item style={{width: '100%', paddingTop: 8,paddingBottom: 16,}}>
                    <Divider/>
                </Grid>
                <Grid item>
                    <Typography
                        style={{
                            color: theme.palette.primary.main,
                            paddingTop: 8,
                            paddingBottom: 8,
                            fontWeight: 600,
                            textAlign: 'center',
                        }}
                    >What do these <span style={{fontStyle: "italic", fontWeight: 500,}}>numbers</span> mean?<br/>
                    <span style={{fontSize: 12,fontStyle: "italic", fontWeight: 500,}}>Numbers represent the number of EV stations available.</span>
                    </Typography>
                </Grid>
                <Grid container direction="row" style={{maxWidth: 400,paddingTop: 8, paddingBottom: 8, display: "flex", alignItems: "center",}}>
                    <Grid item xs={4} style={{
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        <svg width="75" height="75" viewBox="0 0 75 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M35.9682 24.2366C38.1537 21.0587 42.8463 21.0587 45.0318 24.2366L66.7961 55.8834C69.3057 59.5326 66.6932 64.5 62.2643 64.5H18.7357C14.3068 64.5 11.6943 59.5326 14.2039 55.8834L35.9682 24.2366Z" fill="white" stroke={theme.palette.success.main} strokeWidth="3"/>
                            <g filter="url(#filter0_d_1053:481)">
                            <path d="M63 23C63 35.7025 52.7025 46 40 46C27.2975 46 17 35.7025 17 23C17 10.2975 27.2975 0 40 0C52.7025 0 63 10.2975 63 23Z" fill={theme.palette.success.main}/>
                            </g>
                            <path d="M38.4337 34H44V11H35V15.4828H38.4337V34Z" fill="white"/>
                            <defs>
                            <filter id="filter0_d_1053:481" x="13" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1053:481"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1053:481" result="shape"/>
                            </filter>
                            </defs>
                        </svg>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            style={{
                                fontSize: 14,
                                color: theme.palette.primary.main,
                            }}
                        >1 EV station available for charging.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" style={{maxWidth: 400,paddingTop: 8, paddingBottom: 8, display: "flex", alignItems: "center",}}>
                    <Grid item xs={4} style={{
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        <svg width="74" height="57" viewBox="0 0 74 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1.5" y="21.5" width="54" height="34" rx="8.5" fill="white" stroke={theme.palette.warning.main} strokeWidth="3"/>
                            <g filter="url(#filter0_d_1053:482)">
                            <path d="M70 20.5C70 31.8218 60.8218 41 49.5 41C38.1782 41 29 31.8218 29 20.5C29 9.17816 38.1782 0 49.5 0C60.8218 0 70 9.17816 70 20.5Z" fill="#CA3F3C"/>
                            </g>
                            <path d="M59 20.5151C59 17.0502 58.0383 13.977 56.0219 12.0789C54.812 10.934 53.0128 10 50.469 10C47.9872 10 46.219 10.9039 44.9781 12.0789C42.9617 13.977 42 17.0502 42 20.5151C42 23.9498 42.9617 26.9928 44.9781 28.9211C46.219 30.0961 47.9872 31 50.469 31C53.0128 31 54.812 30.066 56.0219 28.9211C58.0383 26.9928 59 23.9498 59 20.5151ZM50.5 13.977C51.3066 13.977 51.865 14.2181 52.4234 14.7303C53.9434 16.1765 54.1606 19.0689 54.1606 20.4849C54.1606 22.0517 53.8814 24.8838 52.4234 26.2697C51.865 26.7819 51.3066 27.023 50.5 27.023C49.6934 27.023 49.135 26.7819 48.5766 26.2697C47.1186 24.8838 46.8394 22.0517 46.8394 20.4849C46.8394 19.0689 47.0566 16.1765 48.5766 14.7303C49.135 14.2181 49.6934 13.977 50.5 13.977Z" fill="white"/>
                            <defs>
                            <filter id="filter0_d_1053:482" x="25" y="0" width="49" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1053:482"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1053:482" result="shape"/>
                            </filter>
                            </defs>
                        </svg>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            style={{
                                fontSize: 14,
                                color: theme.palette.primary.main,
                            }}
                        >0 EV stations available for charging.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}