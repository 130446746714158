import { Typography, useTheme, styled } from "@material-ui/core";
import { Location } from "../../../typings";

type P = {
    lat: number;
    lng: number;
    zoomLevel: number;
    location: Location;
    onClick: (event: any) => void
};

export const Marker = ({ lat, lng, zoomLevel, location, onClick }: P) => {
    const bColor = location.evses.some((e) => e.ocppStatus === "AVAILABLE")
        ? "#4FAF2D"
        : "#CA3F3C";
    const price = location.evses[0]?.chargingRateKWH.toFixed(2);
    const onNetwork = location.onNetwork;
    if (zoomLevel < 13) {
        if (onNetwork) {
            return <SmallTriangleMarker bColor={bColor} price={price} onClick={onClick} />;
        } else {
            return <SmallRectangleMarker bColor={bColor} price={price} onClick={onClick} />;
        }
    } else {
        const title = location.provider?.name || 'Public';
        const numAvailable = location.evses.filter(
            (e) => e.ocppStatus === "AVAILABLE"
        ).length;
        // const isDiscounted = location.evses[0]?.isDiscounted;
        const isDiscounted = false;
        if (onNetwork) {
            return <LargeTriangleMarker title={title} numAvailable={numAvailable} price={price} bColor={bColor} isDiscounted={isDiscounted} onClick={onClick} />
        } else {
            return <LargeRectangleMarker title={title} numAvailable={numAvailable} price={price} bColor={bColor} isDiscounted={isDiscounted} onClick={onClick} />
        }
    }
};

const SmallTriangleMarker = ( {
    bColor,
    price,
    onClick,
}: {
    bColor: string,
    price: string,
    onClick: (event: any) => void
}) => {
    return (
        <div style={{
            position: "absolute",
            transform: "translate(-50%, -100%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}
            onClick={onClick}
        >
            <div style = {{
                position: 'relative',
                transform: "translate(0, 60%)",
                zIndex: -10,
            }}
            >
                <svg width="72" height="58" viewBox="0 0 72 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.1 5.84215C31.8795 0.407506 39.9192 0.407494 43.6987 5.84213L68.5496 41.576C72.9302 47.8749 68.4227 56.5 60.7503 56.5H11.0485C3.376 56.5 -1.13151 47.8749 3.24907 41.576L28.1 5.84215Z" fill={bColor} stroke={bColor} strokeWidth="3"/>
                </svg>
            </div>
            <Typography style={{ color: "white", fontWeight: 900, fontSize: 15, transform: "translate(0px, 4px)" }}>
                ${price}
            </Typography>
            <div
                style={{
                    height: 16,
                    width: 3,
                    backgroundColor: bColor,
                }}
            />
            <div
                style={{
                    height: 8,
                    width: 8,
                    backgroundColor: bColor,
                    transform: "rotate(45deg)",
                    boxShadow: '1px 1px 4px 0px #00000040',
                }}
            />
        </div>
    );
};

const SmallRectangleMarker = ( {
    bColor,
    price,
    onClick,
}: {
    bColor: string,
    price: string,
    onClick: (event: any) => void
}) => {
    return (
        <div
            style={{
                position: "absolute",
                transform: "translate(-50%, -100%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            onClick={onClick}
        >
            <div
                style={{
                    minHeight: 16,
                    minWidth: 24,
                    backgroundColor: bColor,
                    borderRadius: 8,
                    padding: "4px 8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                className="has-shadow"
            >
                <Typography style={{ color: "white", fontWeight: 600 }}>
                    ${price}
                </Typography>
            </div>
            <div
                style={{
                    height: 16,
                    width: 3,
                    backgroundColor: bColor,
                }}
            />
            <div
                style={{
                    height: 8,
                    width: 8,
                    backgroundColor: bColor,
                    transform: "rotate(45deg)",
                }}
            />
        </div>
    );
};

const LargeTriangleMarker = ( {
    bColor,
    numAvailable,
    price,
    title,
    isDiscounted = false,
    onClick,
}: {
    bColor: string,
    numAvailable: number,
    price: string,
    title: string,
    isDiscounted?: boolean,
    onClick: (event: any) => void
}) => {
    const theme = useTheme();
    return (
        <div
            style={{
                position: "absolute",
                transform: "translate(-50%, 0px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            onClick={onClick}
        >
            <span
                style={{
                    backgroundColor: bColor,
                    color: "white",
                    height: 28,
                    width: 28,
                    borderRadius: 180,
                    position: "absolute",
                    top: -114,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    style={{
                        fontWeight: 900,
                        fontSize: 20,
                        textAlign: "center",
                    }}
                >{numAvailable}
                </Typography>
            </span>
            <div style={{
                position: "absolute",
                top: -134,
            }}>
                <div style = {{
                    position: 'relative',
                    transform: "translate(0, 81%) scale(1.5)",
                    zIndex: -10,
                }}
                >
                    <svg width="72" height="58" viewBox="0 0 72 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <linearGradient id="paint1_linear_875:10091" x1="3.5" y1="39.5" x2="50" y2="64" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFE86D"/>
                            <stop offset="0.35531" stop-color="white"/>
                            <stop offset="0.582245" stop-color="#FFF3E3" stop-opacity="1"/>
                            <stop offset="0.785825" stop-color="#FFFDC3" stop-opacity="1"/>
                            <stop offset="1" stop-color="white" stop-opacity="1"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_875:10092" x1="3.5" y1="39.5" x2="50" y2="64" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#121212"/>
                            <stop offset="0.35531" stop-color="#6d5f10"/>
                            <stop offset="0.582245" stop-color="#121212" stop-opacity="1"/>
                            <stop offset="0.785825" stop-color="#4f4510" stop-opacity="1"/>
                            <stop offset="1" stop-color="121212" stop-opacity="1"/>
                        </linearGradient>
                        <path d="M28.1 5.84215C31.8795 0.407506 39.9192 0.407494 43.6987 5.84213L68.5496 41.576C72.9302 47.8749 68.4227 56.5 60.7503 56.5H11.0485C3.376 56.5 -1.13151 47.8749 3.24907 41.576L28.1 5.84215Z" fill={isDiscounted ? ( theme.palette.type==='dark' ? "url(#paint1_linear_875:10092)" : "url(#paint1_linear_875:10091)") : theme.palette.background.default} stroke={bColor} strokeWidth="3"/>
                    </svg>
                </div>
                <Typography
                    style={{ color: theme.palette.primary.main, fontWeight: 900, fontSize: 20, textAlign: "center" }}
                >
                    ${price}
                </Typography>
                <Typography
                    style={{
                        color: theme.palette.primary.main,
                        fontWeight: 400,
                        textAlign: "center",
                        lineHeight: 1.1,
                        fontSize: 12,
                    }}
                >
                    {title}
                </Typography>
            </div>
            <div
                style={{
                    position: "fixed",
                    top: -16,
                    height: 16,
                    width: 3,
                    backgroundColor: bColor,
                }}
            />
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    height: 8,
                    width: 8,
                    backgroundColor: bColor,
                    transform: "rotate(45deg)",
                }}
            />
        </div>
    );
};

const LargeRectangleMarker = ( {
    bColor,
    numAvailable,
    price,
    title,
    isDiscounted = false,
    onClick,
}: {
    bColor: string,
    numAvailable: number,
    price: string,
    title: string,
    isDiscounted?: boolean,
    onClick: (event: any) => void
}) => {
    const theme = useTheme();
    return (
        <div
            style={{
                position: "absolute",
                transform: "translate(-50%, -100%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            onClick={onClick}
        >
            <div
                style={{
                    minHeight: 32,
                    minWidth: 48,
                    backgroundColor: isDiscounted ? 'none' : theme.palette.background.default,
                    backgroundImage: isDiscounted ? (theme.palette.type  === 'dark' ? `linear-gradient(118.63deg, #121212 10.18%, #6d5f10 30.97%, #121212 40.05%, #4f4510 80.09%, #121212 90.61%)` : `linear-gradient(108.63deg, #FFE86D 18.18%, #FFFFFF 33.97%, #fff3e3 44.05%, #fffdc3 53.09%, #fff 62.61%)`) : 'none',
                    borderWidth: 4,
                    borderColor: bColor,
                    borderStyle: "solid",
                    borderRadius: 14,
                    padding: "2px 8px 4px 8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                className="has-shadow"
            >
                <Typography
                    style={{ color: theme.palette.primary.main, fontWeight: 900, fontSize: 20 }}
                >
                    ${price}
                </Typography>
                <Typography
                    style={{
                        color: theme.palette.primary.main,
                        fontWeight: 400,
                        textAlign: "center",
                        lineHeight: 1.1,
                        fontSize: 12,
                    }}
                >
                    {title}
                </Typography>
                <span
                    style={{
                        backgroundColor: bColor,
                        color: "white",
                        height: 28,
                        width: 28,
                        borderRadius: 180,
                        position: "absolute",
                        top: -10,
                        right: -10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 900,
                            fontSize: 20,
                            textAlign: "center",
                        }}
                    >{numAvailable}
                    </Typography>
                </span>
            </div>
            <div
                style={{
                    height: 16,
                    width: 3,
                    backgroundColor: bColor,
                }}
            />
            <div
                style={{
                    height: 8,
                    width: 8,
                    backgroundColor: bColor,
                    transform: "rotate(45deg)",
                }}
            />
        </div>
    );
};