import { Button, Grid, Typography, useTheme } from "@material-ui/core";
import { useEffect } from "react";
import { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import QrReader from "react-qr-scanner";
import { useState } from "react";
import TopBar from "../../components/common/TopBar";
import { useQuery, useQueryClient } from "react-query";
import { del, post, query } from "../../network";
import { CreditCardOutlined, CloseOutlined } from "@material-ui/icons";

import {
    Elements,
    PaymentElement,
    PaymentRequestButtonElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Div, DivHor } from "../../components/base/Divs";

// const stripePromise = loadStripe("pk_test_P2odmVRARFjM58FPAfqELVW300aSxUlj3C");
const stripePromise = loadStripe("pk_live_INVey83MGbEnovWbTkelREc600KXCjIvlT");

export async function fetchClientSecret() {
    const raw = await query("me/paymentMethods/setup", { raw: true });
    if (!raw.ok) return null;
    const { clientSecret } = await raw.json();
    return clientSecret;
}

function Form() {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const queryClient = useQueryClient();

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        console.log("SUBMITTING");
        console.log("elements", elements);
        const res = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://demo.chargegowhere.sg/paymentComplete",
            },
            redirect: "if_required",
        });
        console.log("STRIPERES", res);
        const error = res?.error;

        if (error) {
            console.log("STRIPEERROR", error);
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (e.g., payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            const { setupIntent } = res;
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            switch (setupIntent.status) {
                case "succeeded":
                    setErrorMessage(
                        "Success! Your payment method has been saved."
                    );
                    const paymentMethodId = setupIntent.payment_method;
                    const card = {
                        lastFourDigits: "DEMO",
                        cardName: "DEMO",
                        cardType: "VISA",
                        token: paymentMethodId,
                    };
                    post("me/paymentMethods", card).then((res) => {
                        if (res.ok) {
                            console.log("SUCCESS", res.status);
                            queryClient.invalidateQueries("paymentMethods");
                            history.push("/paymentMethods");
                        } else {
                            res.text().then((t) => console.error(t));
                        }
                    });
                    break;

                case "processing":
                    setErrorMessage(
                        "Processing payment details. We'll update you when processing is complete."
                    );
                    break;

                case "requires_payment_method":
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again
                    setErrorMessage(
                        "Failed to process payment details. Please try another payment method."
                    );
                    break;
            }
        }
    };

    return (
        <div style={{}} onSubmit={handleSubmit}>
            <PaymentElement />
            <Button
                variant="contained"
                disabled={!stripe}
                onClick={handleSubmit}
                fullWidth
                style={{ marginTop: 24 }}
            >
                Submit
            </Button>
            {/* Show error message to your customers */}
            {errorMessage && (
                <div style={{ marginTop: 14 }}>{errorMessage}</div>
            )}
        </div>
    );
}

export default function PaymentMethodScreen() {
    const theme = useTheme();
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        fetchClientSecret().then((cs) => {
            console.log("setting client secret", cs);
            setClientSecret(cs);
        });
    }, []);

    return (
        <>
            <TopBar title="Payment Method" back />
            <Div
                style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    padding: "24px 24px",
                    backgroundColor: theme.palette.background.default,
                }}
            >
                {clientSecret !== "" && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <Form />
                    </Elements>
                )}
            </Div>
        </>
    );
}
