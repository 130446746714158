export const faq = [
  {
    question: "What is ChargeGoWhere and why should I use it?",
    answer: "ChargeGoWhere is a free Progressive Web Application for iOS, Android and Web that allows users to find charging stations and monitor their live statuses all around Singapore. Unlike other services which rely on a community for charger information, the data shown of the chargers in ChargeGoWhere is live and always up to date.",
  },
  {
    question: "What do the different colored charge station icons mean?",
    answer: "Charge station icons allow users to have a quick view on the status of the chargers without needing to click into them. To learn more about what these icons mean, please visit the “Legend” tab at the top right corner of the app.",
  },
  {
    question: "Do I need to sign up for an account?",
    answer: "No you do not. ChargeGoWhere aims to solve the hassle of needing multiple apps and accounts just to search for Electric Vehicle Chargers around Singapore. As such, we do not require the user to create an account to sign in to use our services.",
  },
  {
    question: "How do I get directions to the charger displayed on ChargeGoWhere?",
    answer: "We have a built-in navigation tool button to help you find directions to the chargers that you are interested in. This tool will allow you to use the map app of your choice (Apple maps, Google maps) to guide you to the charger location.",
  },
  {
    question: "How can I filter chargers that are compatible with Electric Vehicle?",
    answer: "Use our powerful filtering tool to filter chargers based on their charger types, charger availability as well as accessibility types. Click on the top right corner of the app to access this feature.\n By clicking on the list button at the bottom right of the homescreen, you will be presented with a list view of the chargers. Here, you will find additional filtering options at the top to filter chargers based on prices as well as the nearest chargers to your location.",
  },
  {
    question: "Can I make payments through the ChargeGoWhere app?",
    answer: "No you cannot. ChargeGoWhere's main purpose is to enable users to locate chargers from all around Singapore regardless of which Charge Point Operator's network they are on. However, payments at the charger still need to be done via the Charge Point Operator's app. Currently, ChargeGoWhere has a button at the selected charger of interest to redirect you to that specific charger inside Charge Point Operator's app, you will then be able to make the payment through there.",
  },
];
