import { Button, Dialog, DialogContent, Grid, Typography, useTheme } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import QrReader from "react-qr-reader";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { DialogTitle } from "../../components/common/DialogTitle";
import TopBar from "../../components/common/TopBar";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { query } from "../../network";
import config from '../../config';

const { SCAN_URL_PREFIX } = config;

const InvalidQRDialog = (
    { open, handleClose }: { open: boolean, handleClose: Function }
) => {
    const history = useHistory();
    const theme = useTheme();
    const closeHandler = () => {
        handleClose();
    }
    return (
        <Dialog onClose={closeHandler} open={open}>
            <DialogTitle id="dialog-title" onClose={closeHandler} iconColor={theme.palette.secondary.main} color={theme.palette.background.default} />
            <DialogContent style={{ paddingBottom: 32, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                <Typography style={{ color: theme.palette.warning.main, textAlign: 'center', fontWeight: 600, marginBottom: 32, }}>
                    Invalid QR Code
                </Typography>
                <Typography style={{ color: theme.palette.primary.main, }}>
                    You might be accessing stations that require launching their own app for charging.
                    <br />
                    <span style={{ color: theme.palette.neutral.main, fontStyle: 'italic' }}>Eg. SP, Greenlots, Charge+</span>
                    <br />
                    OR the QR code is unrecognisable.
                </Typography>
                <Button variant="contained" style={{ marginTop: 14, alignSelf: 'center' }} onClick={() => history.push('/support')}>Contact for Help</Button>
            </DialogContent>
        </Dialog>
    )
}

export default function ScanScreen() {
    const height = useWindowDimensions().height;
    const width = useWindowDimensions().width;
    const overlayRef = useRef<HTMLDivElement>(null);
    const [cameraHeight, setCameraHeight] = useState(height);
    const location = useLocation();
    const history = useHistory();
    const theme = useTheme();

    const { data: locations } = useQuery("loc", () => query("locations"), {
        initialData: [],
    });

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }
    const handleOpen = () => {
        setOpen(true);
    }

    useEffect(() => {
        setCameraHeight(height - 56 - (overlayRef.current?.clientHeight ?? 0));
    }, [location, height]);

    const onScan = async (data: string) => {
        if (!data) return;
        if (data.includes(SCAN_URL_PREFIX)) {
            const [postalCode, evseId] = data.replace(SCAN_URL_PREFIX, "").split("/");
            const check = await query(`locations/${postalCode}/${evseId}`);
            if (check) {
                history.push(`/charger/${postalCode}/${evseId}`);
            } else {
                setOpen(true)
            }
        } else {
            handleOpen();
        }
    };

    const goDev = () => {
        history.push(`charger/${locations[0].postalCode}/SUTD_TEST`);
    };

    return (
        <>
            <TopBar title="Scan QR" back />
            <InvalidQRDialog open={open} handleClose={handleClose} />
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    width,
                    backgroundColor: theme.palette.background.default,
                }}
            >
                {window.location.protocol === "https:" ||
                    window.location.href.includes("localhost") ? (
                    <QrReader
                        style={{
                            height: cameraHeight,
                            width,
                            backgroundColor: theme.palette.background.default,
                        }}
                        onScan={onScan}
                        facingMode="environment"
                        showViewFinder={false}
                    />
                ) : (
                    <Typography style={{ color: theme.palette.primary.main }}>
                        HTTPS required for scanning to function
                    </Typography>
                )}
                <div
                    style={{
                        background: theme.palette.background.default,
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                    ref={overlayRef}
                >
                    <Button
                        style={{ margin: "14px 0px" }}
                        onClick={() => {
                            goDev();
                        }}
                    >
                        DEV: Go to location
                    </Button>
                </div>
            </Grid>
        </>
    );
}
