import { Typography, useTheme } from "@material-ui/core";

type P = {
    title: string;
    subtitle?: string;
};
export const Label = ({ title, subtitle }: P) => {
    const theme = useTheme();
    return (
        <div style={{ marginTop: 14, marginBottom: 4 }}>
            <Typography
                style={{
                    color: theme.palette.primary.main,
                    fontSize: 14,
                    fontWeight: "bold",
                }}
            >
                {title}
            </Typography>
            {subtitle && (
                <Typography style={{ color: theme.palette.primary.main, fontSize: 14 }}>
                    {subtitle}
                </Typography>
            )}
        </div>
    );
};
