import {
    Button,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import { useContext, useState } from "react";
import { GoogleLogin } from "react-google-login";
import { useHistory, useLocation } from "react-router-dom";
import { Div } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";
import { Header } from "../../components/forms/Header";
import { AuthContext } from "../../context/AuthContext";
import { basePath } from "../../network";
import { ErrorModal } from "../../components/overlay/ErrorModal";
import config from '../../config';

const { GOOGLE_CLIENT_ID } = config;

function useSearchParams() {
    return new URLSearchParams(useLocation().search);
}

type State = "ASKNUM" | "ASKOTP" | "DONE";
export default function LoginScreen() {
    const history = useHistory();
    const theme = useTheme();
    const [mobileNumber, setMobileNumber] = useState("");
    const [state, setState] = useState<State>("ASKNUM");
    const [message, setMessage] = useState("");
    const next = useSearchParams().get("next");

    const [pin, setPin] = useState("");
    const [error, setError] = useState("")

    const { setLoggedIn } = useContext(AuthContext);

    const requestOTP = async () => {
        setMessage('')
        const resCheck = await fetch(`${basePath}auth/checkPhone`, {
            method: "POST",
            body: JSON.stringify({ mobileNumber: `+65${mobileNumber.trim()}` }),
            headers: { "Content-Type": "application/json" },
        });
        const resCheckResults = await resCheck.json();
        if (resCheckResults.hasUser) {
            const res = await fetch(`${basePath}auth/requestOTP`, {
                method: "POST",
                body: JSON.stringify({ mobileNumber: `+65${mobileNumber.trim()}` }),
                headers: { "Content-Type": "application/json" },
            });
            if (res.ok) {
                setState("ASKOTP");
            } else {
                res.json().then((t: any) => setMessage(t?.message || t));
            }
        } else {
            setMessage('No user found with that mobile number')
        }
    };

    const submit = async () => {
        const res = await fetch(`${basePath}auth/login`, {
            method: "POST",
            body: JSON.stringify({
                mobileNumber: `+65${mobileNumber.trim()}`,
                pin: pin.trim(),
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (res.ok) {
            res.json().then((j) => {
                localStorage.setItem("token", j.token);
                localStorage.setItem("refresh", j.refreshToken);
            });
            setLoggedIn(true);
            setState("DONE");
            setTimeout(() => {
                history.push(next ?? "/");
            }, 2000);
        } else {
            res.json().then((t: any) => setMessage(t?.message || t));
        }
    };

    const submitGoogle = async (googleID, googleAT) => {
        const res = await fetch(`${basePath}auth/login`, {
            method: "POST",
            body: JSON.stringify({ googleID, googleAT }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (res.ok) {
            res.json().then((j) => {
                localStorage.setItem("token", j.token);
                localStorage.setItem("refresh", j.refreshToken);
            });
            setLoggedIn(true);
            setState("DONE");
            setTimeout(() => {
                history.push(next ?? "/");
            }, 2000);
        } else {
            res.json().then((t: any) => setError(t?.message || t));
        }
    };

    const googleSuccess = (user) => {
        submitGoogle(user.googleId, user.tokenId)
    }

    return (
        <>
            <TopBar title="Login" back noRight />
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    padding: "48px 24px",
                    backgroundColor: theme.palette.background.default,
                    overflowY: 'auto'
                }}
            >
                {state === "ASKNUM" && (
                    <Div style={{ alignItems: "stretch", maxWidth: 640 }}>
                        <Header
                            title="Enter Contact Number"
                            subtitle="Please enter your registered mobile number"
                        />
                        <TextField
                            placeholder="Mobile number"
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 24 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        +65
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {message && <Typography style={{ marginTop: 8, color: '#F66C62' }}>{message}</Typography>}
                        <Button
                            style={{ margin: "12px 0px 32px 0px" }}
                            onClick={requestOTP}
                            variant="contained"
                            fullWidth
                            disabled={mobileNumber.length < 8}
                        >
                            Confirm
                        </Button>
                        <GoogleLogin
                            clientId={GOOGLE_CLIENT_ID}
                            buttonText="Login with Google"
                            onSuccess={googleSuccess}
                            onFailure={(f) => console.log('failure', f)}
                            cookiePolicy={'single_host_origin'}
                            style={{ marginBottom: 8 }}
                        />
                        <ErrorModal open={!!error} handleClose={() => setError('')} message={error} />
                    </Div>
                )}

                {state === "ASKOTP" && (
                    <Div>
                        <Header
                            title="Enter OTP"
                            subtitle={`The 6 digit number was sent to +65 ${mobileNumber}`}
                        />
                        <TextField
                            placeholder="OTP"
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            variant="outlined"
                            style={{ marginTop: 24 }}
                            value={pin}
                            onChange={(e) => setPin(e.target.value)}
                            fullWidth
                        />
                        {message && <Typography style={{ color: '#F66C62', marginTop: 8, }}>{message}</Typography>}
                        <Button
                            style={{ margin: "14px 0px 0px 0px" }}
                            onClick={() => {
                                requestOTP()
                            }}
                            variant="contained"
                            fullWidth
                        >
                            Resend OTP
                        </Button>
                        <Button
                            style={{ margin: "14px 0px" }}
                            onClick={() => {
                                submit();
                            }}
                            variant="contained"
                            fullWidth
                            disabled={pin.length < 6}
                        >
                            Confirm
                        </Button>
                    </Div>
                )}

                {state === "DONE" && (
                    <Div style={{ height: "80%", justifyContent: "center" }}>
                        <Typography
                            style={{
                                color: theme.palette.secondary.main,
                                fontSize: 20,
                                fontWeight: 800,
                            }}
                        >
                            Log In successful
                        </Typography>
                        <Typography
                            style={{
                                color: theme.palette.secondary.main,
                                fontSize: 28,
                                fontWeight: 800,
                            }}
                        >
                            Enjoy charging!
                        </Typography>
                    </Div>
                )}
            </Grid>
        </>
    );
}
