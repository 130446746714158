import React, { createContext, useEffect, useState } from "react";

export const AuthContext = createContext({
    loggedIn: false,
    setLoggedIn: (loggedIn: boolean) => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("token")) {
            setLoggedIn(true);
        }
        window.addEventListener("storage", () => {
            if (
                !localStorage.getItem("token") &&
                !localStorage.getItem("refresh")
            ) {
                setLoggedIn(false);
            }
        });
    }, []);
    return (
        <AuthContext.Provider value={{ loggedIn, setLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};
