import { Typography, useTheme } from "@material-ui/core";

type P = {
    title: string;
    subtitle: string;
};
export const Header = ({ title, subtitle }: P) => {
    const theme = useTheme();
    return (
        <>
            <Typography
                style={{
                    color: theme.palette.primary.main,
                    fontSize: 17,
                    fontWeight: "bold",
                    alignSelf: "center",
                }}
            >
                {title}
            </Typography>
            <Typography
                style={{ color: theme.palette.primary.main, fontSize: 12, alignSelf: "center" }}
            >
                {subtitle}
            </Typography>
        </>
    );
};
