import { Typography, useTheme } from "@material-ui/core";
import { useHistory } from "react-router";
import { DivHor } from "../../../components/base/Divs";

type P = { searchTerm: string };
export const SearchInput = ({ searchTerm }: P) => {
    const history = useHistory();
    const theme = useTheme();
    return (
        <DivHor
            style={{
                alignSelf: "stretch",
                borderBottomColor: theme.palette.neutral.light,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
            }}
            onClick={() => history.push("search")}
        >
            <img
                src={"images/charge-pin.png"}
                style={{ height: 28, marginRight: 24 }}
                alt=""
            />
            <Typography
                style={{
                    color: theme.palette.neutral.light,
                    padding: "8px 0px",
                    minWidth: "70%",
                }}
            >
                {searchTerm ? searchTerm : "Find charging locations here"}
            </Typography>
        </DivHor>
    );
};
