import { Dialog, Typography, Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { Theme, withStyles, useTheme } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { DialogTitle } from '../common/DialogTitle';
import ShareIcon from '@material-ui/icons/Share';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StarsIcon from '@material-ui/icons/Stars';
import NotificationsIcon from '@material-ui/icons/Notifications';

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: 16,
    paddingLeft: 32,
    paddingRight: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))(MuiDialogContent);

export default function WelcomePopup({open=false, handleClose}:{open ?: boolean, handleClose: Function}) {

    const history = useHistory();
    const theme = useTheme();

    const closeHandler = () => {
        handleClose();
    }

    return (
        <Dialog onClose={closeHandler} aria-labelledby="customized-dialog-title" open={open} PaperProps={{ style: {
            borderRadius: 16,
            minWidth: 300,
            width: '50%',
        }}}>
          <DialogTitle id="customized-dialog-title" onClose={closeHandler}>
            Welcome to CGW
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{
                fontWeight: 600,
                textAlign: 'center',
                color: theme.palette.primary.main,
            }}>
              Enjoy these benefits for FREE!
            </Typography>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: 'fit-content',
            }}>
                <Typography style={{
                    fontSize: 14,
                    color: theme.palette.primary.main,
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                }}>
                    <ShareIcon style={{fill: theme.palette.secondary.main, paddingRight: 8,}}/> Share Receipts for Claims
                </Typography>
                <Typography style={{
                    fontSize: 14,
                    color: theme.palette.primary.main,
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                }}>
                    <WatchLaterIcon style={{fill: theme.palette.secondary.main, paddingRight: 8,}}/> Transaction History
                </Typography>
                <Typography style={{
                    fontSize: 14,
                    color: theme.palette.primary.main,
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                }}>
                    <MonetizationOnIcon style={{fill: theme.palette.secondary.main, paddingRight: 8,}}/> Save Payment Details
                </Typography>
                <Typography style={{
                    fontSize: 14,
                    color: theme.palette.primary.main,
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                }}>
                    <StarsIcon style={{fill: theme.palette.secondary.main, paddingRight: 8,}}/> Access to Favourites
                </Typography>
                <Typography gutterBottom style={{
                    fontSize: 14,
                    color: theme.palette.primary.main,
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                }}>
                    <NotificationsIcon style={{fill: theme.palette.secondary.main, paddingRight: 8,}}/> Charge Updates!
                </Typography>
            </div>
            <Button style={{
                backgroundColor: theme.palette.secondary.light,
                color: 'white',
                width: '100%',
                paddingTop: 12,
                paddingBottom: 12,
                borderRadius: 16,
                marginTop: 16,
                marginBottom: 16,
            }} onClick={() => {handleClose(); history.push("/register")}}>Sign Up</Button>
            <Typography style={{
                fontWeight: 600,
                textAlign: 'center',
                color: theme.palette.primary.main,
            }}>
              Already registered?<Button style={{
                color: theme.palette.secondary.main,
                fontWeight: 600,
                textTransform: 'none',
                marginBottom: 4,
                marginTop: 0,
                fontSize: '1rem',
            }} onClick={() => {handleClose(); history.push("/login")}}>Log In</Button>
            </Typography>
          </DialogContent>
        </Dialog>
    );
  }