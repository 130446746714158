import { Button, DialogTitle, Grid, Typography, useTheme } from "@material-ui/core";
import { useEffect } from "react";
import { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import QrReader from "react-qr-scanner";
import { useState } from "react";
import TopBar from "../../components/common/TopBar";
import { useQuery, useQueryClient } from "react-query";
import { del, post, query } from "../../network";
import { CreditCardOutlined, CloseOutlined } from "@material-ui/icons";

import { Div, DivHor } from "../../components/base/Divs";
import { shadow } from "../../shadows";

export default function ChoosePMModal ({ setPMID }: { setPMID: (string) => void }) {
    const theme = useTheme();
    const history = useHistory();
    const queryClient = useQueryClient();

    const { data: cards, refetch } = useQuery(
        "paymentMethods",
        () => query("me/paymentMethods"),
        { initialData: [] }
    );

    return (
        <Div style={{
            zIndex: 1000,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        }}>
            <div
                style={{
                    backgroundColor: theme.palette.background.default,
                    padding: 16,
                    borderRadius: 14,
                    maxWidth: "80%",
                    ...shadow
                }} 
            >
                {
                    cards.length === 0 && (
                        <DialogTitle style={{ color: theme.palette.neutral.main }}>
                            No payment method set-up yet, please add a payment method before proceeding
                        </DialogTitle>
                    )
                }
                {
                    cards.length > 0 && (
                        <DialogTitle style={{ color: theme.palette.neutral.main }}>
                            Please select a payment method
                        </DialogTitle>
                    )
                }
                {cards.map((c: any) => (
                    <DivHor
                        style={{
                            padding: 8,
                            border: "1px lightgray solid",
                            borderColor: theme.palette.neutral.light,
                            borderRadius: 14,
                            justifyContent: "space-between",
                            margin: "8px 0px",
                        }}
                        onClick={() => setPMID(c._id)}
                    >
                        <DivHor>
                            <CreditCardOutlined />
                            <span
                                style={{
                                    fontWeight: 700,
                                    fontSize: 12,
                                    marginLeft: 14,
                                    color: theme.palette.primary.main,
                                }}
                            >
                                {c.cardType}
                            </span>
                            <span
                                style={{
                                    fontSize: 18,
                                    marginLeft: 14,
                                    color: theme.palette.primary.main,
                                }}
                            >
                                {c.lastFourDigits}
                            </span>
                        </DivHor>
                    </DivHor>
                ))}
                <Button
                    variant="contained"
                    fullWidth
                    style={{ marginTop: 8 }}
                    onClick={() => history.push("/paymentMethods")}
                >
                    Edit Payment Methods
                </Button>
            </div>
        </Div>
    );
}
