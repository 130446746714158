import { Typography, TypographyProps, useTheme } from "@material-ui/core";
import React from "react";

export default function InfoButton(props: TypographyProps) {
    const theme = useTheme();
    return (
        <Typography
            {...props}
            style={{
                ...props.style,
                color: theme.palette.primary.main,
                fontWeight: "bold",
                backgroundColor: theme.palette.background.paper,
                padding: "2px 12px",
                borderRadius: 48,
                fontSize: 12,
            }}
        >
            INFO
        </Typography>
    );
}
