import {Typography, useTheme } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import TopBar from "../../components/common/TopBar";
import { post } from "../../network";

import {
    Elements,
    useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Div, } from "../../components/base/Divs";

export function PaymentCompleteForm() {
    const stripe = useStripe();
    const [message, setMessage] = useState(null);
    const theme = useTheme();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        // Retrieve the "setup_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        );

        console.log("CLIENT SECRET", clientSecret);

        // Retrieve the SetupIntent
        stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
            // Inspect the SetupIntent `status` to indicate the status of the payment
            // to your customer.
            //
            // Some payment methods will [immediately succeed or fail][0] upon
            // confirmation, while others will first enter a `processing` state.
            //
            // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
            console.log("setupintent", setupIntent);
            switch (setupIntent.status) {
                case "succeeded":
                    setMessage("Success! Your payment method has been saved.");
                    const paymentMethodId = setupIntent.payment_method;
                    const card = {
                        lastFourDigits: "1111",
                        cardName: "Ryan",
                        cardType: "VISA",
                        token: paymentMethodId,
                    };
                    post("me/paymentMethods", card).then((res) => {
                        if (res.ok) {
                            console.log("SUCCESS", res.status);
                        } else {
                            res.text().then((t) => console.error(t));
                        }
                    });
                    break;

                case "processing":
                    setMessage(
                        "Processing payment details. We'll update you when processing is complete."
                    );
                    break;

                case "requires_payment_method":
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again
                    setMessage(
                        "Failed to process payment details. Please try another payment method."
                    );
                    break;
            }
        });
    }, [stripe]);

    return (
        <>
            <TopBar title="Payment Complete" back />
            <Div
                style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    padding: "24px 24px",
                }}
            >
                <Typography style={{color: theme.palette.primary.main}}>{message}</Typography>
            </Div>
        </>
    );
}

// const stripePromise = loadStripe("pk_test_P2odmVRARFjM58FPAfqELVW300aSxUlj3C");
const stripePromise = loadStripe("pk_live_INVey83MGbEnovWbTkelREc600KXCjIvlT");

export default function PaymentComplete() {
    return (
        <Elements stripe={stripePromise}>
            <PaymentCompleteForm />
        </Elements>
    );
}
