import { Grid, Typography } from "@material-ui/core";
import Logo from "../../../components/common/Logo";
import TopBar from "../../../components/common/TopBar";

export default function OnboardingFrameA() {
    return (
        <>
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{
                    flex: 1,
                    justifyContent: "center",
                    paddingTop: 32,
                }}
            >
                <div className="textWrapper" 
                    style= {{
                        position: "absolute",
                        bottom: "460px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography style={{ 
                        color: "#058B8E",
                        fontWeight: 700,
                        fontSize: 60,
                        }}
                    >
                        1st
                    </Typography>
                    <Typography
                        style={{
                            color: "#058B8E",
                            padding: "0px 32px 0px 48px",
                            marginTop: "-16px",
                            fontSize: 20,
                            fontWeight: 700,
                            textAlign: "center",
                        }}
                    >
                        universal EV locator<br/>in Singapore
                    </Typography>
                </div>
                <div className="ChargerIconWrapper"
                    style={{
                        position: "absolute",
                        bottom: "-5px"
                    }}
                >
                    <svg width="400" viewBox="0 0 414 481" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d)">
                        <mask id="mask0" style={{maskType:"alpha",}} maskUnits="userSpaceOnUse" x="109" y="99" width="201" height="248">
                        <path d="M109.748 199.51C109.748 144.296 154.508 99.5361 209.722 99.5361C264.936 99.5361 309.696 144.296 309.696 199.51L309.696 246.073C309.696 301.287 264.936 346.047 209.722 346.047C154.508 346.047 109.748 301.287 109.748 246.073L109.748 199.51Z" fill="#058B8E"/>
                        </mask>
                        <g mask="url(#mask0)">
                        <path d="M87.834 195.685L328.867 195.685L328.867 346.056L87.834 346.056L87.834 195.685Z" fill="#058B8E"/>
                        </g>
                        <path d="M131.66 138.983L189.179 138.983L189.179 222.797L131.66 222.797L131.66 138.983Z" fill="#058B8E"/>
                        <path d="M233.009 138.989L290.528 138.989L290.528 222.803L233.009 222.803L233.009 138.989Z" fill="#058B8E"/>
                        </g>
                        <g filter="url(#filter1_d)">
                        <path d="M212 341L212 481" stroke="#058B8E" strokeWidth="50"/>
                        </g>
                        <circle cx="159.5" cy="239.5" r="6.5" fill="white"/>
                        <circle cx="262.5" cy="239.5" r="6.5" fill="white"/>
                        <path d="M190.5 270.5C198.667 273.833 224.6 277.6 233 266C230.167 272.5 211.7 282.5 190.5 270.5Z" fill="white"/>
                        <defs>
                        <filter id="filter0_d" x="105.748" y="138.983" width="207.948" height="215.064" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        <filter id="filter1_d" x="183" y="341" width="58" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        </defs>
                    </svg>
                </div>
            </Grid>
        </>
    );
}
