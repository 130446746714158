import { Typography, useTheme } from "@material-ui/core";

type P = {
    text: string;
};

export default function DisclaimerText({ text }: P) {
    const theme = useTheme()
    return (
        <Typography style={{ fontSize: 12, textAlign: "center", color: theme.palette.primary.main }}>
            {text}
        </Typography>
    );
}
