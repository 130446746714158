import { Divider, SvgIconTypeMap, useTheme, makeStyles, Theme, Switch, Typography } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import {
    AccountCircle,
    ExitToApp,
    Help,
    Info,
    Payment,
    Star,
    WatchLater,
    Visibility,
} from "@material-ui/icons";
import React, { createContext, useContext, useEffect } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import config from "../../config";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: "absolute",
        marginTop: 56,
        backgroundColor: theme.palette.background.default,
        "& .pro-sidebar-inner": {
            backgroundColor: theme.palette.background.default,
        }
    }
}));

export const SidebarContext = createContext({
    toggleSidebar: () => { },
    closeSidebar: () => { },
});

type P = {
    text: string;
    Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    url?: string;
    close: Function;
    disabled?: boolean;
    emphasized?: boolean;
    onClick?: Function;
};

const MenuRow = ({
    text,
    Icon,
    url,
    close,
    disabled,
    emphasized,
    onClick,
}: P) => {
    const history = useHistory();
    const theme = useTheme();
    const _onClick = () => {
        if (disabled) return;
        if (url) history.push(url);
        if (onClick) onClick();
        close();
    };
    const color = disabled ? theme.palette.neutral.dark : emphasized ? theme.palette.secondary.main : theme.palette.primary.main;
    return (
        <MenuItem style={{ color }} onClick={_onClick}>
            {Icon ? (
                <Icon style={{ height: 14, width: 14, marginRight: 10 }} />
            ) : (
                <></>
            )}
            {text}
        </MenuItem>
    );
};

export const SideBar = ({
    open,
    close,
    preferDark,
    togglePreferDark,
}: {
    open: boolean;
    close: Function;
    preferDark: boolean;
    togglePreferDark: Function;
}) => {
    const { loggedIn, setLoggedIn } = useContext(AuthContext);
    const { closeSidebar } = useContext(SidebarContext);

    const location = useLocation();
    const history = useHistory();
    const theme = useTheme();
    const classes = useStyles();

    useEffect(() => {
        closeSidebar();
    }, [location]);

    // LOCATOR_ONLY sidebar items
    if (config.LOCATOR_ONLY) {
        return (
            <>
                <ProSidebar
                    collapsed={!open}
                    collapsedWidth="0px"
                    width="100vw"
                    className={classes.root}
                >
                    <Menu iconShape="square" style={{ backgroundColor: theme.palette.background.default }}>
                        <MenuRow text="About" url="/about" close={close} Icon={Info} />
                        <MenuRow
                            text="Support & Help"
                            url="/support"
                            close={close}
                            Icon={Help}
                        />
                        <MenuItem>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}>
                                <span style={{ color: theme.palette.primary.main }}>
                                    <Visibility style={{ height: 14, width: 14, marginRight: 10 }} />
                                    Dark Mode
                                </span>
                                <div style={{
                                    marginTop: '-8px',
                                }}>
                                    <Switch
                                        checked={preferDark}
                                        onChange={() => { togglePreferDark() }}
                                    />
                                </div>
                            </div>
                        </MenuItem>
                        <Divider />
                    </Menu>
                </ProSidebar>
            </>
        )
    }


    // regular sidebar items
    return (
        <>
            <ProSidebar
                collapsed={!open}
                collapsedWidth="0px"
                width="100vw"
                className={classes.root}
            >
                <Menu iconShape="square" style={{ backgroundColor: theme.palette.background.default }}>
                    {loggedIn ? (
                        <MenuRow
                            text="Account"
                            url="/account"
                            close={close}
                            Icon={AccountCircle}
                        />
                    ) : (
                        <>
                            <MenuRow
                                text="Log In"
                                url="/login"
                                close={close}
                                emphasized
                                Icon={ExitToApp}
                            />
                            <MenuRow
                                text="Register"
                                url="/register"
                                close={close}
                                emphasized
                                Icon={ExitToApp}
                            />
                        </>
                    )}
                    <MenuRow
                        text="Favourites"
                        url="/favourites"
                        close={close}
                        disabled={!loggedIn}
                        Icon={Star}
                    />
                    <MenuRow
                        text="Transactions"
                        url="/transactions"
                        close={close}
                        disabled={!loggedIn}
                        Icon={WatchLater}
                    />
                    <MenuRow
                        text="Payment Methods"
                        url="/paymentMethods"
                        close={close}
                        disabled={!loggedIn}
                        Icon={Payment}
                    />
                    <Divider />
                    <MenuRow text="About" url="/about" close={close} Icon={Info} />
                    <MenuRow
                        text="Support & Help"
                        url="/support"
                        close={close}
                        Icon={Help}
                    />
                    <MenuItem>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}>
                            <span style={{ color: theme.palette.primary.main }}>
                                <Visibility style={{ height: 14, width: 14, marginRight: 10 }} />
                                Dark Mode
                            </span>
                            <div style={{
                                marginTop: '-8px',
                            }}>
                                <Switch
                                    checked={preferDark}
                                    onChange={() => { togglePreferDark() }}
                                />
                            </div>
                        </div>
                    </MenuItem>
                    <Divider />
                    {loggedIn && (
                        <>
                            <MenuRow
                                text="Sign Out"
                                onClick={() => {
                                    setLoggedIn(false);
                                    history.push("/logout");
                                    localStorage.removeItem('token')
                                    localStorage.removeItem('refresh')
                                }}
                                close={close}
                                Icon={ExitToApp}
                            />
                            <Divider />
                        </>
                    )}
                </Menu>
            </ProSidebar>
        </>
    );
};
