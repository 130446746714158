import { Button, Grid, useTheme } from "@material-ui/core";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Div, DivHor } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";
import { AuthContext } from "../../context/AuthContext";
import { query } from "../../network";
import { Transaction } from "../../typings";
import LargeDetail from "./components/LargeDetail";
import StatusText from "./components/StatusText";

type P = { openReceipt?: Function; };
export default function HoggingScreen({ openReceipt: _openReceipt, }: P) {
    const history = useHistory();
    const theme = useTheme();
    const openReceipt = () => {
        _openReceipt?.();
        // history.push("/receipt");
        if (current && current._id) {
            history.push(`/transactions/${current._id}`);
        }
    };
    const { data: transactions } = useQuery<Transaction[]>(
        "transactions",
        () => query(`me/transactions`),
        { refetchInterval: 4000, initialData: [] }
    );
    const current = useMemo(() => {
        if (transactions.length > 0) return transactions[transactions.length -1]
        return null
    }, [transactions])

    const { loggedIn } = useContext(AuthContext);

    return (
        <>
            <TopBar title="Charging" showMap />
            <Grid
                container
                xs={12}
                direction="column"
                alignItems="center"
                justifyContent="space-around"
                style={{
                    padding: 14,
                    backgroundColor: theme.palette.background.default,
                    minHeight: '100%',
                    paddingBottom: !loggedIn ? '20%' : '0px',
                    justifyContent: "flex-start"
                }}
            >
                <Div>
                    <img
                        src="/images/check.png"
                        alt=""
                        style={{
                            margin: "8px 0px",
                            height: 156,
                            width: 156,
                            backgroundColor: theme.palette.background.default
                        }}
                    />
                    <DivHor>
                        <LargeDetail
                            title="You've Paid"
                            text={current?.totalCost?.toString()}
                            prefix="$"
                        />
                        <span
                            style={{
                                borderRightWidth: 1,
                                borderRightColor: "lightgray",
                                borderRightStyle: "solid",
                                alignSelf: "stretch",
                            }}
                        />
                        <LargeDetail
                            title="Total Charge"
                            text={current?.totalKWHCharged.toString()}
                            suffix="kWh"
                        />
                    </DivHor>
                </Div>

                <Div>
                    <StatusText
                        text="Please remove your vehicle immediately to avoid penalty"
                        type="FAILURE"
                    />
                    <Button
                        variant="contained"
                        style={{ margin: "14px 0px" }}
                        onClick={openReceipt}
                    >
                        Receipt
                    </Button>
                </Div>
            </Grid>
        </>
    );
}
