import React, { useState, useEffect } from 'react';
import { Dialog, Typography, Button, IconButton, useTheme } from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import { createStyles, Theme, withStyles, WithStyles, ThemeProvider } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { DialogTitle } from "../../components/common/DialogTitle";
import 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShareIcon from '@material-ui/icons/Share';
import { post } from '../../network';

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: 16,
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}))(MuiDialogContent);

const DownloadButton = withStyles((theme: Theme) => ({
  root: {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      textTransform: 'none',
      borderRadius: 8,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 4,
      paddingBottom: 4,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    }
  }
}))(Button);

const ShareButton = withStyles((theme: Theme) => ({
  root: {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      textTransform: 'none',
      borderRadius: 8,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 4,
      paddingBottom: 4,
  }
}))(Button);

export default function TransactionFilterPopup({
  open=false,
  closeHandler,
}:{
  open ?: boolean,
  closeHandler?: Function,
}) {

    const history = useHistory();
    const theme = useTheme();

    const [fromDate, setFromDate] = useState<Date|null>(new Date());
    const [toDate, setToDate] = useState<Date|null>(new Date());

    const handleFromDateChange = (date: Date | null) => {
      setFromDate(date);
    };
    const handleToDateChange = (date: Date | null) => {
      setToDate(date);
    };

  const handleDownload = async () => {
      fromDate.setHours(0, 0, 0, 0)
      toDate.setHours(23, 59, 59, 999)
      const q = { startDateTime: { $gte: fromDate.toISOString(), $lt: toDate.toISOString() } };
      post('me/transactions/filtered?csv=true', q)
        .then(res => res.text())
        .then(res => {
          const hiddenElement = document.createElement('a');
          hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res);
          hiddenElement.target = '_blank';
          hiddenElement.download = 'transactions.csv';
          hiddenElement.click();
        })
      closeHandler();
  }

    const handleShare = async () => {
      fromDate.setHours(0, 0, 0, 0)
      toDate.setHours(23, 59, 59, 999)
      const q = { startDateTime: { $gte: fromDate.toISOString(), $lt: toDate.toISOString() } };
      post('me/transactions/filtered?csv=true', q)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "transactions.csv", {type: 'text/csv'});
          const filesArray = [file];

          if ((window.navigator as any).canShare && (window.navigator as any).canShare({ files: filesArray })) {
            (window.navigator as any).share({
              files: filesArray,
            });
          }
        })
      closeHandler();
    }

    const handleClose = () => {
      closeHandler();
    };
  
    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} PaperProps={{ style: {
            borderRadius: 16,
            minWidth: 300,
            width: '50%',
        }}}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Summary
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{
                fontWeight: 600,
                textAlign: 'center',
                color: theme.palette.primary.main,
            }}>
              Select Transaction Period
            </Typography>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="from-date-picker-inline"
              label="From"
              value={fromDate}
              onChange={handleFromDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change from date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="from-date-picker-inline"
              label="To"
              value={toDate}
              onChange={handleToDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change from date',
              }}
            />
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 32,
            }}>
            <DownloadButton
              startIcon={<GetAppIcon style={{fill: 'white'}}/>}
              onClick={handleDownload}
            >Download</DownloadButton>
            <ShareButton
              variant='outlined'
              startIcon={<ShareIcon />}
              onClick={handleShare}
            >Share</ShareButton>
            </div>
          </DialogContent>
        </Dialog>
    );
  }