import { Typography, useTheme } from "@material-ui/core";
import { BatteryChargingFull } from "@material-ui/icons";
import { useHistory } from "react-router";
import { DivHor } from "../../../components/base/Divs";
import { shadow } from "../../../shadows";
import { Transaction } from "../../../typings";

export const ChargingIndicator = ({ current }: { current: Transaction }) => {
    const postalCode = current.location.postalCode;
    const evseID = current.evseID;
    const history = useHistory();
    const theme = useTheme();
    return (
        <DivHor
            style={{
                backgroundColor: theme.palette.background.default,
                alignSelf: "stretch",
                justifyContent: "flex-start",
                zIndex: 101,
                ...shadow,
            }}
            onClick={() => history.push(`/charger/${postalCode}/${evseID}`)}
        >
            <BatteryChargingFull
                style={{ color: theme.palette.secondary.light, transform: "rotate(90deg)" }}
            />
            <Typography
                style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    textAlign: "center",
                    fontWeight: "bold",
                    color: theme.palette.secondary.light,
                }}
            >
                Go back to charging
            </Typography>
        </DivHor>
    );
};
