import FileSaver from "file-saver";
import { Button, Grid, Typography, useTheme } from "@material-ui/core";
import { Div } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";
import { shadow } from "../../shadows";
import { Transaction } from "../../typings";
import DetailRow from "./components/DetailRow";
import { CloseOutlined } from "@material-ui/icons";
import LargeDetail from "./components/LargeDetail";
import { useHistory } from "react-router";
import { useMemo } from "react";
import { query } from "../../network";

type P = {
    transaction?: Transaction;
    openReceipt?: Function;
};

export default function ReceiptScreen({
    transaction,
    openReceipt: _openReceipt,
}: P) {
    const history = useHistory();
    const theme = useTheme();
    console.log("transactions", transaction);

    const shareReceipt = async () => {
        const res = await query(`transactions/share/${transaction._id}`, { raw: true })
        const text = await res.text()
        const blob = new Blob([text], { type: 'application/pdf' });
        const file = new File([blob], "receipt.pdf", { type: blob.type });
        if ((window.navigator as any).canShare && (window.navigator as any).canShare({ files: [file] })) {
            (window.navigator as any).share({ files: [file], title: "Transaction Receipt" })
        }
    };

    const downloadReceipt = async () => {
        const res = await query(`transactions/share/${transaction._id}`, { raw: true })
        const text = await res.text()
        const blob = new Blob([text], { type: 'application/pdf' });
        FileSaver.saveAs(blob, 'receipt.pdf')
    };

    const duration = useMemo(() => {
        const endTime = new Date(transaction?.endDateTime).getTime();
        const startTime = new Date(transaction?.startDateTime).getTime();
        const diff = (endTime - startTime) / 1000;
        if (diff > 60 * 60) {
            return `${(diff / 60 / 60).toFixed(0)} hours`;
        } else if (diff > 60) {
            return `${(diff / 60).toFixed(0)} minutes`;
        } else {
            return `${diff.toFixed(0)} seconds`;
        }
    }, [transaction]);

    const providerName = transaction?.location.onNetwork ? 'Public' : transaction?.location.provider?.name
    const providerImage = transaction?.location.onNetwork ? '/images/public-charger-icon.png' : transaction?.location.provider?.imageURL

    return (
        <div style={{ overflowY: 'auto' }}>
            <Div style={{ padding: 0, margin: 0, width: "100vw", minHeight: "100vh", backgroundColor: theme.palette.background.default }}>
                <div
                    style={{
                        alignSelf: "stretch",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: theme.palette.background.default,
                        paddingBottom: 48,
                    }}
                >
                    <CloseOutlined
                        style={{
                            alignSelf: "flex-end",
                            color: "#AEB1C1",
                            padding: "24px 24px 8px 24px",
                            transform: "scale(1.4)",
                        }}
                        onClick={() => history.goBack()}
                    />
                </div>
                <Div
                    style={{
                        alignSelf: "stretch",
                        alignItems: "center",
                        padding: 0,
                        margin: 0,
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <img
                        src={providerImage}
                        style={{
                            width: 64,
                            height: 64,
                            borderRadius: 180,
                            objectFit: "cover",
                            margin: "14px 0px",
                            borderColor: "white",
                            borderWidth: 8,
                            borderStyle: "solid",
                            ...shadow,
                        }}
                        alt="Charger Provider"
                    />
                    <LargeDetail
                        title="You've Paid"
                        text={transaction?.totalCost?.toFixed(2)}
                        prefix="$"
                    />
                    <Typography
                        style={{
                            color: theme.palette.neutral.main,
                        }}
                    >
                        {new Date(transaction?.startDateTime).toLocaleString(
                            "en-GB"
                        )}
                    </Typography>
                    <Typography
                        style={{
                            marginBottom: 24,
                            color: theme.palette.neutral.main,
                        }}
                    >
                        ({duration})
                    </Typography>
                </Div>
                <DetailRow
                    title="Total Charge"
                    text={`${transaction?.totalKWHCharged?.toFixed(
                        2
                    )}kWh ($${transaction?.chargingRateKWH?.toFixed(2)}/kWh)`}
                />
                <DetailRow
                    title="Charger ID"
                    text={`${transaction?.evseID}`}
                />
                <DetailRow
                    title="Charge Location"
                    text={transaction?.location.name}
                />
                <DetailRow title="Charger By" text={providerName} />
                <DetailRow
                    title="Transaction Ref. No."
                    text={transaction?._id}
                />
                <div style={{ alignSelf: "stretch", padding: "14px" }}>
                    {/* <Button
                        variant="contained"
                        style={{ margin: "14px 0px 0px 0px", alignSelf: "center" }}
                        onClick={shareReceipt}
                        fullWidth
                    >
                        Share
                    </Button> */}
                    <Button
                        variant="contained"
                        style={{ margin: "14px 0px", alignSelf: "center" }}
                        onClick={downloadReceipt}
                        fullWidth
                    >
                        Download
                    </Button>
                </div>
            </Div>
        </div>
    );
}
