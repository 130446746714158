import config from './config';

export const basePath = config.API_URL;

const refresh = async () => {
    console.log("Attempting refresh..");
    const refreshToken = localStorage.getItem("refresh");
    if (refreshToken) {
        const res = await fetch(`${basePath}auth/refresh`, {
            method: "POST",
            headers: {
                Authorization: refreshToken,
            },
        });
        if (res.ok) {
            const { token } = await res.json();
            localStorage.setItem("token", token);
            console.log("Refresh token found, valid and got token.");
            return true;
        } else {
            console.log("Refresh token found, invalid.", res.status);
            return false;
        }
    }
    console.log("no refresh token");
    return false;
};

const fail = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
};

export async function query(
    path: string,
    {
        raw = false,
        baseOverride,
    }: { raw?: boolean; baseOverride?: string } = {},
    refreshed?: boolean
) {
    // const _basePath = baseOverride ? altPaths[baseOverride] : basePath;
    const _basePath = basePath;
    const url = `${_basePath}${path}`;
    const token = localStorage.getItem("token");
    console.log("GET", url);
    const headers = token ? { Authorization: token } : {};
    const res = await fetch(url, {
        headers,
    });
    if (!res.ok && res.status === 401) {
        if (!refreshed) {
            const res = await refresh();
            if (res) {
                return await query(path, { raw, baseOverride }, true);
            } else {
                fail();
            }
        } else {
            fail();
        }
    }
    if (raw) return res;
    return res.json();
}

export async function post(path: string, body: object, refreshed?: boolean) {
    const url = `${basePath}${path}`;
    const token = localStorage.getItem("token");
    console.log("POST", url);
    const headers = token ? { Authorization: token } : {};
    const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
    });
    if (!res.ok && res.status === 401) {
        if (!refreshed) {
            const res = await refresh();
            if (res) {
                return await query(path, body, true);
            } else {
                fail();
            }
        } else {
            fail();
        }
    }
    return res;
}

export async function patch(path: string, body: object, refreshed?: boolean) {
    const url = `${basePath}${path}`;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: token } : {};
    console.log("PATCH", url);
    const res = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
    });
    if (!res.ok && res.status === 401) {
        if (!refreshed) {
            const res = await refresh();
            if (res) {
                return await query(path, body, true);
            } else {
                fail();
            }
        } else {
            fail();
        }
    }
    return res;
}

export async function put(path: string, body: object, refreshed?: boolean) {
    const url = `${basePath}${path}`;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: token } : {};
    console.log("PUT", url);
    const res = await fetch(url, {
        method: "put",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
    });
    if (!res.ok && res.status === 401) {
        if (!refreshed) {
            const res = await refresh();
            if (res) {
                return await query(path, body, true);
            } else {
                fail();
            }
        } else {
            fail();
        }
    }
    return res;
}

export async function del(path: string, body: object, refreshed?: boolean) {
    const url = `${basePath}${path}`;
    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: token } : {};
    console.log("DELETE", url);
    const res = await fetch(url, {
        method: "delete",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
    });
    if (!res.ok && res.status === 401) {
        if (!refreshed) {
            const res = await refresh();
            if (res) {
                return await query(path, body, true);
            } else {
                fail();
            }
        } else {
            fail();
        }
    }
    return res;
}
