import { Button, Typography, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "../../components/common/TopBar";
import { useQuery } from "react-query";
import { del, query } from "../../network";
import { CreditCardOutlined, CloseOutlined } from "@material-ui/icons";

import { Div, DivHor } from "../../components/base/Divs";

export default function PMScreen() {
    const theme = useTheme();
    const history = useHistory();

    const { data: cards, refetch } = useQuery(
        "paymentMethods",
        () => query("me/paymentMethods"),
        { initialData: [] }
    );

    console.log("CARDS", cards);

    const remove = async (cardID) => {
        const res = await del(`me/paymentMethods/${cardID}`, {});
        if (res.ok) {
            refetch();
        }
    };

    return (
        <>
            <TopBar title="Payment Method" back backURL="/" />
            <Div
                style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    padding: "24px 24px",
                    backgroundColor: theme.palette.background.default,
                }}
            >
                {cards.length === 0 && (
                    <Typography
                        style={{
                            textAlign: "center",
                            padding: "24px 0px",
                            color: theme.palette.primary.main,
                        }}
                    >
                        You have not added any payment methods
                    </Typography>
                )}
                {cards.map((c: any) => (
                    <DivHor
                        style={{
                            padding: 8,
                            border: "1px lightgray solid",
                            borderColor: theme.palette.neutral.light,
                            borderRadius: 14,
                            justifyContent: "space-between",
                            margin: "8px 0px",
                        }}
                    >
                        <DivHor>
                            <CreditCardOutlined />
                            <span
                                style={{
                                    fontWeight: 700,
                                    fontSize: 12,
                                    marginLeft: 14,
                                    color: theme.palette.primary.main,
                                }}
                            >
                                {c.cardType}
                            </span>
                            <span
                                style={{
                                    fontSize: 18,
                                    marginLeft: 14,
                                    color: theme.palette.primary.main,
                                }}
                            >
                                {c.lastFourDigits}
                            </span>
                        </DivHor>
                        <CloseOutlined onClick={() => remove(c._id)} />
                    </DivHor>
                ))}
                <Button
                    variant="contained"
                    fullWidth
                    style={{ marginTop: 8 }}
                    onClick={() => history.push("/addPaymentMethod")}
                >
                    Add Payment Method
                </Button>
            </Div>
        </>
    );
}
