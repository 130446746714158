import { Typography, useTheme } from "@material-ui/core";
import { min } from "date-fns";
import { CSSProperties, useMemo } from "react";
import { useHistory } from "react-router";
import { Div, DivHor } from "../../../components/base/Divs";
import { shadow } from "../../../shadows";
import { Location } from "../../../typings";
import { distanceBetween } from "../../../util";

const VertBar = ({ title, Row1, Row2 }) => {
    const theme = useTheme();
    return (
        <Div style={{ padding: 4 }}>
            <Typography style={{ height: 24, fontSize: 12, color: theme.palette.neutral.dark }}>
                {title}
            </Typography>
            <div style={{ height: 32 }}>{Row1}</div>
            <div style={{ height: 32 }}>{Row2}</div>
        </Div>
    );
};

const VertLine = () => {
    const theme = useTheme();
    return (
        <div
            style={{
                borderRightColor: theme.palette.neutral.main,
                borderRightWidth: 1,
                borderRightStyle: "solid",
                height: "60%",
                marginLeft: 8,
                marginRight: 8,
            }}
        ></div>
    );
};

const Bar = ({ color }: { color: string }) => {
    return (
        <div
            style={{
                backgroundColor: color,
                width: 20,
                height: 8,
                borderRadius: 4,
            }}
        ></div>
    );
};

export const LocationListItem = ({
    location,
    style,
    currentLocation,
}: {
    location: Location;
    style: CSSProperties;
    currentLocation?: { latitude: number, longitude: number };
}) => {
    const history = useHistory();
    const theme = useTheme();

    const distanceFromCurrentLocation = useMemo(() => {
        if (currentLocation && location) {
            return `${distanceBetween(currentLocation, location.coordinates).toFixed(2)}km`;
        } else {
            return null;
        }
    }, [location, currentLocation])

    const acEvses = useMemo(() => {
        if (!location) return { available: 0, charging: 0, unknown: 0 };
        const ac = location.evses.filter(
            (e) => e.connectors[0].powerType.startsWith("AC")
        );
        const available = ac.filter((e) => e.status === "AVAILABLE").length;
        const charging = ac.filter((e) => e.status === "CHARGING").length;
        return {
            available,
            charging,
            unknown: ac.length - available - charging,
        };
    }, [location]);

    const dcEvses = useMemo(() => {
        if (!location) return { available: 0, charging: 0, unknown: 0 };
        const dc = location.evses.filter(
            (e) => e.connectors[0].powerType === "DC"
        );
        const available = dc.filter((e) => e.status === "AVAILABLE").length;
        const charging = dc.filter((e) => e.status === "CHARGING").length;
        return {
            available,
            charging,
            unknown: dc.length - available - charging,
        };
    }, [location]);

    const acPrice = useMemo(() => {
        if (!location) {
            return null;
        }

        const ac = location.evses.filter(
            (e) => e.connectors[0].powerType.startsWith("AC")
        );

        if (ac.length === 0) {
            return null;
        }

        const acPrices = ac.map(e => e.chargingRateKWH);

        return Math.min(...acPrices);

    }, [location]);

    const dcPrice = useMemo(() => {
        if (!location) {
            return null;
        }

        const dc = location.evses.filter(
            (e) => e.connectors[0].powerType === "DC"
        );

        if (dc.length === 0) {
            return null;
        }

        const dcPrices = dc.map(e => e.chargingRateKWH);

        return Math.min(...dcPrices);
    }, [location]);


    const providerName = useMemo(() => {
        return location.onNetwork ? 'Public' : location.provider?.name
    }, [location]);

    const providerImage = useMemo(() => {
        return location.onNetwork ? '/images/public-charger-icon.png' : location.provider?.imageURL
    }, [location]);

    return (
        <Div
            style={{
                alignItems: "flex-start",
                borderBottomStyle: "solid",
                borderBottomColor: "lightgray",
                borderBottomWidth: 1,
                padding: 0,
                justifyContent: "space-between",
                backgroundColor: theme.palette.background.default,
                ...style,
            }}
            onClick={() => history.push(`/location/${location.postalCode}`)}
        >
            <DivHor
                style={{
                    justifyContent: "space-between",
                    alignSelf: "stretch",
                    paddingBottom: 0
                }}
            >
                <Div style={{ alignItems: "flex-start", paddingBottom: 0 }}>
                    <DivHor style={{ padding: "0px 0px 4px 0px" }}>
                        <img
                            src={providerImage}
                            style={{
                                height: 28,
                                marginRight: 14,
                                borderRadius: 180,
                                ...shadow,
                            }}
                            alt=""
                        />
                        <Typography style={{ color: "#9190A7", paddingTop: 2, fontSize: 14 }}>
                            {providerName}
                        </Typography>
                        {
                            (providerName !== 'Public') && <img
                                src="/images/external.svg"
                                style={{
                                    height: 28,
                                    marginLeft: 10,
                                    paddingTop: 2
                                }}
                                alt=""
                            />
                        }
                    </DivHor>
                    <Typography style={{ fontSize: 22, color: theme.palette.primary.main, lineHeight: 1.1, paddingTop: 2, paddingBottom: 4 }}>
                        {location.name}
                    </Typography>
                    <DivHor
                        style={{
                            justifyContent: "flex-start",
                            padding: "0px 0px",
                        }}
                    >
                        <Typography style={{ fontSize: 15, color: theme.palette.success.main }}>
                            Open 24 Hours
                        </Typography>
                        {location.residentsOnly && (
                            <>
                                <span
                                    style={{
                                        height: 5,
                                        width: 5,
                                        margin: 8,
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: 180,
                                    }}
                                />
                                <Typography style={{ fontSize: 15, color: "#9190A7" }}>
                                    Residents Only
                                </Typography>
                            </>
                        )}
                    </DivHor>
                </Div>
                <img
                    src="https://static.straitstimes.com.sg/s3fs-public/styles/x_large/public/articles/2019/03/15/colin-co2-15.jpg?itok=FnEuKLJS&timestamp=1552654693"
                    style={{
                        maxHeight: 90,
                        maxWidth: 140,
                        borderRadius: 14,
                        paddingRight: 8
                    }}
                    alt=""
                />
            </DivHor>
            <DivHor style={{ flex: 1, justifyContent: "space-around", paddingTop: 0, paddingBottom: 0 }}>
                <VertBar
                    title=""
                    Row1={
                        <Typography style={{ color: theme.palette.primary.main, fontWeight: 500 }}>AC</Typography>
                    }
                    Row2={
                        <Typography style={{ color: theme.palette.primary.main, fontWeight: 500 }}>DC</Typography>
                    }
                />
                <VertBar
                    title="Cost per kWh ($)"
                    Row1={
                        <Typography style={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                            {acPrice}
                        </Typography>
                    }
                    Row2={
                        <Typography style={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                            {dcPrice}
                        </Typography>
                    }
                />
                <VertLine />
                <VertBar
                    title="Availability"
                    Row1={
                        <DivHor>
                            {[...Array(acEvses.available)].map((_) => (
                                <Bar color={theme.palette.success.main} />
                            ))}
                            {[...Array(acEvses.charging)].map((_) => (
                                <Bar color={theme.palette.warning.main} />
                            ))}
                            {[...Array(acEvses.unknown)].map((_) => (
                                <Bar color={theme.palette.neutral.light} />
                            ))}
                        </DivHor>
                    }
                    Row2={
                        <DivHor>
                            {[...Array(dcEvses.available)].map((_) => (
                                <Bar color={theme.palette.success.main} />
                            ))}
                            {[...Array(dcEvses.charging)].map((_) => (
                                <Bar color={theme.palette.warning.main} />
                            ))}
                            {[...Array(dcEvses.unknown)].map((_) => (
                                <Bar color={theme.palette.neutral.light} />
                            ))}
                        </DivHor>
                    }
                />
                {
                    distanceFromCurrentLocation &&
                    <>
                        <VertLine />
                        <VertBar
                            title="Distance"
                            Row1={
                                <DivHor>
                                    <Typography style={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                                        {distanceFromCurrentLocation}
                                    </Typography>
                                </DivHor>
                            }
                            Row2={
                                <></>
                            }
                        />
                    </>
                }
            </DivHor>
        </Div>
    );
};
