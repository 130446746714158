import { Grid, Typography } from "@material-ui/core";
import Logo from "../../../components/common/Logo";
import TopBar from "../../../components/common/TopBar";

export default function OnboardingFrameJ() {
    return (
        <>
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{
                    flex: 1,
                    justifyContent: "center",
                    paddingTop: 32,
                }}
            >
                <div className="textWrapper" 
                    style= {{
                        position: "absolute",
                        bottom: "460px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        style={{
                            color: "#058B8E",
                            padding: "0px 32px 0px 48px",
                            marginTop: "-16px",
                            fontSize: 20,
                            fontWeight: 700,
                            textAlign: "center",
                        }}
                    >
                        Ready to Charge<br/>your EV with us?
                    </Typography>
                </div>
                <div className="BackgroundIllustrationWrapper"
                    style={{
                        position: "absolute",
                        bottom: "-5px",
                        left: "0px",
                        right: "0px",
                    }}
                >
                <svg width="100%" height="261" viewBox="0 0 414 261" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M126.909 168.307C76.7591 151.325 5.96417 170.598 -23.1646 182.357L-213.837 651.069L343.157 762.861L471.569 476.536C468.898 367.048 456.16 142.376 426.576 119.589C389.596 91.1061 264.694 163.773 234.645 172.474C204.596 181.176 189.596 189.534 126.909 168.307Z" fill="#639E9A" fillOpacity="0.58"/>
                    <path d="M90.8626 192.95C40.824 175.644 -30.0942 194.458 -59.2985 206.028L-253 673.496L303.259 788.891L433.521 503.403C431.559 393.9 420.275 169.15 390.839 146.173C354.044 117.451 228.674 189.307 198.569 197.815C168.465 206.322 153.411 214.582 90.8626 192.95Z" fill="#639E9A"/>
                    <path d="M116.067 224.024C59.2619 219.143 -9.93512 256.221 -37.4329 275.37L-117.838 799L493.553 775.106L555.162 452.286C524.532 341.628 454.082 116.349 417.325 100.487C371.379 80.6604 259.126 185.387 229.887 201.655C200.649 217.923 187.074 230.124 116.067 224.024Z" fill="#09686A"/>
                    <path d="M374.756 49.1071C375.52 47.7457 377.48 47.7457 378.244 49.1071L389.983 70.0211C390.732 71.3542 389.768 73 388.239 73H364.761C363.232 73 362.268 71.3542 363.017 70.0211L374.756 49.1071Z" fill="#058B8E"/>
                    <path d="M65.756 174.107C66.5201 172.746 68.4799 172.746 69.244 174.107L80.9833 195.021C81.7317 196.354 80.7681 198 79.2393 198H55.7607C54.2319 198 53.2683 196.354 54.0167 195.021L65.756 174.107Z" fill="#058B8E"/>
                    <path d="M352.268 81C353.038 79.6667 354.962 79.6667 355.732 81L362.66 93C363.43 94.3333 362.468 96 360.928 96H347.072C345.532 96 344.57 94.3333 345.34 93L352.268 81Z" fill="#058B8E"/>
                    <path d="M43.2679 206C44.0377 204.667 45.9623 204.667 46.7321 206L53.6603 218C54.4301 219.333 53.4678 221 51.9282 221H38.0718C36.5322 221 35.5699 219.333 36.3397 218L43.2679 206Z" fill="#058B8E"/>
                    <path d="M375.268 40C376.038 38.6667 377.962 38.6667 378.732 40L388.258 56.5C389.028 57.8333 388.066 59.5 386.526 59.5H367.474C365.934 59.5 364.972 57.8333 365.742 56.5L375.268 40Z" fill="#058B8E"/>
                    <path d="M66.268 165C67.0378 163.667 68.9623 163.667 69.7321 165L79.2583 181.5C80.0281 182.833 79.0659 184.5 77.5263 184.5H58.4737C56.9341 184.5 55.9719 182.833 56.7417 181.5L66.268 165Z" fill="#058B8E"/>
                    <path d="M352.79 74.8212C353.568 73.537 355.432 73.537 356.21 74.8212L361.752 83.9632C362.56 85.2961 361.601 87 360.042 87H348.958C347.399 87 346.44 85.2961 347.248 83.9632L352.79 74.8212Z" fill="#058B8E"/>
                    <path d="M43.7897 199.821C44.5683 198.537 46.4317 198.537 47.2103 199.821L52.7523 208.963C53.5603 210.296 52.6007 212 51.042 212H39.958C38.3993 212 37.4397 210.296 38.2477 208.963L43.7897 199.821Z" fill="#058B8E"/>
                    <path d="M375.247 58.1873C376.007 56.8055 377.993 56.8055 378.753 58.1873L392.691 83.5364C393.424 84.8693 392.459 86.5 390.938 86.5H363.062C361.541 86.5 360.576 84.8693 361.309 83.5364L375.247 58.1873Z" fill="#058B8E"/>
                    <path d="M66.2475 183.187C67.0073 181.805 68.9927 181.805 69.7525 183.187L83.6909 208.536C84.4238 209.869 83.4595 211.5 81.9384 211.5H54.0616C52.5405 211.5 51.5762 209.869 52.3091 208.536L66.2475 183.187Z" fill="#058B8E"/>
                    <path d="M352.738 87.2772C353.493 85.8731 355.507 85.8731 356.262 87.2772L364.607 102.803C365.323 104.135 364.358 105.75 362.846 105.75H346.154C344.642 105.75 343.677 104.135 344.393 102.803L352.738 87.2772Z" fill="#058B8E"/>
                    <path d="M43.7384 212.277C44.4931 210.873 46.5069 210.873 47.2616 212.277L55.6073 227.803C56.3235 229.135 55.3584 230.75 53.8456 230.75H37.1544C35.6416 230.75 34.6765 229.135 35.3927 227.803L43.7384 212.277Z" fill="#058B8E"/>
                    <line x1="376.75" y1="71.75" x2="376.75" y2="107.25" stroke="#48466D" strokeWidth="1.5" strokeLinecap="round"/>
                    <line x1="67.75" y1="196.75" x2="67.75" y2="232.25" stroke="#48466D" strokeWidth="1.5" strokeLinecap="round"/>
                    <line x1="354.75" y1="95.75" x2="354.75" y2="119.25" stroke="#48466D" strokeWidth="1.5" strokeLinecap="round"/>
                    <line x1="45.75" y1="220.75" x2="45.75" y2="244.25" stroke="#48466D" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M392.268 69C393.038 67.6667 394.962 67.6667 395.732 69L402.66 81C403.43 82.3333 402.468 84 400.928 84H387.072C385.532 84 384.57 82.3333 385.34 81L392.268 69Z" fill="#69B0B2"/>
                    <path d="M392.79 62.8212C393.568 61.537 395.432 61.537 396.21 62.8212L401.752 71.9632C402.56 73.2961 401.601 75 400.042 75H388.958C387.399 75 386.44 73.2961 387.248 71.9632L392.79 62.8212Z" fill="#69B0B2"/>
                    <path d="M392.738 75.2772C393.493 73.8731 395.507 73.8731 396.262 75.2772L404.607 90.8031C405.323 92.1355 404.358 93.75 402.846 93.75H386.154C384.642 93.75 383.677 92.1355 384.393 90.8031L392.738 75.2772Z" fill="#69B0B2"/>
                    <line x1="394.75" y1="83.75" x2="394.75" y2="107.25" stroke="#48466D" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M83.2679 194C84.0377 192.667 85.9623 192.667 86.7321 194L93.6603 206C94.4301 207.333 93.4678 209 91.9282 209H78.0718C76.5322 209 75.5699 207.333 76.3397 206L83.2679 194Z" fill="#BBDDDE"/>
                    <path d="M83.7897 187.821C84.5683 186.537 86.4317 186.537 87.2103 187.821L92.7523 196.963C93.5603 198.296 92.6007 200 91.042 200H79.958C78.3993 200 77.4397 198.296 78.2477 196.963L83.7897 187.821Z" fill="#BBDDDE"/>
                    <path d="M83.7384 200.277C84.4931 198.873 86.5069 198.873 87.2616 200.277L95.6073 215.803C96.3235 217.135 95.3584 218.75 93.8456 218.75H77.1544C75.6416 218.75 74.6765 217.135 75.3927 215.803L83.7384 200.277Z" fill="#BBDDDE"/>
                    <line x1="85.75" y1="208.75" x2="85.75" y2="232.25" stroke="#48466D" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M369.176 92.75C369.561 92.0833 370.523 92.0833 370.908 92.75L374.805 99.5C375.19 100.167 374.709 101 373.939 101H366.145C365.375 101 364.894 100.167 365.279 99.5L369.176 92.75Z" fill="#BBDDDE"/>
                    <path d="M369.457 89.4106C369.847 88.7685 370.778 88.7685 371.168 89.4106L374.318 94.6066C374.722 95.273 374.242 96.125 373.462 96.125H367.163C366.383 96.125 365.903 95.273 366.307 94.6066L369.457 89.4106Z" fill="#BBDDDE"/>
                    <path d="M369.432 96.1386C369.809 95.4366 370.816 95.4366 371.193 96.1386L375.853 104.808C376.211 105.474 375.729 106.281 374.972 106.281H365.653C364.896 106.281 364.414 105.474 364.772 104.808L369.432 96.1386Z" fill="#BBDDDE"/>
                    <line x1="370.792" y1="101.208" x2="370.792" y2="113.25" stroke="#48466D" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M29.6635 224.875C30.4333 223.542 32.3578 223.542 33.1276 224.875L36.3752 230.5C37.145 231.833 36.1827 233.5 34.6431 233.5H28.1479C26.6083 233.5 25.6461 231.833 26.4159 230.5L29.6635 224.875Z" fill="#69B0B2"/>
                    <path d="M30.0085 220.821C30.787 219.537 32.6505 219.537 33.429 220.821L35.7505 224.651C36.5585 225.984 35.5989 227.688 34.0402 227.688H29.3972C27.8386 227.688 26.879 225.984 27.687 224.651L30.0085 220.821Z" fill="#69B0B2"/>
                    <path d="M29.9571 229.027C30.7119 227.623 32.7256 227.623 33.4804 229.027L37.6853 236.85C38.4016 238.182 37.4364 239.797 35.9237 239.797H27.5138C26.0011 239.797 25.0359 238.182 25.7522 236.85L29.9571 229.027Z" fill="#69B0B2"/>
                    <line x1="32.1455" y1="233.604" x2="32.1455" y2="248.25" stroke="#48466D" strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>
                </div>
                <div className="ChargerIconWrapper"
                    style={{
                        position: "absolute",
                        bottom: "-5px"
                    }}
                >
                    <svg width="400" viewBox="0 0 414 481" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d)" transform="rotate(10 206 337) translate(0 5)">
                        <mask id="mask0" style={{maskType:"alpha",}} maskUnits="userSpaceOnUse" x="109" y="99" width="201" height="248">
                        <path d="M109.748 199.51C109.748 144.296 154.508 99.5361 209.722 99.5361C264.936 99.5361 309.696 144.296 309.696 199.51L309.696 246.073C309.696 301.287 264.936 346.047 209.722 346.047C154.508 346.047 109.748 301.287 109.748 246.073L109.748 199.51Z" fill="#058B8E"/>
                        </mask>
                        <g mask="url(#mask0)">
                        <path d="M87.834 195.685L328.867 195.685L328.867 346.056L87.834 346.056L87.834 195.685Z" fill="#058B8E"/>
                        </g>
                        <path d="M131.66 138.983L189.179 138.983L189.179 222.797L131.66 222.797L131.66 138.983Z" fill="#058B8E"/>
                        <path d="M233.009 138.989L290.528 138.989L290.528 222.803L233.009 222.803L233.009 138.989Z" fill="#058B8E"/>
                        </g>
                        <g filter="url(#filter1_d)">
                        <path d="M212 341L212 481" stroke="#058B8E" strokeWidth="50"/>
                        </g>
                        <g transform="rotate(10 206 337) translate(0 5)">
                        <path d="M150 239.871C153.667 237.705 162.7 234.671 169.5 239.871C167 238.871 159.6 237.471 150 239.871Z" fill="white"/>
                        <path d="M250 239.871C253.667 237.705 262.7 234.671 269.5 239.871C267 238.871 259.6 237.471 250 239.871Z" fill="white"/>
                        <path d="M190.5 270.5C198.667 273.833 224.6 277.6 233 266C230.167 272.5 211.7 282.5 190.5 270.5Z" fill="white"/>
                        </g>
                        <defs>
                        <filter id="filter0_d" x="105.748" y="138.983" width="207.948" height="215.064" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        <filter id="filter1_d" x="183" y="341" width="58" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        </defs>
                    </svg>
                </div>
            </Grid>
        </>
    );
}
