import { Typography, useTheme } from "@material-ui/core";
import { Div } from "../../../components/base/Divs";

type P = {
    title: string;
    text: string;
};

export default function SmallDetail({ title, text }: P) {
    const theme = useTheme()
    return (
        <Div>
            <Typography
                style={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                }}
            >
                {title}
            </Typography>
            <Typography
                style={{
                    color: theme.palette.primary.main,
                    fontSize: 14,
                }}
            >
                {text}
            </Typography>
        </Div>
    );
}
