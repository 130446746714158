import { Typography, useTheme } from "@material-ui/core";
import { Div } from "../../../components/base/Divs";

type P = {
    title: string;
    text: string;
    prefix?: string;
    suffix?: string;
};

export default function LargeDetail({ title, text, prefix, suffix }: P) {
    const theme = useTheme()
    return (
        <Div>
            <Typography
                style={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: 14,
                }}
            >
                {title}
            </Typography>
            <Typography
                style={{
                    color: theme.palette.primary.main,
                    fontSize: 38,
                }}
            >
                <p
                    style={{
                        fontSize: 14,
                        display: "inline",
                    }}
                >
                    {prefix}
                </p>
                {text}
                <p
                    style={{
                        fontSize: 14,
                        display: "inline",
                    }}
                >
                    {suffix}
                </p>
            </Typography>
        </Div>
    );
}
