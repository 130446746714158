import { Button, Grid, Typography, useTheme } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Div, DivHor } from "../../components/base/Divs";
import InfoButton from "../../components/base/InfoButton";
import TopBar from "../../components/common/TopBar";
import { AuthContext } from "../../context/AuthContext";
import { shadow } from "../../shadows";
import { EVSE, Location } from "../../typings";
import DisclaimerText from "./components/DisclaimerText";
import LargeDetail from "./components/LargeDetail";
import SmallDetail from "./components/SmallDetail";
import StatusText from "./components/StatusText";

type P = {
    location: Location;
    evse: EVSE;
    next?: Function;
};

export default function PreConnectScreen({ location, evse, next: _next }: P) {
    const history = useHistory();
    const pathname = useLocation().pathname;
    const theme = useTheme();

    const next = () => {
        _next?.();
    };

    const { loggedIn } = useContext(AuthContext);
    const providerName = location.onNetwork ? 'Public' : location.provider?.name
    const providerImage = location.onNetwork ? '/images/public-charger-icon.png' : location.provider?.imageURL

    return (
        <>
            <TopBar showMap />
            <Div
                style={{
                    padding: "14px 0px",
                    backgroundColor: theme.palette.background.default,
                    height: "100%",
                    paddingBottom: !loggedIn ? '20%' : '0px',
                    justifyContent: "flex-start"
                }}
            >
                <Div>
                    <Typography
                        style={{ color: theme.palette.primary.main, fontWeight: "bold" }}
                    >
                        CHARGER BY
                    </Typography>
                    <img
                        src={providerImage}
                        style={{
                            width: 64,
                            height: 64,
                            borderRadius: 180,
                            objectFit: "cover",
                            margin: "14px 0px",
                            borderColor: "white",
                            borderWidth: 8,
                            borderStyle: "solid",
                            ...shadow,
                        }}
                        alt="Charger Provider"
                    />
                    <InfoButton />

                    <DivHor>
                        <SmallDetail title="Location" text={location.name} />
                        <SmallDetail title="ChargerID" text={evse.evseID} />
                    </DivHor>
                    <DivHor
                        style={{
                            borderTopWidth: 1,
                            borderTopColor: "lightgray",
                            borderTopStyle: "solid",
                        }}
                    >
                        <Div>
                            <LargeDetail
                                title="Max Power"
                                text={evse.maxElectricPower?.toString() || "22"}
                                suffix="kW"
                            />
                        </Div>
                        <span
                            style={{
                                borderRightWidth: 1,
                                borderRightColor: "lightgray",
                                borderRightStyle: "solid",
                                alignSelf: "stretch",
                            }}
                        />
                        <Div>
                            <LargeDetail
                                title="Cost Per kWh"
                                text={evse.chargingRateKWH.toFixed(2)}
                                prefix="$"
                            />
                        </Div>
                    </DivHor>
                </Div>

                {loggedIn ? (
                    <Div>
                        <StatusText text="Please Connect Charger" />
                        <Button
                            variant="contained"
                            style={{ margin: "14px 0px" }}
                            onClick={() => next()}
                            disabled
                        >
                            Next
                        </Button>
                        <DisclaimerText text="By clicking Next, you agree to our Terms & Conditions" />
                    </Div>
                ) : (
                    <Div
                        style={{
                            backgroundColor: "#83CEC7",
                            borderTopLeftRadius: 32,
                            borderTopRightRadius: 32,
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}
                    >
                        <Typography style={{ color: "#48466D" }}>
                            Ready to charge your EV?
                        </Typography>
                        <Button
                            variant="contained"
                            style={{ margin: "14px 0px" }}
                            onClick={() =>
                                history.push(`/register?next=${pathname}`)
                            }
                        >
                            Register Now
                        </Button>
                        <Typography style={{ color: "#48466D" }}>OR</Typography>
                        <Button
                            variant="contained"
                            style={{
                                margin: "14px 0px",
                                color: "#058B8E",
                                backgroundColor: "white",
                            }}
                            onClick={() =>
                                history.push(`/login?next=${pathname}`)
                            }
                        >
                            Log In
                        </Button>
                    </Div>
                )}
            </Div>
        </>
    );
}
