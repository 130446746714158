import { Typography } from "@material-ui/core";

type P = {
    text: string;
    type?: "SUCCESS" | "FAILURE";
};

export default function StatusText({ text, type = "SUCCESS" }: P) {
    const color = type === "SUCCESS" ? "#1CADA9" : "#C94C4C";
    return (
        <Typography
            style={{
                color,
                fontSize: 14,
                textAlign: "center",
                fontWeight: "bold",
            }}
        >
            {text}
        </Typography>
    );
}
