import { Typography } from "@material-ui/core";
import { Div } from "../../../components/base/Divs";
import { EVSE } from "../../../typings";

export const Charger = ({ evse }: { evse: EVSE }) => {
    const borderColor = evse.ocppStatus === "AVAILABLE"
        ? "#58BB56"
        : evse.ocppStatus === "FINISHING"
        ? "#FFBE6D"
        : evse.ocppStatus === "CHARGING"
        ? "#C94C4C"
        : evse.ocppStatus === "PREPARING"
        ? "#C94C4C"
        : evse.ocppStatus === "SuspendedEVSE"
        ? "#C94C4C"
        : "#C4C4C4"
    return (
        <Div style={{ maxWidth: 86, padding: "0px 4px" }}>
            <Div
                style={{
                    backgroundColor: "#48466D",
                    borderColor,
                    borderStyle: "solid",
                    borderWidth: 6,
                    color: "white",
                    height: 48,
                    width: 48,
                    borderRadius: 180,
                    textAlign: "center",
                    margin: "6px 0px",
                }}
            >
                <Typography style={{ fontSize: 20, lineHeight: 1, fontWeight: 500 }}>
                    ${evse.chargingRateKWH}
                </Typography>
                <Typography style={{ fontSize: 14, lineHeight: 1, }}>
                    /kWh
                </Typography>
            </Div>
        </Div>
    );
};
